class SingleSubmit {
    static TARGET_ATTR = 'dj-single-submit';

    constructor(formEl) {
        this.form = formEl;
        this.isSubmitting = false;
        this.button = this.form.querySelector('button[type="submit"], input[type="submit"]');

        this.onSubmit = this.onSubmit.bind(this);

        this.form.addEventListener('submit', this.onSubmit);
    }

    onSubmit(event) {
        this.button.setAttribute('disabled', 'disabled');

        if (this.isSubmitting) {
            event.preventDefault();
            return;
        }

        this.isSubmitting = true;
    }
}

export { SingleSubmit };

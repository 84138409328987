import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';


@customElement('dj-native-select')
class DjNativeSelect extends DjElement {
  constructor() {
      super();

      this.onSelect = this.onSelect.bind(this);
      this.hideOtherInput = this.hideOtherInput.bind(this);
      this.showOtherInput = this.showOtherInput.bind(this);
    }

    connectedCallback() {
      super.connectedCallback();

      this.$selectEl = this.querySelector('select');
      this.$otherCaseInput = this.querySelector(`#${this.$selectEl.name}_withother`);
      if(this.$otherCaseInput) {
        this.$otherCaseInputEl = this.$otherCaseInput.querySelector('input');
        this.onSelect({target:this.$selectEl});
      }
      this.$selectEl.addEventListener('change', this.onSelect);
  }

    disconnectedCallback() {
      super.disconnectedCallback();

      this.$selectEl.removeEventListener('change', this.onSelect);
    }

  onSelect(event) {
    if (event.target.value === '-1') {
      this.showOtherInput();
    }
    else {
      this.hideOtherInput();
    }
  }

  hideOtherInput(){
    if (this.$otherCaseInput){
      // hide and disable other input field
      this.$otherCaseInput.hidden = true;
      this.$otherCaseInputEl.disabled = true;

      if(this.$selectEl.required) {
        this.$otherCaseInputEl.required = false;
      }

    }
  }

  showOtherInput(){
    if (this.$otherCaseInput){
      // show and enable other input field
      this.$otherCaseInput.hidden = false;
      this.$otherCaseInputEl.disabled = false;

      if(this.$selectEl.required) {
        this.$otherCaseInputEl.required = true;
      }

    }
  }
}

export { DjNativeSelect };

import { customElement, property } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';
import { resize } from '../../utils/resize';

@customElement('dj-single-page-left')
export class SinglePageLeft extends DjElement {
    @property({
        type: String,
        attribute: 'dj-fixed-class',
    })
    fixedClass;

    constructor() {
        super();

        this.setClass = this.setClass.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        this.$contents = this.children[0];
        this.setClass();

        resize(this.setClass);
        // This is needed as an image loading may change the heights.
        window.addEventListener('load', this.setClass);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        window.removeEventListener('load', this.setClass);
    }

    setClass(newSize, oldSize) {
        if (newSize && oldSize && newSize.width === oldSize.width) {
            return;
        }
        const viewportHeight = newSize && newSize.height || window.innerHeight;
        const elHeight = this.offsetHeight;
        const contentsHeight = this.$contents.offsetHeight;

        // Sometimes the child spills out of the parent
        const height = Math.max(elHeight, contentsHeight) + this.offsetTop;

        this.classList.toggle(this.fixedClass, height <= viewportHeight);
    }
}

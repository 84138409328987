import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';
import { demoContentStrings, demoSessions, demoEditableSessions } from './session-demo.constant';

@customElement('dj-sg-session-demo')
class SessionDemo extends DjElement {

    onClick(groupId, sessionId, status) {
        console.log(`sessionDemoController session clicked: groupId (${groupId}), sessionId (${sessionId}), status (${status})`);
    }

    contentStrings = demoContentStrings;

    sessions = demoSessions;

    editableSessions = demoEditableSessions;

    connectedCallback() {
        super.connectedCallback();
    }

    render() {
        return html`
        <h2 class="dj-sg-section-heading">Sessions (not editable)</h2>
        ${this.sessions.map(session => {
            return html`
                <dj-session
                .session="${session}"
                .strings="${this.contentStrings}">
                </dj-session>
            `
        })}

        <h2 class="dj-sg-section-heading">Sessions (editable)</h2>
        ${this.editableSessions.map(session => {
            return html`
                <dj-session
                    .session="${session}"
                    .strings="${this.contentStrings}"
                    .isEditable="${true}"
                    @click="${() => this.onClick(null, session.id, session.status)}"
                </dj-session>`

        })}
        <h2 class="dj-sg-section-heading">Sessions in an Accordion</h2>
        <dj-accordion class="dj-accordion">
            <dj-accordion-item class="dj-accordion__item">
                <div class="dj-accordion__title">Some sessions (not editable)
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        class="dj-icon dj-accordion__chevron"
                        aria_hidden=true
                    >
                        <use xlink:href="#mi-chevron-right"></use>
                    </svg>
                </div>
                <div class="dj-accordion__content-wrap">
                    <div class="dj-accordion__content">
                    ${this.sessions.map(session => {
                        return html`
                        <dj-session
                            .groupId="1"
                            .session="${session}"
                            .strings="${this.contentStrings}">
                        </dj-session>
                        `
                    })}
                    </div>
                </div>
            </dj-accordion-item>
            <dj-accordion-item class="dj-accordion__item">
                <div class="dj-accordion__title">Some sessions (editable)
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        class="dj-icon dj-accordion__chevron"
                        aria_hidden=true
                    >
                    <use xlink:href="#mi-chevron-right"></use>
                    </svg>
                </div>
                <div class="dj-accordion__content-wrap">
                    <div class="dj-accordion__content">
                    ${this.editableSessions.map(session => {
                        return html`
                            <dj-session
                                .groupId="2"
                                .isEditable="${true}"
                                .session="${session}"
                                .strings="${this.contentStrings}"
                                @click="${() => this.onClick(2, session.id, session.status)}">
                            </dj-session>
                        `
                    })}
                    </div>
                </div>
            </dj-accordion-item>
        </dj-accordion>
        `
    }
}

export { SessionDemo, };
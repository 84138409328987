import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';
import { resize } from '../../resize.js';

const SPY_CREATED = 'dj-scroll-spy:created';

@customElement('dj-scroll-container')
class ScrollContainer extends DjElement {

    @property({
        type: String,
        attribute: 'tag-name',
    })
    tagName;

    spies = [];

    $scroller;
    $rootEl;

    currentScrollTop;
    currentScrollLeft;
    prevScrollLeft;
    prevScrollTop;

    constructor() {
        super();

        this.getScrollTop = this.getScrollTop.bind(this);
        this.getScrollLeft = this.getScrollLeft.bind(this);
        this.queueAf = this.queueAf.bind(this);
        this.onAnimationFrame = this.onAnimationFrame.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.getScroll = this.getScroll.bind(this);
        this.register = this.register.bind(this);
    }

    connectedCallback() {
        this.onBody = this.tagName === 'body';
        this.$scroller = this.onBody ? window : this;
        this.$rootEl = this.onBody ? document.querySelector('body') : this;

        this.currentScrollTop = this.getScrollTop();
        this.currentScrollLeft = this.getScrollLeft();

        this.$scroller.addEventListener('scroll', this.onScroll);
        this.onScroll();

        this.addEventListener(SPY_CREATED, this.register);

        resize.resizeHandler(() => {
            this.spies.forEach(spy => {
                spy.resizeHandler(this);
            });
        });
    }

    disconnectedCallback() {
        this.$scroller.removeEventListener('scroll', this.onScroll);
        this.removeEventListener(SPY_CREATED, this.register);
    }

    register(event) {
        this.spies.push(event.detail);
    }

    getScroll() {
        return {
            left: this.getScrollLeft(),
            top: this.getScrollTop(),
        };
    };

    getScrollTop() {
        return this.onBody ? window.scrollY : this.scrollTop;
    }

    getScrollLeft() {
        return this.onBody ? window.scrollX : this.scrollLeft;
    }

    queueAf() {
        window.requestAnimationFrame(this.onAnimationFrame);
    }

    onAnimationFrame() {
        this.spies.forEach(spy => {
            if (this.$rootEl.style.position !== 'fixed') {
                spy.onScroll({
                    top: this.currentScrollTop,
                    left: this.currentScrollLeft,
                });
            }
        });
    }

    onScroll() {
        this.currentScrollTop = this.getScrollTop();
        this.currentScrollLeft = this.getScrollLeft();

        if (
            this.prevScrollTop !== this.currentScrollTop ||
            this.prevScrollLeft !== this.currentScrollLeft
        ) {
            this.queueAf();
            this.prevScrollTop = this.currentScrollTop;
            this.prevScrollLeft = this.currentScrollLeft;
        }
    }
}

export { ScrollContainer, SPY_CREATED };

import { property } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

class DjCheckboxRadioBase extends DjElement {

    @property({
        type: Boolean,
        attribute: 'dj-disabled',
    })
    djDisabled;

    constructor() {
        super();

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateState = this.updateState.bind(this);
        this.displayOtherField = this.displayOtherField.bind(this);

        this.CLASSES = {
            BASE: '',
            BOX_CHECKED: '__box--checked',
            DISABLED: '--disabled',
            FOCUSED: '--focused',
        };
    }

    connectedCallback() {
        super.connectedCallback();

        this.input = this.querySelector('input');
        if (!this.input) {
            console.warn('DjCheckboxRadioBase missing native input');
            return;
        }

        this.label = this.querySelector('label');
        this.box = this.querySelector(`.${this.CLASSES.BASE}__box`);
        this.checkedIcon = this.querySelector(`.${this.CLASSES.BASE}__icon--checked`);
        this.uncheckedIcon = this.querySelector(`.${this.CLASSES.BASE}__icon--unchecked`);
        this.otherField = document.querySelector(`#${this.input.name}_withother`);

        this.input.addEventListener('focus', this.onFocus, {passive: true});
        this.input.addEventListener('blur', this.onBlur, {passive: true});
        this.input.addEventListener('change', this.onChange, {passive: true});

        if (this.djDisabled) {
            this.input.disabled = this.djDisabled;
        }

        this.updateState();
    }

    onFocus() {
        this.label.classList.add(this.CLASSES.FOCUSED);
    }

    onBlur() {
        this.label.classList.remove(this.CLASSES.FOCUSED);
    }

    onChange() {
        this.updateState();
    }

    displayOtherField() {
        // Control the display of the other field input
        if (this.input.value === '-1') {
            this.otherField.style.display = this.input.checked ? '' : 'none';
        }
    }

    updateState() {
        this.label.classList.toggle(this.CLASSES.DISABLED, this.input.disabled);
        this.box.classList.toggle(this.CLASSES.BOX_CHECKED, this.input.checked);

        this.checkedIcon.style.display = this.input.checked ? '' : 'none';
        this.uncheckedIcon.style.display = this.input.checked ? 'none' : '';

        if (this.otherField) {
            this.displayOtherField();
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.input.removeEventListener('focus', this.onFocus, {passive: true});
        this.input.removeEventListener('blur', this.onBlur, {passive: true});
        this.input.removeEventListener('change', this.onChange, {passive: true});
    }
}

export { DjCheckboxRadioBase };

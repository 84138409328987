import _ from 'lodash';
import { getEventBus } from '../shared-with-old-bundles/eventBus';
const eventBus = getEventBus();

let nowFn;

if ('performance' in window) {
    nowFn = function () {
        return window.performance.now();
    };
} else {
    var nowOffset = Date.now();

    nowFn = function () {
        return Date.now() - nowOffset;
    };
}

/**
 * Returns a function that will execute only after at least tresholds seconds have
 * elapsed since the last call
 * @param  {function} fn         The function to debounce
 * @param  {number}   threshhold Delay in milliseconds, defaults to 250ms
 * @return {function}            The debounced function
 */
var debounce = function debounce(fn, threshhold) {
    threshhold = threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
        var nowTime = nowFn(),
            args = arguments;
        if (nowTime - last < threshhold) {
            clearTimeout(deferTimer);
        }

        deferTimer = setTimeout(function () {
            fn.apply(undefined, args);
        }, threshhold);

        last = nowTime;
    };
};

function resize(cb) {
    var callbacks = [],
        size = {
            height: window.innerHeight,
            width: window.innerWidth,
        };

    var doResize = debounce(onResize, 250, true);
    window.addEventListener('resize', doResize);
    eventBus.on('dj:force-resize', doResize);

    function resizeHandler(cb) {
        if (typeof cb === 'function') {
            callbacks.push(cb);
        }
    }

    function onResize() {
        var newSize = {
            height: window.innerHeight,
            width: window.innerWidth,
        };
        callbacks.forEach(function (cb) {
            cb(newSize, size);
        });
        size = _.cloneDeep(newSize);
        eventBus.emit('dj:resize');
    }
    return resizeHandler(cb);
}

export { resize };

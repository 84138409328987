import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { keynames, ensureInView, disableBodyScroll, calcHeight } from '../../utils';
import { resize } from '../../utils/resize';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

const PILL_CLOSE_CLASS = 'dj-select__tag-close';
const OPTION_CLASS = 'dj-select__option';
const NO_MATCHING_OPTIONS = '';


const BACKDROP_SELECTOR = '.dj-select__backdrop';

const KEYNAMES = {
    ' ': keynames.SPACE,
    Backspace: keynames.BACKSPACE,
    Tab: keynames.TAB,
    Enter: keynames.ENTER,
    Escape: keynames.ESCAPE,
    ArrowDown: keynames.DOWN,
    ArrowUp: keynames.UP,
    ArrowLeft: keynames.LEFT,
    ArrowRight: keynames.RIGHT,
};

const hide = (x) => { x.style.display = 'none'; }
const show = (x) => { x.style.display = 'block'; }
const mod = (x, n) => ((x % n) + n) % n;
const noop = () => {};


/**
 * Sub component for filtering and rendering dropdown options.
 */
@customElement('dj-select-dropdown')
class DjSelectDropdown extends DjElement {
    @property({
        type: Boolean,
    })
    multiple = false;
    options = [];
    filtered = [];
    isOpen = false;
    filterValue = '';
    cachedHeight = undefined;

    renderOption = (o) => {
        const classes = {
            'dj-select__option': true,
            'dj-select__option--active': o.active,
            'dj-select__option--selected': o.selected,
            'dj-select__item--placeholder': o.placeholder,
        };

        return html`<div
            role="option"
            id="${this.id}__${o.value}"
            aria-selected="${o.selected ? 'true' : 'false'}"
            class="${classMap(classes)}"
            data-value="${o.value}"
        >
            ${o.placeholder?`Choose one`: o.label}
        </div>`;
    }

    filterOptions = () => {
        this.filterValue = this.filterValue.toLowerCase();
        this.filtered = this.options.filter((o) => {
            o.active = false;
            return o.label.toLowerCase().indexOf(this.filterValue) > -1
        });
    }

    render() {
        if (this.filtered.length) {
            return html`${this.filtered.map((o) => this.renderOption(o))}`
        } else {
        //FIXME: should we remove this and just show an empty box, or some emoji?
        //otherwise we should pass a translation
            return html`${NO_MATCHING_OPTIONS}`;
        }
    }

    firstUpdated() {
        requestAnimationFrame(this.cacheHeight);
    }

    cacheHeight = () => {
        this.cachedHeight = calcHeight(this);
    }
}


/**
 * Sub component for rendering multi select "pills"
 */
@customElement('dj-select-title')
class DjSelectTitle extends DjElement {
    @property({
        type: Boolean,
    })
    multiple = false;
    options = [];

    constructor() {
        super();

        this.setAttribute('aria-expanded', 'false');
        this.setAttribute('aria-activedescendant', '');
    }

    renderSingleSelected = () => {
        const sel = this.selectedOptions();
        if (sel.length == 0) return `Choose one`;
        return sel[0].label;
    }

    selectedOptions = () => {
        return this.options.filter((o) => o.selected);
    }

    renderPill = ({value, label}) => html`
        <div class="dj-select__tag">
            ${label}
            <span
                class="dj-select__tag-close"
                data-value="${value}"
                aria-label="Unselect ${label}"
                role="button"
                tabindex="0"></span>
        </div>`;

    render() {
        const icon = html`<svg xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            class="dj-icon dj-select__triangle">
            <use xlink:href="#mi-triangle-down"></use>
        </svg>`;
        if (this.multiple) {
            return html`${this.selectedOptions().map((p, i) => {
                if (i === 0) {
                    return this.renderPill(p);
                }
                // For screen readers, the field's value becomes a comma separated list.
                // Visually it is still pills though
                return html`<span class="dj-h-visually-hidden">, </span>${this.renderPill(p)}`;
            })}${icon}`;
        }
        return html`${this.renderSingleSelected()}${icon}`;
    }
}


@customElement('dj-select')
class DjSelect extends DjElement {
    @property({
        type: Boolean,
    })
    multiple = false;

    $native;
    $backdrop;
    $dropdown;
    $title;
    active;
    activeValue;
    options;


    constructor() {
        super();
        // the native hidden input that actually gets posted with the form
        this.$native = this.children[0];
        this.$backdrop = this.querySelector(BACKDROP_SELECTOR);
        //this.$trigger = this.children[0];
    }

    connectedCallback() {
        super.connectedCallback();
        // Copy the options array, not even sure if we need this or if we could pass around a reference to the
        // native select .options? On the off chance it's not cross browser safe to mark arbitrary new attributes on
        // the options (active=true/false) we're making a copy here
        this.options = Array.from(this.$native.options).map((o) => ({
            value: o.value,
            selected: o.selected,
            label: o.innerHTML,
            active: false
        }));

    }

    firstUpdated() {
        // Closing dropdown also updates the filter, filtered version and picks the first option as active
        this.$dropdown = this.querySelector('dj-select-dropdown');
        this.$title = this.querySelector('dj-select-title');
        this.$dropdown.options = this.options;
        this.$title.options = this.options;
        this.$title.requestUpdate();

        this.addListeners();

        this.restoreScroll = noop;
        this.closeDropdown();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeys);
        if (this.$title) {
            this.$title.removeEventListener('blur', this.blurHandler);
            this.$title.removeEventListener('click', this.titleClickHandler);
            this.$title.removeEventListener('focus', this.focusHandler);
        }
        if (this.$dropdown) {
            this.$dropdown.removeEventListener('click', this.dropdownClickHandler);
            this.$dropdown.removeEventListener('mousemove', this.mousemoveHandler);
        }
        if (this.$backdrop) {
            this.$backdrop.removeEventListener('click', this.closeDropdown);
        }
    }

    addListeners = () => {
        this.addEventListener('keydown', this.handleKeys);
        this.$title.addEventListener('click', this.titleClickHandler);
        this.$dropdown.addEventListener('click', this.dropdownClickHandler);
        this.$backdrop.addEventListener('click', this.closeDropdown);
        this.$dropdown.addEventListener('mousemove', this.mousemoveHandler);

        // These are only here to trigger the dj-input styling
        this.$title.addEventListener('focus', this.focusHandler);
        this.$title.addEventListener('blur', this.blurHandler);

        resize(this.positionDropdown);
    }

    focusHandler = (event) => {
        this.$native.dispatchEvent(new Event('focus'));
    }

    blurHandler = (event) => {
        this.$native.dispatchEvent(new Event('blur'));
    }

    /**
     * Mark the clicked option as selected (which adds a pill), or deselct a previously selected one (removes a pill)
     * Also closes the dropdown immediatelly.
     * @param {event}      click event
     */
    dropdownClickHandler = (event) => {
        if (event.target.classList.contains(OPTION_CLASS)) {
            event.preventDefault();
            const clicked = event.target.getAttribute('data-value');
            this.toggleSelected(clicked);
        }
    }

    /**
     * Mark the hovered option as "active" - visually highlights.
     * Because the functionality is shared with hilighting on Up/Down arrow it also scrolls the hovered option
     * into view - which may or may not be what we want.
     * @param {event}      mouse move event
     */
    mousemoveHandler = (event) => {
        if (event.target.classList.contains(OPTION_CLASS)) {
            const hovered = event.target.getAttribute('data-value');
            if (hovered != this.activeValue) {
                this.updateActive({activeValue: hovered});
            }
        }
    }

    /**
     * Opens the dropdown OR - if a pill's x mark is clicked - deselects the corresponding option.
     * @param {event}      click event
     */
    titleClickHandler = (event) => {
        if (event.target.classList.contains(PILL_CLOSE_CLASS)) {
            event.preventDefault();
            const clicked = event.target.getAttribute('data-value');
            this.toggleSelected(clicked);
            return;
        }
        this.openDropdown();
    }

    /**
     * Toggles the selected status of an option identified by the passed value.
     * This is used for removing pill's and choosing values from the dropdown
     * This also updates the hidden native element and fires a change event on it.
     * @param {value}      an options value attribute
     */
    toggleSelected = (value) => {
        this.$dropdown.filtered.forEach((o) => {
            if (o.value == value) o.selected = !o.selected;
        });
        this.closeDropdown();
        this.updateNative();
        this.$title.requestUpdate();
        this.$dropdown.requestUpdate();
        this.$native.dispatchEvent(new Event('change', {bubbles: true}));
    }

    /**
     * Make sure an option is visible and not hidden by scroll
     * If it is, scroll just enough to make it visible
     * @param  {value} option value to check visibility for
     */
    ensureOptionVisible = (value, container=this.$dropdown) => {
        let $option = this.$dropdown.querySelector('[data-value="' + value + '"]');
        if (!$option) {
            $option = this.$dropdown.querySelector('[data-value]');
        }
        if (!$option) {
            return;
        }
        ensureInView($option, container);
    }

    /**
     * Updates the internal `active` - an index of the hovered/active option and `activeValue` - the value of the
     * chosen option.
     * This is used either from the dropdown click event (when we pick by value), or from up/down key navigation,
     * where we find the next active option by adding +-1 to the current active index
     * @param {activeValue?}      an options value attribute
     * @param {activeIndex?}      an index in the dropdown of the option
     */
    updateActive = ({activeValue = null, activeIndex = null} = {}) => {
        // this.active holds the index of the currently active option, useful to +1 and -1 in the keydown/keyup handler,
        // while when we hover an option we get the option value directly
        if (activeValue != null) {
            this.$dropdown.filtered.forEach((o, i) => {
                o.active = false;
                if (o.value == activeValue) {
                    o.active = true;
                    this.active = i;
                    this.activeValue = o.value;
                }
            });
        } else if (activeIndex != null) {
            this.active = mod(activeIndex, this.$dropdown.filtered.length);
            this.activeValue = this.$dropdown.filtered[this.active].value;
            this.$dropdown.filtered.forEach((o, i) => {
                o.active = i == this.active;
            });
        }
        this.$dropdown.requestUpdate();
        this.ensureOptionVisible(this.activeValue);
        this.updateActiveA11y(this.activeValue);
    }

    /**
     * Update the activedescrendant attribute to match the highlighted option
     * @param  {value} option value to highlight
     */
    updateActiveA11y = (value) => {
        let $option = this.$dropdown.querySelector('[data-value="' + value + '"]');
        this.$title.setAttribute('aria-activedescendant', $option?.id || '');
    }

    /**
     * Updates the internal search string used for filtering down options.
     * Notably this isn't calling toggleActive and therefore ensureOptionVisible - because filterOptions will always
     * clear the active attr of all options and we pick the first one here - or if we filtered down to nothing,
     * clear the activeValue
     * @param {search}      the new value of the search filter
     */
    updateFilter = (search) => {
        this.$dropdown.filterValue = search;
        this.$dropdown.filterOptions();
        //FIXME: possibly we could leave the active option if it's filtered, but for now just always update active to the first
        this.active = 0;
        if (this.$dropdown.filtered.length) {
            this.$dropdown.filtered[0].active = true;
            this.activeValue = this.$dropdown.filtered[0].value;
        } else {
            this.activeValue = '';
        }
        this.$dropdown.requestUpdate();
    }

    /**
     * Closes the dropdown, resets scroll and resets the search filter
     */
    closeDropdown = () => {
        hide(this.$backdrop);
        hide(this.$dropdown);
        this.$dropdown.isOpen = false;
        this.updateFilter('');
        this.restoreScroll();
        this.restoreScroll = noop;

        this.$title.setAttribute('aria-expanded', 'false');
        this.$title.setAttribute('aria-activedescendant', '');
    }

    /**
     * Opens dropdown and positions it just below the select's main title element. Also locks the global scroll
     * so we can only scroll through the options.
     *
     * FIXME: this is broken when the input as at the bottom of the page currently!
     */
    openDropdown = () => {
        this.$dropdown.isOpen = true;


        show(this.$backdrop);
        show(this.$dropdown);
        this.ensureOptionVisible(this.activeValue);
        this.updateActiveA11y(this.activeValue);

        this.positionDropdown();

        this.restoreScroll = disableBodyScroll();

        this.$title.setAttribute('aria-expanded', 'true');
    }

    positionDropdown = async () => {
        if (!this.$dropdown.isOpen) return;

        console.debug(this.$dropdown.getBoundingClientRect());
        console.debug('reposition', this.$dropdown, this.$dropdown.offsetHeight);
        let top = this.getBoundingClientRect().top;
        if (this.multiple) {
            top += this.offsetHeight;
        }

        this.$dropdown.style.top = top + 'px';

        await this.$dropdown.updateComplete;
        console.debug('dropdown complete');
        console.debug('activeValue', this.activeValue);
        window.$select = this;
        window.$dropdown = this.$dropdown;
        window.ensureInView = ensureInView;

        top += ensureInView(this.$dropdown, undefined, 24);

        this.$dropdown.style.top = top + 'px';

        // FIXME: the original implementation does the bolow stuff in a settiimeout after options are open,
        // not sure if we need it, and not sure what this line is for at all:
        //top += ensureInView($options[0], undefined, 24);
        //this.$dropdown.style.top = top + 'px';

        // FIXME: somewhere inside disableBodyScroll we can fix the issue where the dropdown is hidden
        // "below the fold" - but whatever computation is used to do that must be in sync with the above
        // dropdown.style.top - not sure how to do that

    }

    /**
     * Syncs this webcomponents `this.options` list of option objects with the native selects options html elements.
     */
    updateNative = () => {
        const selectedValues = this.options.filter((o) => o.selected).map((o) => o.value);
        for (const o of this.$native.options) {
            o.selected = selectedValues.indexOf(o.value) > -1;
        };
    }

    /**
     * On each keypress that would otherwise interact with the dropdown, check if there are no results, and
     * if there aren't: clear the search
     */
    optionallyClearSearch = () => {
        if (!this.$dropdown.filtered.length) {
            this.updateFilter('');
            return true;
        }
    }

    /**
     * Universal key handler for the dropdown.
     * When it's closed: any of the trigger keys to open the dropdown,
     * When it's open:
     *  - enter selects/deselects the active option (and closes dropdown)
     *  - arrows change the active option,
     *  - escape closes the dropdown (but the element stays in focus)

     *  - tab - closes dropdown (and moves focus to the next form element - or next pill, if we're tabbing through selected)
     *  - letters/digits - build a search value and filter options
     *  - backspace - clears the search value but leaves dropdown open
     */
    handleKeys = (event) => {
        const friendlyName = KEYNAMES[event.key];
        const clickKeys = [keynames.ENTER, keynames.SPACE]
        const triggerKeys = [keynames.UP, keynames.DOWN, keynames.SPACE, keynames.ENTER];

        // backspace operates regardless of open dropdown
        // (although filter will only be there if the dropdown is open)
        if (friendlyName == keynames.BACKSPACE) {
            event.preventDefault();
            if (this.$dropdown.filterValue != '') {
                this.updateFilter('');
            } else {
                // if there's no filter backspace will remove selected choices
                const selected = this.options.filter((o) => o.selected);
                if (selected.length > 0) {
                    this.toggleSelected(selected[selected.length-1].value);
                }
            }
            return;
        }

        if (!this.$dropdown.isOpen) {
            // if a pill is focused
            if (clickKeys.indexOf(friendlyName) > -1) {
                if (event.target.classList.contains(PILL_CLOSE_CLASS)) {
                    this.titleClickHandler(event);
                    return;
                }
            }
            if (triggerKeys.indexOf(friendlyName) > -1) {
                event.preventDefault();
                this.openDropdown();
            }
        } else {
            switch (friendlyName) {
                case keynames.ENTER:
                    event.preventDefault();
                    this.toggleSelected(this.activeValue);
                    break;
                case keynames.SPACE:
                    // only allow toggling by space if there isn't already a filter, otherwise space adds to the filter
                    if (this.$dropdown.filterValue == '') {
                        event.preventDefault();
                        this.toggleSelected(this.activeValue);
                    }
                    break;
                case keynames.UP:
                    event.preventDefault();
                    if (!this.optionallyClearSearch())
                    this.updateActive({activeIndex: this.active - 1});
                    break;
                case keynames.DOWN:
                    event.preventDefault();
                    if (!this.optionallyClearSearch())
                    this.updateActive({activeIndex: this.active + 1});
                    break;
                case keynames.ESCAPE:
                    event.preventDefault();
                    this.closeDropdown();
                    break;
                case keynames.TAB: // this seems to cycle through options instead on the old one?
                    // no prevent default to also de-focus?
                    this.closeDropdown()
                    break;
            }

            // Test if the pressed key is alphanumeric to handle option filtering by typing.
            const code = event.code;
            if ((/^Key[A-Z]$/.test(code)) || (/^Digit[0-9]$/.test(code)) || (code == 'Space' && this.$dropdown.filterValue != '')) {
                event.stopPropagation();
                this.updateFilter(this.$dropdown.filterValue + event.key);
            }
        }

    }
}


export { DjSelectDropdown, DjSelectTitle, DjSelect };



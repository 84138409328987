import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { DjElement } from '../base-component/base-component.lit';

export const MEGABYTE = 2 ** 20; // 1,048,576 bytes
export const DEFAULT_MAX_UPLOAD_SIZE_MB = 15;

@customElement('dj-file-upload')
class FileUpload extends DjElement {
    /**
     * This is semantically the same as the absence of required but
     * we want to explicitely allow the "delete" UI on selected
     * image uploaders only. If we decide to always support this
     * behaviour for all file field we could (and should) use
     * `required` as controlling attribute
     * @type {boolean}
     */
    @property({ attribute: 'allow-remove', type: Boolean })
    allowRemove;

    // TODO use showCropper boolean instead of Python "True"/"False" strings.
    @property({ attribute: 'file-upload-cropping-tool' })
    fileUploadCroppingTool;

    @property({ attribute: 'field-id' })
    fieldId;

    @property({ attribute: 'field-name' })
    fieldName;

    @property({ attribute: 'file-type' })
    fileType;

    @property({ attribute: 'label' })
    label;

    @property({ attribute: 'original-src' })
    originalSrc;

    @property({ attribute: 'src' })
    src;

    @property({ attribute: 'text' })
    text;

    @property({attribute: 'help-text'})
    helpText;

    @property({attribute: 'help-text-id'})
    helpTextId;

    @property({attribute: 'error-messages', type: Array})
    errorMessages = [];

    @state()
    fileName;

    fileUploaded = false;

    imageUploaded = false;

    maxUploadSizeBytes = DEFAULT_MAX_UPLOAD_SIZE_MB * MEGABYTE;

    maxSizeErrorMessage = 'Select file smaller than';

    CLASSES = {
        FOCUSED: 'dj-file-upload--focused',
    };

    FILE_TYPES = {
        IMAGE: 'image',
        DOCUMENT: 'document',
    };

    constructor() {
        super();
        this._onBlur = this._onBlur.bind(this);
        this._onChange = this._onChange.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onReaderLoad = this._onReaderLoad.bind(this);
        this.reset = this.reset.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        /**
         * When an image field is optional and already populated django
         * automagically adds a checkbox with the same field name with a
         * -clear suffix which needs to be checked to remove the file.
         * If the checkbox is present, clicking on the cross checks
         * is, uploading a file unchecks it
         * @type {Element}
         */
        this.$clearCheckbox = this.querySelector(
            `input[name="${this.fieldName}-clear"]`
        );

        // TODO use showCropper boolean instead of Python "True"/"False" strings.
        this.showCropper = this.fileUploadCroppingTool === 'True';

        const icons = {
            default: 'mi-upload',
            [this.FILE_TYPES.DOCUMENT]: 'mi-pdf',
            [this.FILE_TYPES.IMAGE]: 'mi-image',
        }
        this.icon = icons[this.fileType] || icons.default;

        const defaultText = {
            default: `Choose ${this.fileType} file`,
            [this.FILE_TYPES.DOCUMENT]: 'Choose PDF',
            [this.FILE_TYPES.IMAGE]: 'Choose image',
        };
        this.defaultText = defaultText[this.fileType] || defaultText.default;
    }

    firstUpdated(){
        this.$input = this.querySelector('input[type="file"]');
        this.$uploadInput = this.querySelector('dj-file-upload-input');
        const maxSize = this.$input.getAttribute('data-max-size');
        if (maxSize) {
            this.maxUploadSizeBytes = parseInt(maxSize, 10) || this.maxUploadSizeBytes;
        }
        this.maxSizeErrorMessage = this.$input.getAttribute('data-max-size-error-message') || this.maxSizeErrorMessage;

        this.$input.addEventListener('blur', this._onBlur);
        this.$input.addEventListener('change', this._onChange);
        this.$input.addEventListener('focus', this._onFocus);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        // disconnectedCallback can be called before firstUpdated
        if (this.$input) {
            this.$input.removeEventListener('blur', this._onBlur);
            this.$input.removeEventListener('change', this._onChange);
            this.$input.removeEventListener('focus', this._onFocus);
        }
    }

    _onChange() {
        //Path looks liks C:\fakepath\filename.png
        this.fileName = this.$input.value.split('\\').pop();
        // Stop here if no file is selected
        if (!this.$input.files || !this.$input.files.length) {
            return;
        }

        const [file] = this.$input.files;
        const validFileSize = file.size && file.size < this.maxUploadSizeBytes;

        if (!validFileSize) {
            this.fileName = `${this.maxSizeErrorMessage} ${this.maxUploadSizeBytes / MEGABYTE} MB.`;
            return;
        }

        const reader = new FileReader();

        // Only try to load image preview and cropper if we have image type
        reader.onload = this._onReaderLoad;
        reader.readAsDataURL(file);
    }

    _onBlur() {
        this.classList.remove(this.CLASSES.FOCUSED);
    }

    _onFocus() {
        this.classList.add(this.CLASSES.FOCUSED);
    }

    _onReaderLoad({ target }) {
        if (this.fileType === this.FILE_TYPES.IMAGE){
            this.imageUploaded = true;
        }
        if (this.fileType === this.FILE_TYPES.DOCUMENT){
            this.fileUploaded = true;
        }
        this.src = target.result;
        if (this.$clearCheckbox) {
            this.$clearCheckbox.checked = false;
        }
    }

    /**
     * Clears the input field and checks the "clear" checkbox if any
     */
    reset() {
        this.$input.value = '';
        this.fileName = '';
        this.originalSrc = '';
        this.src = '';

        if (this.fileType === this.FILE_TYPES.IMAGE){
            this.imageUploaded = false;
        }
        if (this.fileType === this.FILE_TYPES.DOCUMENT){
            this.fileUploaded = false;
        }

        if (this.$clearCheckbox) {
            this.$clearCheckbox.checked = true;
        }
    }

    uploadSuccessMessage() {
        if (this.fileType === this.FILE_TYPES.IMAGE && this.imageUploaded) {
          return `Image successfully uploaded`;
        }
        if (this.fileType === this.FILE_TYPES.DOCUMENT && this.fileUploaded) {
          return `File successfully uploaded`;
        }
    }

    render() {
        return html`
            <div class=${classMap({
                'dj-file-upload': true,
                'dj-file-upload--allow-remove': this.allowRemove,
            })}>
                <label for="${this.fieldId}" aria-hidden="true">
                    <div class="dj-input__file">
                        <span id=${
                            this.fieldId + '_file_type'
                        } class="dj-link dj-link--file-upload">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                class="dj-icon dj-icon--left"
                                aria-hidden="true">
                                <use href="#${this.icon}"></use>
                            </svg>
                            <span class="dj-h-visually-hidden">${
                                this.label
                            }</span>
                            ${this.text || this.defaultText}
                        </span>
                        <span class="dj-input__file-name">${
                            this.fileName
                        }</span>
                        <span class="dj-input__input-wrapper">
                            ${this.$uploadInput}
                        </span>
                    </div>
                </label>
                ${(this.imageUploaded || this.fileUploaded) ? html`
                    <p role="alert">
                        ${this.uploadSuccessMessage()}
                    </p>
                ` : ''}
                <div id="${this.helpTextId}" class="dj-input__help-text" tabindex="-1">
                    ${this.helpText}
                </div>
                <div class="dj-input__errors" role="alert">
                    ${this.errorMessages.map(err => html`<div>${err}</div>`)}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="dj-icon dj-input__error-icon" aria-hidden="true" role="presentation">
                        <use xlink:href="#mi-warning"></use>
                    </svg>
                </div>
                ${
                    this.fileType === this.FILE_TYPES.IMAGE && (this.src || this.originalSrc)
                        ? html`
                            <div class="dj-file-upload__wrapper">
                                <button
                                    @click=${this.reset}
                                    class="dj-file-upload__remove-image"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        class="dj-icon dj-icon--left"
                                        aria-hidden="true">
                                        <use href="#mi-close"></use>
                                </svg>
                                </button>
                                ${this.showCropper && this.src
                                    ? html`<dj-cropper img-src="${this.src}" field-name="${this.fieldName}"></dj-cropper>`
                                    : html`<img src=${this.src || this.originalSrc} alt="User uploaded image" /> `}
                            </div>
                        `
                        : ''
                }
            </div>
        `;
    }
}

export {FileUpload}

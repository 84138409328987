import { reduceNodeUp } from '../utils.js';

// Selector for potential tabbable candidates
const candidatesSelector = [
    'input',
    'select',
    'a[href]',
    'textarea',
    'button',
    '[tabindex]',
].join(',');

/**
 * Create a tabbing context that traps the user in a tabbing loop
 *
 * @param {Element} element Element to restrict tabbing to
 * @param {{focus: Element}=} options Optional config object
 *
 * focus: Element to be focused after setting the focus trap
 *
 * @returns {function(): void} Function to cancel focus trapping
 */
const create = (element, options = {}) => {
    let tabbable;
    let firstTabbable;
    let lastTabbable;

    if (!window.MutationObserver) {
        return () => {};
    }

    _updateTabbables();

    let lastFocusedElement = document.activeElement;
    let firstFocusTarget = options.focus || firstTabbable;

    // This should never be the case since we always have the close
    // button, but leaving the test to avoid breaking the code otherwise
    if (firstFocusTarget) {
        firstFocusTarget.focus();
    }

    const observer = new MutationObserver(_updateTabbables);

    observer.observe(element, {
        childList: true,
        subtree: true,
    });

    element.addEventListener('keydown', _onKeyDown);

    return () => {
        element.removeEventListener('keydown', _onKeyDown);
        observer.disconnect();

        if (lastFocusedElement) {
            lastFocusedElement.focus();
        }
    };

    /**
     * Keydown event handler, traps Tab and Shift+Tab key press
     *
     * @param {Event} evt KeyDown event
     */
    function _onKeyDown(evt) {
        const tabPressed = evt.key === 'Tab' || evt.keyCode === 9;

        if (!tabPressed) {
            return;
        }

        if (document.activeElement === firstTabbable && evt.shiftKey) {
            evt.preventDefault();
            lastTabbable.focus();
        } else if (document.activeElement === lastTabbable && !evt.shiftKey) {
            evt.preventDefault();
            firstTabbable.focus();
        }
    }

    /**
     * Update the list of tabbable elements
     */
    function _updateTabbables() {
        tabbable = getTabbableElements(element);

        if (tabbable.length <= 0) {
            return;
        }

        firstTabbable = tabbable[0];
        lastTabbable = tabbable[tabbable.length - 1];
    }
};

/**
 * Search for elements that can be tabbed onto given they can
 *
 * @param {Node} root Root node where to start looking
 * @returns {Array.<Node>} Array of elements that can be tabbed to
 */
function getTabbableElements(root) {
    const candidates = root.querySelectorAll(candidatesSelector);

    const filtered = [].filter.call(candidates, (candidate) => {
        if (
            candidate.tabIndex < 0 ||
            (candidate.tagName === 'INPUT' && candidate.type === 'hidden') ||
            candidate.disabled ||
            _inInvisible(candidate)
        ) {
            return false;
        }

        return true;
    });

    return filtered;
}

/**
 * Check if the given element or any of its ancestor are display:none
 * making it non tab-reachable
 *
 * @private
 * @param {Element} element Element to check
 */
const _inInvisible = (element) => {
    return reduceNodeUp(
        element,
        (acc, node) => {
            if (acc || !(node instanceof Element)) {
                return acc;
            }

            const style = window.getComputedStyle(node);
            return style.display === 'none' || style.visibility === 'hidden';
        },
        false
    );
};

const focusTrap = {
    create: create,
    getTabbableElements: getTabbableElements,
};

export { focusTrap };

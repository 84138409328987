import { html } from 'lit';

import moment from 'moment';

import { getEventBus } from '../../shared-with-old-bundles/eventBus';

import { customElement, property, state } from 'lit/decorators.js';

import { classMap } from 'lit/directives/class-map.js';
import { DjElement } from '../base-component/base-component.lit';

const EVENT_EXPIRED = 'dj-countdown:timer-expired';

@customElement('dj-countdown')
class Countdown extends DjElement {
    @property({
        type: String,
        converter: (value) => {
            return moment.utc(value);
        },
    })
    expiry;

    @property({
        type: String,
        attribute: 'warning-message',
    })
    warningMessage;

    @property({
        type: String,
        attribute: 'expiry-message',
    })
    expiryMessage;

    @property({
        type: String,
    })
    approximate;

    @property({
        type: String,
        attribute: 'try-again-text',
    })
    tryAgainText;

    @property({
        type: String,
        attribute: 'try-again-url',
    })
    tryAgainUrl;

    @property({
        type: String,
        attribute: 'remaining-text',
    })
    remainingText;

    @state()
    status;

    @state()
    time;

    interval;

    loadedTimeout = 0;

    eventBus = getEventBus();

    constructor() {
        super();

        //Bind methods
        this.refresh = this.refresh.bind(this);
    }

    refresh() {
        var now = moment.utc();
        var remaining;
        var status;

        // display a11y time alert message after 5 seconds
        // not sure why without this delay the message is skipped by the screen reader
        if (this.loadedTimeout === 5) {
            status = 'loaded';
        } else if (this.loadedTimeout >= 15) {
            status = 'updating';
        }

        this.loadedTimeout += 1;

        if (this.expiry.isAfter(now)) {
            remaining = moment.utc(this.expiry.diff(now));

            if (this.expiry.isBefore(now.add(5, 'minutes'))) {
                status = 'warning';
            }
        } else {
            remaining = '0:00';
            status = 'expired';
            window.clearInterval(this.interval);
            this.eventBus.emit(EVENT_EXPIRED);
        }

        this.status = status;

        if (typeof remaining === 'object') {
            this.time = remaining.format('mm:ss');
            if (typeof this.approximate === 'string') {
                let mins = remaining.format('m');
                this.approximateTime = this.approximate.replace('$mins$', mins);
            }
        } else {
            this.time = remaining;
        }
    }

    connectedCallback() {
        super.connectedCallback();

        this.refresh();
        this.interval = window.setInterval(this.refresh, 1000);
    }

    render() {
        const bannerClasses = {
            'dj-status-banner': true,
            'dj-status-banner--warning': this.status === 'warning',
            'dj-status-banner--expired': this.status === 'expired',
        };

        return html`
            <div class=${classMap(bannerClasses)}>
                <div class="dj-status-banner__container">
                    <div class="dj-status-banner__content">
                        <div class="dj-status-banner__timer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                class="dj-icon"
                            >
                                <use xlink:href="#mi-time"></use>
                            </svg>
                            ${this.time} ${this.remainingText}
                        </div>
                        ${this.status === 'warning'
                            ? html`
                                  <div
                                      role="alert"
                                      class="dj-status-banner__message"
                                  >
                                      ${this.warningMessage}
                                  </div>
                              `
                            : ''}
                        ${this.status === 'expired'
                            ? html`
                                  <div
                                      role="alert"
                                      class="dj-status-banner__message"
                                  >
                                      ${this.expiryMessage}
                                      <a
                                          class="dj-button dj-button--primary"
                                          href=" ${this.tryAgainUrl}"
                                      >
                                          ${this.tryAgainText}</a
                                      >
                                  </div>
                              `
                            : ''}
                        ${this.status === 'loaded'
                            ? html`
                                  <div
                                      role="alert"
                                      class="dj-h-visually-hidden"
                                      ng-if="status === 'loaded'"
                                  >
                                      ${this.approximateTime}
                                      ${this.remainingText}
                                  </div>
                              `
                            : ''}
                        ${this.status === 'updating'
                            ? html`
                                  <div
                                      role="region"
                                      aria-live="polite"
                                      class="dj-h-visually-hidden"
                                  >
                                      ${this.approximateTime}
                                      ${this.remainingText}
                                  </div>
                              `
                            : ''}
                    </div>
                </div>
            </div>
        `;
    }
}

export { Countdown, EVENT_EXPIRED };

import { customElement } from 'lit/decorators.js';
import { resize } from '../../utils/resize';

import { DjElement } from '../base-component/base-component.lit';

const CLASSES = {
    CALCULATING: 'dj-header__nav-bar--is-calculating',
    MENU: 'dj-nav-bar__item--in-menu',
    OPEN: 'dj-header__nav-bar--open',
    OVERFLOW: 'dj-nav-bar__item--overflow',
}

const SELECTORS = {
    CTA_SECTION: '.dj-header__cta',
    MENU_TRIGGER: '.dj-header__menu-trigger',
    MORE_DROPDOWN: '.dj-nav-bar__more-drop-down',
    MORE_ITEM: '.dj-nav-bar__item--more',
    MORE_ITEM_BUTTON: '.dj-nav-bar__item--more > button',
    NAV_BAR_ITEMS: '.dj-nav-bar__items > .dj-nav-bar__item',
    NAV_BAR_CONTENT: '.dj-nav-bar__content',
}

const ATTRIBUTES = {
    LOADING: 'loading',
}

@customElement('dj-nav-bar')
class NavBar extends DjElement {

    ctaSectionLimit;
    overflowIndex = -1;

    constructor() {
        super();

        this.checkOverflow = this.checkOverflow.bind(this);
        this.populateDropdown = this.populateDropdown.bind(this);
        this.isOverflowing = this.isOverflowing.bind(this);
        this.clearOverflow = this.clearOverflow.bind(this);
        this.setOverflow = this.setOverflow.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleMoreNavButton = this.toggleMoreNavButton.bind(this);

        this.loading = this.hasAttribute(ATTRIBUTES.LOADING);
    }

    static get observedAttributes() {
        return [ATTRIBUTES.LOADING];
    }

    get loading() {
        return this.hasAttribute(ATTRIBUTES.LOADING);
    }

    set loading(val) {
        if (val) {
            this.setAttribute(ATTRIBUTES.LOADING, '');
        } else {
            this.removeAttribute(ATTRIBUTES.LOADING);
        }
    }

    attributeChangedCallback() {
        this.checkOverflow();
    }

    connectedCallback() {
        super.connectedCallback();
        this.$isRTL = document.children[0].dir === 'rtl';
        this.$ctaSection = this.querySelector(SELECTORS.CTA_SECTION);
        this.$menuTrigger = this.querySelector(SELECTORS.MENU_TRIGGER);
        this.$moreDropdown = this.querySelector(SELECTORS.MORE_DROPDOWN);
        this.$moreItem = this.querySelector(SELECTORS.MORE_ITEM);
        this.$moreItemButton = this.querySelector(SELECTORS.MORE_ITEM_BUTTON);
        this.$navBarItems = this.querySelectorAll(SELECTORS.NAV_BAR_ITEMS);
        this.$navBarContent = this.querySelector(SELECTORS.NAV_BAR_CONTENT);

        if (this.loading) this.loading = false;

        resize(this.checkOverflow);
        this.$menuTrigger.addEventListener('click', this.toggleNav);
        this.$moreItemButton.addEventListener('click', this.toggleMoreNavButton);
    }

    disconnectedCallback() {
        this.$menuTrigger.removeEventListener('click', this.toggleNav);
        this.$moreItemButton.removeEventListener('click', this.toggleMoreNavButton);
    }

    toggleMoreNavButton() {
        this.$moreItem.toggleAttribute('active');
        if (this.$moreItem.hasAttribute('active')) {
            this.$moreDropdown.style.display = 'flex';
        } else {
            this.$moreDropdown.style = '';
        }
    }

    checkOverflow() {
        let overflowIndex = -1;
        let navBarContentPaddingLeft = 0;
        let navBarContentPaddingRight = 0;
        let style;

        this.classList.add(CLASSES.CALCULATING);

        if (this.$navBarContent) {
            style = window.getComputedStyle(this.$navBarContent);
            navBarContentPaddingLeft = style.getPropertyValue('padding-left');
            navBarContentPaddingRight = style.getPropertyValue('padding-right');
        }

        if (this.$ctaSection) {
            // If cta section is pushed to the left (RTL) the
            // "limit" it how far it stretch to the right,
            // If it's on the right (LTR) its limit is its
            // right margin

            this.ctaSectionLimit = this.$isRTL ?
                this.$ctaSection.offsetLeft + this.$ctaSection.offsetWidth :
                this.$ctaSection.offsetLeft - navBarContentPaddingRight;
        } else {
            this.ctaSectionLimit = this.offsetWidth - navBarContentPaddingLeft - navBarContentPaddingRight;
        }

        this.clearOverflow(this.$navBarItems);
        this.setOverflow(this.$moreItem);

        this.$navBarItems && this.$navBarItems.forEach((item, i) => {
            if (this.isOverflowing(item) || overflowIndex > -1) {
                overflowIndex = overflowIndex > -1 ?
                    overflowIndex :
                    i;
                this.setOverflow(item);
            }
        })

        // Make space for the 'more' dropdown
        overflowIndex -= 1;

        if (overflowIndex > -1) {
            this.setOverflow(this.$navBarItems[overflowIndex]);
            this.clearOverflow(this.$moreItem);
            this.$moreItem.setAttribute('aria-hidden', false);
            this.populateDropdown();
        } else {
            this.clearOverflow(this.$navBarItems);
            this.setOverflow(this.$moreItem);
            this.$moreItem && this.$moreItem.setAttribute('aria-hidden', true);
        }

        this.classList.remove(CLASSES.CALCULATING);
    }

    isOverflowing(item) {
        const style = window.getComputedStyle(item);
        const marginRight = style.getPropertyValue('margin-right');
        const position = item.offsetLeft + item.offsetWidth + parseInt(marginRight, 10);

        return this.$isRTL ?
            item.offsetWidth && item.offsetLeft < this.ctaSectionLimit :
            position > this.ctaSectionLimit;
    }

    populateDropdown() {
        this.$moreDropdown.replaceChildren();

        const $overflowItems = [...this.$navBarItems].filter(
            item => item.classList.contains(CLASSES.OVERFLOW)
        );

        $overflowItems.forEach(item => {
            let clone = item.cloneNode(true);
            clone.classList.remove(CLASSES.OVERFLOW);
            clone.classList.add(CLASSES.MENU);
            this.$moreDropdown.append(clone);
        });
    }

    clearOverflow(items) {
        if (!items) {
            return;
        }
        const itemsList = (typeof items[Symbol.iterator] === 'function') ?
            [...items] : [items];
        itemsList.forEach(item => item.classList.remove(CLASSES.OVERFLOW));
    }

    setOverflow(items) {
        if (!items) {
            return;
        }
        const itemsList = (typeof items[Symbol.iterator] === 'function') ?
            [...items] : [items];
        itemsList.forEach(item => item.classList.add(CLASSES.OVERFLOW));
    }

    toggleNav() {
        this.classList.toggle(CLASSES.OPEN);

        if (this.classList.contains(CLASSES.OPEN)) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }
}

export { NavBar };

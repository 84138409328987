import './components/accordion/accordion.lit';
import './components/agenda/agenda.lit';
import './components/braintree/braintree-field.lit';
import './components/braintree/braintree-form.lit';
import './components/carousel/carousel.swiper.lit';
import './components/checkbox-and-radio/checkbox.lit';
import './components/checkbox-and-radio/radio.lit';
import './components/collapse/collapse.lit';
import './components/conditions/conditions.lit';
import './components/cookie-consent/cookie-consent.lit';
import './components/countdown/countdown.lit';
import './components/cropper/cropper.lit';
import './components/event-countdown/event-countdown.lit';
import './components/file-upload/file-upload.lit';
import './components/filterable-list/filterable-list.lit';
import './components/input-select/input-select.lit';
import './components/input-textarea/input-textarea.lit';
import './components/input/input.lit';
import './components/io-countdown/io-countdown.lit';
import './components/io-faq-nav/io-faq-nav.lit';
import './components/io-schedule-nav/io-schedule-nav.lit';
import './components/map/map.lit';
import './components/messages/messages.lit';
import './components/microsite-form/microsite-form.lit';
import './components/modal/modal.lit';
import './components/native-select/native-select.lit';
import './components/nav-bar/nav-bar.lit';
import './components/notification-box/notification-box.lit';
import './components/scroll-container/scroll-container.lit';
import './components/scroll-spy-nav/scroll-spy-nav.lit';
import './components/scroll-spy/scroll-spy.lit';
import './components/scroll-to-section/scroll-to-section.lit';
import './components/searchable-list/searchable-list.lit';
import './components/session/session-demo.lit';
import './components/session/session.lit';
import './components/session/sessions.lit';
import './components/single-page/single-page.lit';
import './components/tab-nav/tab-nav.lit';
import './components/video-thumbnail/video-thumbnail.lit';
import './components/social-share/social-share.lit';
import './components/when-widget/when-widget.lit';


import { SingleSubmit } from './components/single-submit/single-submit';
import { SmoothScrollHelper } from './components/smooth-scroll/smooth-scroll-helper';
import { ToggleClick } from './components/toggle-click/toggle-click';
import { ScrollToInputWithError } from './components/scroll-to-input-with-error/scroll-to-input-with-error';
import { MenuDropdown } from './components/menu-dropdown/menu-dropdown';


import uncloak from './utils/uncloak';

require("fslightbox");

if ($(".gallery-container").length) {
    let MagicGrid = require("magic-grid");
    let magicGrid = new MagicGrid({
        container: ".gallery-container", // Required. Can be a class, id, or an HTMLElement.
        // static: true, // Required for static content.
        animate: true, // Optional.
        items: 68,
        maxColumns: 10,
        gutter: 30,
        useMin: true,
    });
    magicGrid.listen();
}


new ToggleClick();
new ScrollToInputWithError();
new MenuDropdown();

window.WebComponents.waitFor(() => {
    // At this point we are guaranteed that all required polyfills have
    // loaded, and can use modern APIs.
    SmoothScrollHelper.connectedCallback();

    document.querySelectorAll(`[${SingleSubmit.TARGET_ATTR}]`).forEach(el => new SingleSubmit(el));
    uncloak(document);
});

import { customElement, property } from 'lit/decorators.js';
import { html, nothing } from 'lit';
import { DjElement } from '../base-component/base-component.lit';
import { styleMap } from 'lit/directives/style-map.js';

export const ATTRS = {
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_HAS_POPUP: 'aria-haspopup',
    ARIA_HIDDEN: 'aria-hidden',
    TOGGLE_FAB_ACTIONS: 'dj-fab-actions:toggle'
}
@customElement('dj-social-share')
class SocialShare extends DjElement {

    render() {
        let hasShareAPI = navigator.share;
        return html`
                <div class="dj-fab-speed-dial md-up md-scale fab-bottom-right">
                    ${hasShareAPI ? html`
                        <dj-fab-trigger>
                        <dj-md-button aria-label="share" icon="social-share">
                        </dj-md-button>
                        </dj-fab-trigger>
                    ` : nothing}
                    ${!hasShareAPI ? html`
                        <dj-fab-trigger aria-haspopup="true" aria-expanded="false">
                            <dj-md-button aria-label="Share" icon="social-share">
                            </dj-md-button>
                        </dj-fab-trigger>
                        <dj-fab-actions aria-hidden="true">
                        </dj-fab-actions>
                    ` : nothing}
                </div>`
    }
}

@customElement('dj-fab-trigger')
class FabTrigger extends DjElement {
    constructor() {
        super();
        this.addEventListener('click', this.onClick);
    }

    onClick(event) {
        const nextElement = this.nextElementSibling;
        if (nextElement) {
            if (nextElement.localName == 'dj-fab-actions') {
                nextElement.dispatchEvent(
                    new CustomEvent(ATTRS.TOGGLE_FAB_ACTIONS, { bubbles: true })
                );
            }
        }
    }
}

@customElement('dj-fab-actions')
class FabActions extends DjElement {
    styles = {
        opacity: 0,
        transform: "scale(0)",
        "transition-delay": "2ms",
        "z-index": 22,
    };

    @property({ attribute: 'aria-hidden', type: String, reflect: true })
    ariaHidden;

    constructor() {
        super();

        this.fabTriggerElement = this.previousElementSibling;

        this.onShowActions = this.onToggleActions.bind(this);
        this.onHideActions = this.onHideActions.bind(this);
        this.onBackgroundMousedown = this.onBackgroundMousedown.bind(this);
    }

    get getStyleForState() {
        return this.ariaHidden ? { opacity: 0, transform: "scale(0)", "transition-delay": "2ms" }
            : { opacity: 1, transform: "scale(1)", "transition-delay": "0ms" };
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener(ATTRS.TOGGLE_FAB_ACTIONS, this.onToggleActions);
        // Close menu dropdown on background mouse down.
        window.addEventListener('mousedown', this.onBackgroundMousedown, true);
    }

    disconnectedCallback() {
        window.removeEventListener('mousedown', this.onBackgroundMousedown, true);
        super.disconnectedCallback();
    }

    onToggleActions(event) {
        this.ariaHidden = !this.ariaHidden;
        this.styles = { ...this.styles, ...this.getStyleForState };
        this.setAccessibility();
    }
    onHideActions(event) {
        this.ariaHidden = true;
        this.styles = { ...this.styles, ...this.getStyleForState }
        this.setAccessibility();
    }

    setAccessibility() {
        this.fabTriggerElement.setAttribute(ATTRS.ARIA_EXPANDED, !this.ariaHidden);
    }

    onBackgroundMousedown(event) {
        // We need to short-circuit as early as possible.
        // Check if actions are open.
        if (!this.ariaHidden) {
            // Check if fabTrigger element nodes contains target element i.e event occured on background.
            if (!this.fabTriggerElement.contains(event.target)) {
                this.onHideActions();
            }
        }
    }

    render() {
        return html`
                <div class="fab-action-item" style=${styleMap(this.styles)}>
                    <dj-md-button aria-label="twitter" icon="social-twitter" btn-class="raised mini">
                    </dj-md-button>
                </div>
                <div class="fab-action-item" style=${styleMap(this.styles)}>
                    <dj-md-button aria-label="facebook" icon="social-facebook" btn-class="raised mini">
                    </dj-md-button>
                 </div>
                `;
    }
}

@customElement('dj-md-button')
class MdButton extends DjElement {
    @property({ type: String })
    icon;

    @property({ attribute: 'aria-label' })
    label;

    @property({ attribute: 'btn-class' })
    btnClass;


    constructor() {
        super();

        const parentElement = this.parentElement;
        const hasPopup = parentElement.getAttribute('aria-haspopup', false);
        //only add event listener when button is not used as an fab-actions trigger
        if (!hasPopup) {
            this.addEventListener('click', this.onClick);
        }
    }


    onClick(event) {
        if (this.label === 'share') {
            if (!navigator.share) {
                return;
            }
            navigator.share({
                url: window.document.URL,
            }).catch((error) => console.log('Error sharing', error));
        }
        else if (this.label === 'twitter') {
            var twitterWindow = window.open('https://twitter.com/share?url=' + window.document.URL, 'twitter-popup', 'height=350,width=600');
            if (twitterWindow.focus) {
                twitterWindow.focus();
            }
        }
        else if (this.label === 'facebook') {
            var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.document.URL, 'facebook-popup', 'height=350,width=600');
            if (facebookWindow.focus) {
                facebookWindow.focus();
            }
        }
    }
    renderShareIcon() {
        return html`
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M0 0h24v24H0z" fill="none"></path><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z">
        </path>
        </svg>
        `;
    }

    renderFacebookIcon() {
        return html`
        <svg version="1.1" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><g><g><g>
        <path d="M40,4H8C5.8,4,4,5.8,4,8l0,32c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V8C44,5.8,42.2,4,40,4z M38,8v6h-4c-1.1,0-2,0.9-2,2v4h6v6h-6v14h-6V26h-4v-6h4v-5c0-3.9,3.1-7,7-7H38z">
        </path></g><g>
        <rect fill="none" width="48" height="48"></rect></g></g></g>
        </svg>
        `;
    }
    renderTwitterIcon() {
        return html`
        <svg version="1.1" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><g><g><g>
        <path d="M40,4H8C5.8,4,4,5.8,4,8l0,32c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V8C44,5.8,42.2,4,40,4z M35.4,18.7c-0.1,9.2-6,15.6-14.8,16c-3.6,0.2-6.3-1-8.6-2.5c2.7,0.4,6-0.6,7.8-2.2c-2.6-0.3-4.2-1.6-4.9-3.8c0.8,0.1,1.6,0.1,2.3-0.1
        c-2.4-0.8-4.1-2.3-4.2-5.3c0.7,0.3,1.4,0.6,2.3,0.6c-1.8-1-3.1-4.7-1.6-7.2c2.6,2.9,5.8,5.3,11,5.6c-1.3-5.6,6.1-8.6,9.2-4.9c1.3-0.3,2.4-0.8,3.4-1.3c-0.4,1.3-1.2,2.2-2.2,2.9c1.1-0.1,2.1-0.4,2.9-0.8C37.5,16.9,36.4,17.9,35.4,18.7z">
        </path></g><g>
        <rect fill="none" width="48" height="48"></rect>
        </g></g></g>
        </svg>
        `;
    }

    render() {
        return html`
                <button dj-cloak aria-label="${this.label}" class="dj-social-btn dj-social-fab ${this.btnClass}">
                    <div aria-label="${this.label}" class="dj-social-icon" role="img">
                    ${this.icon == 'social-share' ? this.renderShareIcon() : nothing}
                    ${this.icon == 'social-facebook' ? this.renderFacebookIcon() : nothing}
                    ${this.icon == 'social-twitter' ? this.renderTwitterIcon() : nothing}
                    </div>
                </button>
        `
    }
}
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import Swiper, { Navigation, Pagination } from 'swiper7';
import { DjElement } from '../base-component/base-component.lit';

const ITEM_ADDED = 'dj-swiper-item:added';
const ITEM_REMOVED = 'dj-swiper-item:removed';


@customElement('ks-swiper-container')
class SwiperContainer extends DjElement {

    @property({
        type: Number,
        attribute: 'slides-per-view',
    })
    slidesPerView = 1;

    @property({
        type: Number,
        attribute: 'slides-per-column',
    })
    slidesPerColumn = 1;

    @property({
        type: Number,
        attribute: 'space-between',
    })
    spaceBetween = 0;

    @property({
        type: String,
        attribute: 'parallax',
    })
    parallax = '';

    @property({
        type: String,
        attribute: 'parallax-transition',
    })
    parallaxTransition = '';

    @property({
        type: Boolean,
        attribute: 'pagination-is-active',
    })
    paginationIsActive = true;

    @property({
        type: Boolean,
        attribute: 'pagination-clickable',
    })
    paginationClickable = true;

    @property({
        type: Boolean,
        attribute: 'show-nav-buttons',
    })
    showNavButtons = true;

    @property({
        type: Boolean,
        attribute: 'show-scrollbar',
    })
    showScrollBar = false;

    @property({
        type: Boolean,
        attribute: 'loop',
    })
    loop = false;

    @property({
        type: Number,
        attribute: 'autoplay',
    })
    autoplay;

    @property({
        type: String,
        attribute: 'initial-slide',
    })
    initialSlide = '';

    @property({
        type: String,
        attribute: 'container-cls',
    })
    containerCls = '';

    @property({
        type: String,
        attribute: 'wrapper-cls',
    })
    wrapperCls = '';

    @property({
        type: String,
        attribute: 'pagination-cls',
    })
    paginationCls = '';

    @property({
        type: String,
        attribute: 'slide-cls',
    })
    slideCls = '';

    @property({
        type: String,
        attribute: 'direction',
    })
    direction = 'horizontal';

    @property({
        type: Object,
        attribute: 'override-parameters',
    })
    overrideParameters = {};

    constructor() {
        super();
        this.items = [];

        this.registerItem = this.registerItem.bind(this);
        this.unregisterItem = this.unregisterItem.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        this.addEventListener(ITEM_ADDED, this.registerItem);
        this.addEventListener(ITEM_REMOVED, this.unregisterItem);

        let params = {
            slidesPerView: this.slidesPerView,
            slidesPerColumn: this.slidesPerColumn,
            spaceBetween: this.spaceBetween,
            direction: this.direction,
            loop: this.loop,
            initialSlide: this.initialSlide,
            showNavButtons: this.showNavButtons,
            modules: [Navigation, Pagination],
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
        }

        if (this.paginationIsActive === true) {
            params.pagination = {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: this.paginationClickable || true,
            };
        }

        if (this.showScrollBar === true) {
            params.scrollbar = {
                el: '.swiper-scrollbar',
                draggable: true,
            };
        }

        if (this.overrideParameters) {
            params = {...params, ...this.overrideParameters};
        }

        this.params = params;
    }

    registerItem(event) {
        let swiperItem = event.detail;
        this.items.push(swiperItem);

        event.stopPropagation();
    }

    unregisterItem(event) {
        this.items.pop(event.detail);
        event.stopPropagation();
    }

    firstUpdated(){
        super.firstUpdated();
        const swiperContainer = this.querySelector('.swiper-container');
        this.swiper = new Swiper(swiperContainer, this.params);
    }

    render() {
        const swiperItems = this.querySelectorAll('ks-swiper-slide');
        let renderedTemplate = html`
        <div class="swiper swiper-container ${this.containerCls}">
        <div class="transition-bg" data-swiper-parallax="${this.parallaxTransition}"></div>
        <div class="swiper-wrapper ${this.wrapperCls}">
            ${[...swiperItems].map((item) =>
                {
                    if (item.firstElementChild && item.firstElementChild.classList){
                        item.firstElementChild.classList.add('swiper-slide');
                        return html`${item.firstElementChild}`
                    }
            })}
        </div>
        <div class="swiper-pagination ${this.paginationCls}"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        ${(this.showScrollBar) ? html`<div class="swiper-scrollbar"></div>` : html``}
        </div>`;

        return renderedTemplate;
    }
}

@customElement('ks-swiper-slide')
class SwiperSlide extends DjElement {

    connectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_ADDED, {
                bubbles: true,
                detail: this,
            })
        );

        this.classList.add('swiper-slide');
    }

    disconnectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_REMOVED, {
                bubbles: true,
                detail: this,
            })
        );
    }
}

export { SwiperContainer, SwiperSlide, ITEM_ADDED, ITEM_REMOVED };

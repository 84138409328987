import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

@customElement('dj-io-schedule-nav')
class IOScheduleNav extends DjElement {

    /**
     * @typedef {string} Section
     * @typedef {string} FilterLabel
     *
     * @type {{
     *   [section: Section]: FilterLabel[]
     * }}
     */
    static filters = {
        'Event day': [
            'May 14',
            'May 15',
        ],
        'Focus': [
            'AI',
            'Mobile',
            'Web',
            'Cloud',
        ],
        'Content type': [
            'Keynote',
            'Technical session',
            'Workshop',
            'Office hours',
        ],
    };

    static filterPrefix = 'io24-filter-'

    /**
     * Converts strings into a CSS class using the "io24-filter-" prefix
     *
     * ```
     * IOScheduleNav.cssClassFromLabel('Android Ecosystem') // "io24-filter-android-ecosystem".
     * ```
     *
     * This function also supports multiple arguments so you can pass both a
     * filter section and label. This may help if there are duplicate filter labels.
     *
     * ```
     * IOScheduleNav.cssClassFromLabel('Topic', 'Ads') // "io24-filter-topic-ads".
     * ```
     *
     * @param {...string} labels
     * @return {string} CSS class
     */
    static cssClassFromLabel(...labels) {
        return IOScheduleNav.filterPrefix + labels.map(
            (label) => label.toLowerCase().replace(/\W+/g, '-')
        ).join('-')
    }

    /**
     * Filter labels that are currently active
     * @type {Map<Section, Set<FilterLabel>>}
     */
    activeFilters = new Map();

    /**
     * Sections that are currently collapsed
     * @type {Set<Section>}
     */
    collapsedSections = new Set();

    /**
     * Content that has been hidden by the hideSection() method
     * @type {Map<Element, Comment>}
     */
    hiddenContent = new Map();

    /** @override */
    connectedCallback() {
        // Remove the component if there are no filters on the page.
        if (!this.findAllContentBlocks().length) {
            return this.remove();
        }

        // Add CSS styling hook classes
        super.connectedCallback();
        this.classList.add('dj-io-schedule-nav');
        document.querySelector('.dj-content')?.classList.add('dj-content--with-io-schedule-nav');
    }

    /**
     * Overrides the Lit requestUpdate lifecycle method to apply any active filters to the page.
     * @override
     */
    requestUpdate() {
        super.requestUpdate();
        if (!this.isConnected) return;
        this.showAllSections();

        // Stop there if there are no active filters
        if (!this.activeFilters?.size) return;

        // At least one filter has been set, create the filter selector and check all content blocks
        const selector = this.filterSelector();
        for (const block of this.findAllContentBlocks()) {
            if (block.matches(selector)) {
                this.showSection(block);
            } else {
                this.hideSection(block);
            }
        }
        // Hide all top-level sections where all the content blocks in it are hidden
        for (const section of document.querySelectorAll('.io24-schedule-section')) {
            // Use the DOM to find subsections because qSA doesn't support the following:
            // ```section.querySelectorAll("> * > .dj-section")```
            const subsections = section.children[0]?.children ?? [];

            // The first subsection is the section's title. If there are no other subsections,
            // hide the whole section.
            if (subsections.length < 2) {
                this.hideSection(section);
            }
        }
    }

    /**
     * Toggles whether a section is collapsed and requests a lifecycle update.
     * @param {Section} section
     */
    collapseSection(section) {
        if (this.collapsedSections.has(section)) {
            this.collapsedSections.delete(section);
        } else {
            this.collapsedSections.add(section);
        }
        this.requestUpdate(); // Mutating a Set doesn’t trigger an update automatically
    }

    /**
     * Toggles whether a filter is active and requests a lifecycle update.
     * @param {Section} section
     * @param {FilterLabel} filter
     */
    filterToggle(section, filter) {
        const set = this.activeFilters.get(section);
        if (set) {
            if (set.delete(filter)) {
                if (!set.size) {
                    this.activeFilters.delete(section);
                }
            } else {
                set.add(filter);
            }
        } else {
            this.activeFilters.set(section, new Set([filter]))
        }
        this.requestUpdate(); // Mutating a Map doesn’t trigger an update automatically
    }

    /**
     * Returns all elements with a filter class.
     * @return {NodeListOf<HTMLElement>}
     */
    findAllContentBlocks() {
        return document.querySelectorAll(`[class*="${IOScheduleNav.filterPrefix}" i]`);
    }

    /**
     * Builds a CSS selector string to match elements against the active filters.
     *
     * @example
     *   // Given { "Section A": ["one", "two"], "Section B": ["three", "four"] }
     *   this.filterSelector() // ":is(.one,.two):is(.three,.four)"
     * @return {string}
     */
    filterSelector() {
        return Array.from(this.activeFilters.values(), (filters) => {
            const classes = Array.from(filters,
                (filter) => '.' + IOScheduleNav.cssClassFromLabel(filter)
            );
            return `:is(${classes.join(',')})`;
        }).join('');
    }

    /**
     * Returns whether a filter is enabled.
     * @param {Section} section
     * @param {FilterLabel} filter
     * @returns
     */
    filterIsActive(section, filter) {
        return Boolean(this.activeFilters.get(section)?.has(filter));
    }

    /**
     * Hides content by replacing it with a <!-- comment -->
     * @param {Element} element
     * @return {boolean | void}
     */
    hideSection(element) {
        const section = element.closest('.dj-section') ?? element;
        if (!this.hiddenContent.has(section)) {
            const comment = document.createComment('');
            section.replaceWith(comment);
            this.hiddenContent.set(section, comment);
        }
    }

    /**
     * Restores previously hidden content
     * @param {Element} element
     * @return {boolean | void}
     */
    showSection(element) {
        const section = element.closest('.dj-section') ?? element;
        if (this.hiddenContent.has(section)) {
            const comment = this.hiddenContent.get(section);
            comment?.replaceWith(section);
            this.hiddenContent.delete(section);
        }
    }

    /** Restores all hidden content */
    showAllSections() {
        for (const content of this.hiddenContent.keys()) {
            this.showSection(content);
        }
    }

    /**
     * @override
     * @return {import('lit').TemplateResult}
     */
    render() {
        return html`
            ${Object.entries(IOScheduleNav.filters).map(([section, filters]) => html`
                <div class="dj-io-schedule-nav__section" ?data-collapsed=${this.collapsedSections.has(section)}>
                    <h3 @click=${() => this.collapseSection(section)}>${section}</h3>
                    <ul>
                        ${filters.map((filter) => html`
                            <li
                                @click=${() => this.filterToggle(section, filter)}
                                ?data-active=${this.filterIsActive(section, filter)}
                            >
                                ${filter}
                            </li>
                        `)}
                    </ul>
                </div>
            `)}
        `;
    }
}

export { IOScheduleNav };

import { getEventBus } from '../../../microsite/shared-with-old-bundles/eventBus.js';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

const ATTRS = {
	AUTOFOCUS: 'autofocus',
	GROUP_REQUIRED: 'group-required',
	NO_VALIDATE: 'no-validate',
	PLAIN: 'dj-input-plain',
	VALIDATE_ON_SUBMIT: 'dj-validate-on-submit',
}

const CLASSES = {
	DISABLED: 'dj-input--disabled',
	FOCUSED: 'dj-input--focused',
	HAS_VALUE: 'dj-input--has-value',
	HAS_VALUE_PRELOADED: 'dj-input--has-preloaded-value',
	INVALID: 'dj-input--invalid',
	LABEL: 'dj-input__label',
	LABEL_REQUIRED: 'dj-input__label--required',
};

const RADIO_LIKE_TYPES = [
	'radio',
	'checkbox',
];

@customElement('dj-input')
class DjInput extends DjElement {
	@property({
		type: Boolean,
		attribute: ATTRS.NO_VALIDATE,
	})
	noValidate;

	@property({
		type: String,
		attribute: ATTRS.GROUP_REQUIRED,
	})
	groupRequired;

	constructor() {
		super();

		this.nativeFields;
		this.label;
		this.parentForm;
		this.touched = false;
		this.dirty = false;
		this.observers = [];
		this.inputAlreadyFocused = false;

		this.onInput = this.onInput.bind(this);
		this.onFocusChange = this.onFocusChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.setDynamicAttributeClasses = this.setDynamicAttributeClasses.bind(this);
		this.setValidity = this.setValidity.bind(this);
		this.setGroupValidity = this.setGroupValidity.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();

		this.label = this.querySelector(`.${CLASSES.LABEL}`);

		this.parentForm = this.closest(`form[${ATTRS.VALIDATE_ON_SUBMIT}]`);

		if (this.parentForm) {
			this.parentForm.addEventListener('submit', this.onFormSubmit, { passive: true });
		}

		// @TODO: Improve this selector
		this.nativeFields = [...this.querySelectorAll('input,textarea,select')].filter((field) => {
			// input[type="hidden"] and input[dj-input-plain] should both be filtered out
			return field.type !== 'hidden' && field.getAttribute(ATTRS.PLAIN) === null && field.closest('dj-input') === this;
		});
		this.nativeFields.forEach((field) => {
			field.addEventListener('input', this.onInput, { passive: true });
			field.addEventListener('change', this.onInput, { passive: true });
			field.addEventListener('focus', this.onFocusChange, { passive: true });
			field.addEventListener('blur', this.onFocusChange, { passive: true });
			const observer = new MutationObserver((mutations) => {
				if (mutations.some(mutation => mutation.type === 'attributes')) {
					this.setDynamicAttributeClasses(field);
				}
			});
			observer.observe(field, { attributes: true });
			this.observers.push(observer);
			this.setDynamicAttributeClasses(field);
			if (field.type === 'textarea') {
				this.classList.toggle(CLASSES.HAS_VALUE_PRELOADED, !!field.value);
				this.dirty = true;
			} else if (!RADIO_LIKE_TYPES.includes(field.type)) {
				this.classList.toggle(CLASSES.HAS_VALUE_PRELOADED, !!field.getAttribute('value'));
				this.dirty = true;
			}
			//retrieve and set autofocus attribute on first input element
			if (field.hasAttribute(ATTRS.AUTOFOCUS) && !this.inputAlreadyFocused) {
				this.classList.toggle(CLASSES.FOCUSED, true);
				this.inputAlreadyFocused = true;
				//native <select multiple> element is hidden for multiselect, use scrollTo instead of focus
				if (field.tagName === 'SELECT' && field.hasAttribute('multiple')) {
					//disable scroll restoration to prevent browser from reverting to previous scroll location
					if ('scrollRestoration' in history) {
						history.scrollRestoration = 'manual';
					}
					field.nextElementSibling.scrollIntoView({ "block": "center" });
				}
				else {
					field.focus();
				}
			}
		});

		// FIXME: now that inputs are components we don't need the eventbus, we can raise a regular event(?)
		// or possibly don't need events at all
		const eventBus = getEventBus();
		eventBus.emit('dj-input:ready', this);
	}

	disconnectedCallback() {
		super.disconnectedCallback();

		if (this.parentForm) {
			this.parentForm.removeEventListener('submit', this.onFormSubmit, { passive: true });
		}
		this.nativeFields.forEach((field) => {
			field.removeEventListener('input', this.onInput, { passive: true });
			field.removeEventListener('change', this.onInput, { passive: true });
			field.removeEventListener('focus', this.onFocusChange, { passive: true });
			field.removeEventListener('blur', this.onFocusChange, { passive: true });
		});
		this.nativeFields = [];
		this.observers.forEach(observer => observer.disconnect());
		this.observers = [];
		this.label = null;
	}

	onInput({ currentTarget }) {
		if (!RADIO_LIKE_TYPES.includes(currentTarget.type)) {
			this.classList.toggle(CLASSES.HAS_VALUE, !!currentTarget.value);
			if (!currentTarget.value) {
				this.classList.remove(CLASSES.HAS_VALUE_PRELOADED);
			}
		}
		this.dirty = true;
		this.setValidity(currentTarget);
	}

	onFocusChange({ currentTarget, relatedTarget, type }) {
		const isFocused = type === 'focus';
		const hasLeftGroup = !this.contains(relatedTarget);
		if (isFocused) {
			this.touched = true;
		} else if (hasLeftGroup || this.dirty) {
			// Only validate once we leave the group of inputs
			if (['some', 'all'].includes(this.groupRequired)) {
				this.setGroupValidity();
			} else {
				this.setValidity(currentTarget);
			}
		}
		this.classList.toggle(CLASSES.FOCUSED, isFocused);
	}

	onFormSubmit() {
		this.nativeFields.forEach(this.setValidity);
	}

	setDynamicAttributeClasses(field) {
		if (field.type !== 'checkbox') {
			this.label?.classList.toggle(CLASSES.LABEL_REQUIRED, this.label && field.required);
		}
		this.classList.toggle(CLASSES.DISABLED, field.disabled);
	}

	setValidity(field) {
		const isValid = this.noValidate || field.validity.valid;
		this.classList.toggle(CLASSES.INVALID, !isValid);
	}

	setGroupValidity() {
		const method = (this.groupRequired === 'some')? this.nativeFields.some : this.nativeFields.every;

		const isValid = this.noValidate || method.call(
			this.nativeFields,
			field => field.validity.valid &&
				(RADIO_LIKE_TYPES.includes(field.type) ? field.checked : field.value)
		);
		this.classList.toggle(CLASSES.INVALID, !isValid);
	}
}

export { DjInput, ATTRS, CLASSES };

import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

const TARGETS = {
    DEFAULT: 'current-step',
    FORM_ERRORS: 'form-errors',
}

@customElement('dj-scroll-to-section')
class ScrollToSection extends DjElement {
    @property({
        type: String,
    })
    target = TARGETS.DEFAULT;

    connectedCallback() {
        super.connectedCallback();

        // If there are form errors in the page, ALWAYS focus them
        const errorTarget = document.getElementById(TARGETS.FORM_ERRORS);
        if (errorTarget) {
            this.target = TARGETS.FORM_ERRORS;
        }

        // We've since implemented autofocus for inputs.
        // If we have a decided autofocus on the page, abort this focusing behaviour.
        const autoFocus = document.querySelector('[autofocus]');

        const targetEl = errorTarget || document.getElementById(this.target);
        if (targetEl && !autoFocus) {
            // Update url hash to make the browser scroll to section
            window.location.hash = this.target;
            // Wait a tick for scroll to happen before moving focus
            requestAnimationFrame(() => targetEl.focus());
        } else {
            window.location.hash = '';
        }
    }
}

export { ScrollToSection };

import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

@customElement('dj-notification-box')
class NotificationBox extends DjElement {
    constructor() {
        super();
        this.$dismissBtn = this.querySelector(
            '.dj-notification-box__dismiss-button'
        );
        const normalClass = 'dj-notification-box';
        this.dismissingClass = `${normalClass}--dismissing`;
        this.dismissedClass = `${normalClass}--dismissed`;

        // Bind event listeners callbacks
        this.dismiss = this.dismiss.bind(this);
        this.minimize = this.minimize.bind(this);
        this.removeEl = this.removeEl.bind(this);
    }

    dismiss = () => {
        this.classList.add(this.dismissingClass);
        this.addEventListener('transitionend', this.minimize, { once: true });
    };

    removeEl = () => {
        this.remove();
    };

    minimize = () => {
        this.classList.add(this.dismissedClass);
        this.addEventListener('transitionend', this.removeEl, { once: true });
    };

    connectedCallback() {
        super.connectedCallback();
        this.$dismissBtn.addEventListener('click', this.dismiss);
    }

    disconnectedCallback() {
        this.$dismissBtn.removeEventListener('click', this.dismiss);
    }
}

export { NotificationBox };

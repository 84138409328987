import { customElement, property } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

const ITEM_ADDED = 'dj-agenda-block-item:added';
const ITEM_REMOVED = 'dj-agenda-block-item:removed';

const CLASSES = {
    FILTER_TAG_ACTIVE: 'dj-filter-tag--active',
    JS_HIDDEN: 'js-hidden',
}

const DEFAULT_KEYWORD = 'all';


@customElement('dj-agenda')
class Agenda extends DjElement {
    items = [];
    @property({
        type: String,
        attribute: 'default-keyword',
    })
    defaultKeyword=DEFAULT_KEYWORD;

    @property({
        type: Boolean,
        attribute: 'select-today',
    })
    selectToday=false;

    constructor() {
        super();

        this.registerItem = this.registerItem.bind(this);
        this.unregisterItem = this.unregisterItem.bind(this);
        this.filter = this.filter.bind(this);
        this.selectTodaysItem.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener(ITEM_ADDED, this.registerItem);
        this.addEventListener(ITEM_REMOVED, this.unregisterItem);
        this.agendaTabs = this.querySelectorAll('dj-tab-nav-item');
        this.agendaTabs.forEach((item) => item.addEventListener('click', this.filter));
    }

    disconnectedCallback(){
        super.disconnectedCallback();
        this.removeEventListener(ITEM_ADDED, this.registerItem);
        this.removeEventListener(ITEM_REMOVED, this.unregisterItem);
        this.agendaTabs.forEach((item) => item.removeEventListener('click', this.filter));
    }

    registerItem(event) {
        this.items.push(event.detail);
        event.stopPropagation();

        if (this.selectToday && (this.items.length === this.agendaTabs.length)) {
            // if items are named with dates, preselect the one matching today's one.
            //this.selectTodaysItem();  // this runs too early before the nav items are setup
            setTimeout(()=> { this.selectTodaysItem() }, 0);
        }
    }

    unregisterItem(event) {
        this.items.pop(event.detail);
        event.stopPropagation();
    }

    filter(event) {
        const filterFor = event.target.getAttribute('item-name');
        this.items.forEach((item) => {
            if (filterFor === this.defaultKeyword || item.getAttribute('item-name') === filterFor) {
                item.show();
            }
            else {
                item.hide();
            }
        });
    }

    selectTodaysItem() {
        function formatTodaysDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        const today = formatTodaysDate();

        let selected = this.agendaTabs[0];
        if (today > this.agendaTabs[0].getAttribute('item-name')) {
            selected = this.agendaTabs[this.agendaTabs.length - 1];
        } else {
            this.agendaTabs.forEach((tab) => {
                if (tab.getAttribute('item-name') === today) {
                    selected = tab;
                }
            });
        }
        selected.click();
    }

}

@customElement('dj-agenda-item')
class AgendaItem extends DjElement {

    constructor() {
        super();
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
    }

    connectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_ADDED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    disconnectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_REMOVED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    hide() {
        this.hidden = true;
    }

    show() {
        this.classList.remove(CLASSES.JS_HIDDEN);
        this.hidden = false;
    }

}

export { Agenda, AgendaItem, ITEM_ADDED, ITEM_REMOVED, DEFAULT_KEYWORD };

import { customElement, property, state } from 'lit/decorators.js';
import { whenTransitionEnds } from '../../utils';
import { DjElement } from '../base-component/base-component.lit';

const DEFAULT_CLASS = 'dj-h-collapsed';


const SELECTORS = {
    COLLAPSABLE_EL: '[dj-collapse-collapsable]',
    COLLAPSABLE_INPUT: '[dj-collapse-trigger]',
    COLLAPSABLE_CONTROL_CONTAINER: '[dj-collapse-control-container]',
    EL_MESSAGE_COLLAPSED: '[dj-collapse-collapsed-msg]',
    EL_MESSAGE_EXPANDED: '[dj-collapse-expanded-msg]',
}

const setWithoutTransition = (el, styles) => {
    const initialTransition = el.style.transition || '';
    el.style.transition = 'none';

    for (const [prop, val] of Object.entries(styles)) {
        el.style[prop] = val;
    }

    el.style.transition = initialTransition;
}

@customElement('dj-collapse')
class Collapse extends DjElement {
    @property({
        type: String,
        attribute: 'dj-collapse-class',
    })
    djCollapseClass = DEFAULT_CLASS;

    @property({
        type: String,
        attribute: 'collapsed-class',
    })
    collapsedClass;

    @property({
        type: String,
        attribute: 'expanded-class',
    })
    expandedClass;

    @state({
        type: Boolean,
    })
    isCollapsed;

    connectedCallback() {
        super.connectedCallback();

        this.collapse = this.collapse.bind(this);
        this.expand = this.expand.bind(this);
        this.getAutoHeight = this.getAutoHeight.bind(this);
        this.setCollapsedState = this.setCollapsedState.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.transitionCollapseEnded = this.transitionCollapseEnded.bind(this);
        this.transitionExpandEnded = this.transitionExpandEnded.bind(this);
        this.updateAria = this.updateAria.bind(this);
        this.updateMessaging = this.updateMessaging.bind(this);

        this.$collapsableContainer = this.querySelector(SELECTORS.COLLAPSABLE_CONTROL_CONTAINER);
        this.$collapsableEl = this.querySelector(SELECTORS.COLLAPSABLE_EL);
        this.$collapsableInput = this.querySelector(SELECTORS.COLLAPSABLE_INPUT);
        this.$msgCollapsed = this.querySelector(SELECTORS.EL_MESSAGE_COLLAPSED);
        this.$msgExpanded = this.querySelector(SELECTORS.EL_MESSAGE_EXPANDED);

        this.setCollapsedState(this.hasAttribute('collapsed'));

        this.$collapsableInput.addEventListener('click', this.toggleCollapse);
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.toggleCollapse);
    }

    collapse() {
        if (this.isCollapsed) {
            return;
        }

        const currentHeight = this.getAutoHeight();
        // Starting state
        setWithoutTransition(this.$collapsableEl, {height: `${currentHeight}px`});

        whenTransitionEnds(this.$collapsableEl, 'height', this.transitionCollapseEnded);

        // Force reflow.
        this.$collapsableEl.offsetHeight;
        this.$collapsableEl.style.height = '0';

        this.setCollapsedState(true);
    }

    expand() {
        if (!this.isCollapsed) {
            return;
        }

        this.$collapsableEl.hidden = false;
        const targetHeight = this.getAutoHeight();

        // Starting state
        setWithoutTransition(this.$collapsableEl, {
            height: '0',
            visibility: '',
        });

        whenTransitionEnds(this.$collapsableEl, 'height', this.transitionExpandEnded);
        this.updateMessaging(false);

        // Force reflow.
        this.$collapsableEl.offsetHeight;
        this.$collapsableEl.style.height = `${targetHeight}px`;
    }

    transitionCollapseEnded() {
        this.$collapsableEl.style.visibility = 'hidden';
    }

    transitionExpandEnded() {
        this.setCollapsedState(false);
        this.$collapsableEl.style.height = 'auto';
    }

    getAutoHeight() {
        const initialTransition = this.$collapsableEl.style.transition || '';

        this.$collapsableEl.style.transition = 'none';
        this.$collapsableEl.style.height = 'auto';

        const autoHeight = this.$collapsableEl.offsetHeight;

        this.$collapsableEl.style.height = '';
        this.$collapsableEl.style.transition = initialTransition;

        return autoHeight;
    }

    setCollapsedState(newState) {
        this.isCollapsed = newState;
        this.$collapsableEl.classList.toggle(this.djCollapseClass, this.isCollapsed);
        this.updateMessaging(newState);
        this.updateAria();
    }

    toggleCollapse() {
        if (this.isCollapsed){
            this.expand();
        } else {
            this.collapse();
        }
    }

    updateAria() {
        const id = this.$collapsableEl.id;

        if (!id) {
            return;
        }

        const controlledBy = Array.from(
            document.querySelectorAll(`[aria-controls=${id}]`)
        );

        controlledBy.forEach((controller) => {
            controller.setAttribute('aria-expanded', !this.isCollapsed);
        });
    }

    updateMessaging(showCollapsedState = this.isCollapsed) {
        this.$msgExpanded.hidden = showCollapsedState;
        this.$msgCollapsed.hidden = !showCollapsedState;
        this.$collapsableContainer.classList.toggle(this.collapsedClass, showCollapsedState);
        this.$collapsableContainer.classList.toggle(this.expandedClass, !showCollapsedState);
    }
}

export { Collapse, DEFAULT_CLASS, SELECTORS };

import { keynames, bindKeyboardEvents } from '../../utils';
import { focusTrap } from '../../utils/focus-trap.js';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';


const _MODAL = 'dj-dialog'

const CLASSES = {
    VISIBLE_BODY: `${_MODAL}-visible`,
    VISIBLE_MODAL: `${_MODAL}--visible`,
}

const SELECTORS = {
    MODAL_CONTENT:  `.${_MODAL}__body`,
    CLOSE_ICON:  `.${_MODAL}__close`,
    CLOSE_BUTTON:  '[dj-modal-close]',
    TRIGGER: '[dj-modal-trigger]'
}

const ATTRS = {
    TRIGGER_ID: 'dj-modal-trigger-id',
    TRIGGER_URL: 'dj-modal-trigger-url',
    TRIGGER_STOP_PROPAGATION: 'dj-modal-trigger-stop-propagation',
    TRIGGER_NO_CLOSE: 'dj-modal-trigger-no-close',
    TRIGGER_CLASSES: 'dj-modal-trigger-classes',
    FOCUS: 'dj_modal-focus',
}

/**
 * ModalWrapper encapsulates the actual modal operation, opening & closing, capturing focus and events.
 *
 * The ModalTrigger wraps the element opening the modal and configures the modal operation via it's parameters.
 */
@customElement('dj-modal-wrapper')
class ModalWrapper extends DjElement {

    $closeIcon;
    $modalContent;
    $body;
    $triggers;

    appliedClasses = null;
    modalOpen = false;;
    preventClose = false;

    unbindKeys = [];

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        this.$body = document.querySelector('body');
        this.$modalContent = this.querySelector(SELECTORS.MODAL_CONTENT);
        this.$closeIcon = this.querySelector(SELECTORS.CLOSE_ICON);

        this.setAttribute('role', 'dialog');
        this.$closeIcon.setAttribute('aria-label', 'Close modal');

        // Close modal on Esc key
        this.unbindKeys.push(bindKeyboardEvents(document, {
            [keynames.ESCAPE]: this.closeHandler
        }, 'keyup'));

        // Close modal on clicking its backdrop
        this.addEventListener('click', this.backgroundCloseHandler);

        // Attach an open handler to all triggers
        this.$triggers = document.querySelectorAll(SELECTORS.TRIGGER);
        this.$triggers.forEach((element) => {
            element.setAttribute('role', 'button');
            element.setAttribute('tabindex', '0');
            element.setAttribute('aria-haspopup', 'true');
            element.setAttribute('aria-controls', element.getAttribute(ATTRS.TRIGGER_ID));
            element.addEventListener('click', this.openHandler);
            this.unbindKeys.push(bindKeyboardEvents(element, {
                [keynames.ENTER]: this.openHandler,
                [keynames.SPACE]: this.openHandler,
            }));
        });
        this.updateAriaAttributes();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('click', this.backgroundCloseHandler);
        this.$triggers.forEach((element) => {
            element.removeEventListener('click', this.openHandler);
        });
        this.unbindKeys.forEach((unbind) => unbind());
    }

    openModal = (contentParent, config) => {
        this.modalOpen = true;
        // Vanilla js content appending:
        this.$modalContent.innerHTML = '';
        while (contentParent.firstChild) {
            this.$modalContent.appendChild(contentParent.firstChild);
        }

        this.appliedClasses = [CLASSES.VISIBLE_MODAL];
        if (config.classes) {
            this.appliedClasses.push( ...config.classes.split(" "));
        }

        this.classList.add(...this.appliedClasses);
        this.$body.classList.add(CLASSES.VISIBLE_BODY);

        // If 'noClose' config option is present, hide close button.
        // Otherwise show it and attach close listener
        if (config.noClose) {
            this.preventClose = true;
            const closeIcon = this.querySelector(SELECTORS.CLOSE_ICON);
            closeIcon.style.display = 'none';
        } else {
            this.preventClose = false;
            const closeIcon = this.querySelector(SELECTORS.CLOSE_ICON);
            closeIcon.style.display = 'block';
            this.attachClose(SELECTORS.CLOSE_ICON);
        }
        // Add close listener to any inner close buttons. This
        // is done regardless of the noClose option so it can be
        // controlled by components within the modal

        this.attachClose(SELECTORS.CLOSE_BUTTON);

        this.updateAriaAttributes();
        let focusable;
        if (config.focus) {
            focusable = this.$modalContent.querySelector(config.focus);
        }

        this.clearFocusTrap = focusTrap.create(this, {focus: focusable});
    }

    closeModal = () => {
        this.modalOpen = false;

        this.classList.remove(...this.appliedClasses);
        this.appliedClasses = null;
        this.$body.classList.remove(CLASSES.VISIBLE_BODY);
        this.$modalContent.innerHTML = '';

        // Remove close listener from any inner close buttons
        this.detachClose(SELECTORS.CLOSE_BUTTON);
        this.clearFocusTrap();
        this.updateAriaAttributes();
    }

    closeHandler = () => {
        if (!this.preventClose && this.modalOpen) this.closeModal();
    }

    backgroundCloseHandler = (evt) => {
        if (evt.target == this) this.closeHandler();
    }

    openHandler = async (evt) => {
        evt.preventDefault();
        const element = evt.target.closest(SELECTORS.TRIGGER);

        const contentId = element.getAttribute(ATTRS.TRIGGER_ID);
        const contentUrl = element.getAttribute(ATTRS.TRIGGER_URL);
        const stopPropagation = element.hasAttribute(ATTRS.TRIGGER_STOP_PROPAGATION);

        const config = {};
        if (contentId) {
            config.id = contentId;
        } else if (contentUrl) {
            config.url = contentUrl;
        } else return;

        config.noClose = element.hasAttribute(ATTRS.TRIGGER_NO_CLOSE);
        config.classes = element.getAttribute(ATTRS.TRIGGER_CLASSES);
        config.focus = element.getAttribute(ATTRS.FOCUS);

        if (stopPropagation) {
            evt.stopPropagation();
        }
        if (config.id) {
            const source = document.querySelector(`#${config.id}`).cloneNode(true);
            this.openModal(source, config);

        } else if (config.url) {
            try {
                const response = await fetch(config.url);

                const div = document.createElement('div');
                response.text().then(content => {
                    div.innerHTML = content;
                    this.openModal(div, config);
                });
            } catch (e) {
                return null;
            }
        }
    }

    attachClose = (selector) => {
        this.querySelectorAll(selector).forEach((node) => {
            node.addEventListener('click', this.closeModal);
        });
    }

    detachClose = (selector) => {
        this.querySelectorAll(selector).forEach((node) => {
            node.removeEventListener('click', this.closeModal);
        });
    }

    updateAriaAttributes = () => {
        this.setAttribute('aria-hidden', !this.modalOpen);
    }
}


export { ModalWrapper, CLASSES, ATTRS, SELECTORS };

import { customElement, property } from 'lit/decorators.js';
import moment from '../../utils/moment-django';

import { debounce } from '../../utils';
import { DjElement } from '../base-component/base-component.lit';
import uncloak from '../../utils/uncloak';

export const CLASSES = {
    HIDDEN: 'hidden',
    MIN_MAX_ERROR_HIDDEN: 'min-max-error--hidden',
    NOT_ATTENDING: 'dj-not-attending',
    START_TIME_ERROR_HIDDEN: 'same-start-time-error--hidden',
};

export const SELECTORS = {
    CHECKED_INPUT: 'input:checked',
    CSRF_INPUT: 'input[name="csrfmiddlewaretoken"]',
    EMAIL_INPUT: 'input#id_user_email',
    FORM: 'form',
    MANAGE_REGISTRATION_BUTTON: '#manage-registration-button',
    NOT_ATTENDING: 'input[type="radio"][value="False"]',
    RSVP_OPTIONS: '[dj-conditional-field="are_you_attending"]',
    SESSION_DATE: '.session-checkbox-label__date-location',
    SESSION_DATETIME: '.session-checkbox-label__datetime',
    SESSION_DETAILS: '.session-checkbox-label__date-location',
    SESSION_GROUP_ITEM: '.session-group-item',
    SESSION_GROUPS: '.session-groups',
    SESSIONS: '#sessions',
    SUBMIT: 'input[type="submit"], button[type="submit"]',

    // Errors
    GROUP_MAX_ERROR: '.group-max-error',
    GROUP_MIN_ERROR: '.group-min-error',
    MIN_MAX_ERROR: '.min-max-error',
    REGISTRATION_FORM: '.registration-form',
    SAME_START_TIME_ERROR: '.same-start-time-error',
};


@customElement('dj-microsite-form')
export class DjMicrositeForm extends DjElement {
    @property({
        type: String,
        attribute: 'dj-model-type',
    })
    djModelType;

    @property({
        type: String,
        attribute: 'dj-session-api-endpoint',
    })
    djSessionApiEndpoint;

    @property({
        type: Boolean,
        attribute: 'dj-convert-session-dates',
    })
    djConvertSessionDates;

    @property({
        type: String,
        attribute: 'dj-alert-before-unload',
    })
    djAlertBeforeUnload;

    minSessions = 0;
    maxSessions = Infinity;

    constructor() {
        super();

        this.alertBeforeUnload = this.alertBeforeUnload.bind(this);
        this.areSessionsHidden = this.areSessionsHidden.bind(this);
        this.checkedCount = this.checkedCount.bind(this);
        this.checkSameStartTime = this.checkSameStartTime.bind(this);
        this.convertAllSessionDatetimes = this.convertAllSessionDatetimes.bind(this);
        this.convertSessionDatetime = this.convertSessionDatetime.bind(this);
        this.getNotAttendingOption = this.getNotAttendingOption.bind(this);
        this.hideMinMaxError = this.hideMinMaxError.bind(this);
        this.hideSameStartTimeError = this.hideSameStartTimeError.bind(this);
        this.isNotAttending = this.isNotAttending.bind(this);
        this.minMaxValid = this.minMaxValid.bind(this);
        this.onEmailInput = this.onEmailInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.showMinMaxError = this.showMinMaxError.bind(this);
        this.showSameStartTimeError = this.showSameStartTimeError.bind(this);
        this.showSessionsIfHidden = this.showSessionsIfHidden.bind(this);
        this.toggleAttendeeOnlySteps = this.toggleAttendeeOnlySteps.bind(this);
        this.toggleEnableSubmit = this.toggleEnableSubmit.bind(this);
        this.validateGroupMinMax = this.validateGroupMinMax.bind(this);
        this.validateSessions = this.validateSessions.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        this.$formEl = this.querySelector(SELECTORS.FORM);
        this.$registrationForm = document.querySelector(SELECTORS.REGISTRATION_FORM);
        this.$emailInput = this.querySelector(SELECTORS.EMAIL_INPUT);
        this.$sessionsContainer = this.querySelector(SELECTORS.SESSIONS);
        this.$manageRegistrationBtn = this.querySelector(SELECTORS.MANAGE_REGISTRATION_BUTTON);
        this.$sessionGroups = this.querySelector(SELECTORS.SESSION_GROUPS);
        this.$minMaxError = this.querySelector(SELECTORS.MIN_MAX_ERROR);
        this.$sameStartTimeError = this.querySelector(SELECTORS.SAME_START_TIME_ERROR);
        this.$submit = this.querySelector(SELECTORS.SUBMIT);
        this.$rsvpOptions = this.querySelector(SELECTORS.RSVP_OPTIONS);
        this.$rsvpNotAttendingOption = null;
        this.CSRF_TOKEN = document.querySelector(SELECTORS.CSRF_INPUT)?.value

        // We don't need to load and display sessions depending on the
        // user's input for feedback, only for registration form
        this.isFeedback = this.djModelType === 'feedback';

        if (this.$emailInput && this.$sessionGroups && !this.isFeedback) {
            this.toggleEnableSubmit(false);
        }

        this.convertAllSessionDatetimes();

        this.onEmailInputDebounced = debounce(() => this.onEmailInput(), 600);
        if (this.$emailInput && this.djSessionApiEndpoint && !this.isFeedback) {
            this.$emailInput.addEventListener('input', this.onEmailInputDebounced, { passive: true });
            this.$emailInput.addEventListener('input', this.showSessionsIfHidden, { passive: true });
            // Trigger check on page load as well, sometimes email is pre-filled
            this.onEmailInputDebounced();
        }

        // Show/hide steps based on initial inputs, in case of autofill etc
        if (this.$rsvpOptions) {
            this.$rsvpOptions.addEventListener('change', this.toggleAttendeeOnlySteps);
            this.toggleAttendeeOnlySteps();
        }

        if (this.$sessionGroups) {
            this.$sessionGroups.addEventListener('change', this.validateSessions);
        }

        if (this.djAlertBeforeUnload) {
            window.addEventListener('beforeunload', this.alertBeforeUnload);
        }

        this.$formEl.addEventListener('submit', this.onSubmit);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        // @TODO Unbind events
        this.$emailInput?.removeEventListener('input', this.onEmailInputDebounced, { passive: true });
        this.$emailInput?.removeEventListener('input', this.showSessionsIfHidden, { passive: true });
        this.$rsvpOptions?.removeEventListener('change', this.toggleAttendeeOnlySteps);
        this.$sessionGroups?.removeEventListener('change', this.validateSessions);
        window.removeEventListener('beforeunload', this.alertBeforeUnload);
        this.$formEl.removeEventListener('submit', this.onSubmit);
    }

    async onEmailInput() {
        if (this.isFeedback) {
            return;
        }

        const email = this.$emailInput.value;
        if (!email) {
            this.toggleEnableSubmit(false);
            return;
        }

        const data = new URLSearchParams();
        data.append('email', email);
        // @TODO try/catch
        const response = await fetch(this.djSessionApiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-CSRFToken': this.CSRF_TOKEN,
            },
            body: data,
        });
        const responseData = await response.json();
        if (!responseData.valid) {
            this.toggleEnableSubmit(false);
            return;
        }

        if (responseData.redirect) {
            window.location.assign(responseData.redirect);
            return;
        }

        if (responseData.sessions_html && this.$sessionGroups) {
            this.$sessionGroups.innerHTML = responseData.sessions_html;
            this.convertAllSessionDatetimes();
            uncloak(this.$sessionGroups);
        }
        this.minSessions = responseData.min_sessions || this.minSessions;
        this.maxSessions = responseData.max_sessions || this.maxSessions;

        this.validateSessions();
    }

    convertAllSessionDatetimes() {
        if (!this.djConvertSessionDates) {
            return;
        }

        [...this.querySelectorAll(SELECTORS.SESSION_DATE)].forEach(this.convertSessionDatetime);
    }

    convertSessionDatetime($el) {
        const start = $el.dataset.sessionStartTime;
        const end = $el.dataset.sessionEndTime;
        const dateFormat = $el.dataset.sessionDateFormat;
        const timeFormat = $el.dataset.sessionTimeFormat;

        const locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);

        let userStartDate = '';
        let userEndDate = '';

        if (typeof dateFormat !== 'undefined') {
            userStartDate = moment(start).django(dateFormat);
            if (userStartDate !== moment(end).django(dateFormat)) {
                userEndDate = moment(end).django(dateFormat);
            }
        }
        if (typeof timeFormat !== 'undefined') {
            if (userStartDate !== '') {
                userStartDate += ' ';
            }
            userStartDate += moment(start).django(timeFormat);
            // Don't repeat the end time if it's the same as the start on the same date.
            if (moment(start).format('YYYY-MM-DD') !== moment(end).format('YYYY-MM-DD') ||
                moment(start).format('H:m') !== moment(end).format('H:m')) {
                userEndDate += ' ' + moment(end).django(timeFormat);
            }
        }
        // Strip leading/trailing whitespace
        let displayDate = userStartDate.replace(/^\s+|\s+$/g, '');
        if (userEndDate !== '') {
            displayDate += ' - ' + userEndDate.replace(/^\s+|\s+$/g, '');
        }
        const tz = moment.tz.guess(true);
        const labelDatetime = displayDate + ' ' + moment.tz(tz).zoneAbbr() + ' - ' + tz;
        const label = $el.querySelector(SELECTORS.SESSION_DATETIME);
        if (label) {
            label.textContent = labelDatetime;
        }
    }

    showSessionsIfHidden() {
        // If sessions are hidden and the email address changes, display them back
        if (this.$submit.classList.contains(CLASSES.HIDDEN)) {
            this.$submit.classList.remove(CLASSES.HIDDEN);
            this.$manageRegistrationBtn.classList.add(CLASSES.HIDDEN);
        }
        if (this.areSessionsHidden()) {
            this.$sessionsContainer.classList.remove(CLASSES.HIDDEN);
        }
    }

    alertBeforeUnload(event) {
        const message = this.djAlertBeforeUnload;

        event.returnValue = message;
        return message;
    }

    onSubmit() {
        window.removeEventListener('beforeunload', this.alertBeforeUnload);
        this.toggleEnableSubmit(false);
    }

    validateSessions() {
        const isMinMaxValid = this.minMaxValid();
        const isStartTimesValid = this.checkSameStartTime();

        if (!isMinMaxValid) {
            this.showMinMaxError();
        }
        if (!isStartTimesValid) {
            this.showSameStartTimeError();
        }
        if (isMinMaxValid && isStartTimesValid) {
            this.hideMinMaxError();
            this.hideSameStartTimeError();
        }
        this.validateGroupMinMax(isMinMaxValid && isStartTimesValid);
    }

    areSessionsHidden() {
        return this.$sessionsContainer?.classList.contains(CLASSES.HIDDEN);
    }

    checkedCount($el) {
        if ($el) {
            return $el.querySelectorAll(SELECTORS.CHECKED_INPUT).length;
        }
        return 0;
    }

    validateGroupMinMax(isFormValid) {
        let showError = false;
        const groups = this.$sessionGroups?.querySelectorAll(SELECTORS.SESSION_GROUP_ITEM) || [];
        [...groups].forEach(($group) => {
            const groupMin = $group.dataset.min;
            const groupMax = $group.dataset.max;
            const groupCount = this.checkedCount($group);
            const $minError = $group.querySelector(SELECTORS.GROUP_MIN_ERROR);
            const $maxError = $group.querySelector(SELECTORS.GROUP_MAX_ERROR);

            $minError?.classList.add(CLASSES.HIDDEN);
            $maxError?.classList.add(CLASSES.HIDDEN);

            if (groupMin && groupMin > groupCount) {
                $minError?.classList.remove(CLASSES.HIDDEN);
                showError = true;
            } else if (groupMax && groupMax < groupCount) {
                $maxError?.classList.remove(CLASSES.HIDDEN);
                showError = true;
            }
        });
        if (isFormValid) {
            this.toggleEnableSubmit(!showError);
        }
    }

    minMaxValid() {
        const count = this.checkedCount(this.$sessionGroups);
        return count >= this.minSessions && count <= this.maxSessions;
    }

    checkSameStartTime() {
        // Restrict registrations to sessions with same start time only
        // if the error is on the page, i.e. if the restriction is enabled
        if (!this.$sameStartTimeError || this.areSessionsHidden()) {
            return true;
        }

        const startTimes = [];
        let showError = false;
        // @TODO Could be optimised using a `some`
        this.$sessionGroups?.querySelectorAll(SELECTORS.CHECKED_INPUT).forEach((input) => {
            const $sessionDetails = input.parentElement.querySelector(SELECTORS.SESSION_DETAILS);
            const startTime = $sessionDetails.dataset.sessionStartTime;
            if (startTimes.indexOf(startTime) > -1) {
                showError = true;
                return;
            }
            startTimes.push(startTime);
        });
        return !showError;
    }

    getNotAttendingOption() {
        // @TODO test this selector
        this.$rsvpNotAttendingOption = this.$rsvpOptions.querySelector(SELECTORS.NOT_ATTENDING);
    }

    isNotAttending() {
        if (!this.$rsvpNotAttendingOption) {
            this.getNotAttendingOption();
        }
        return !!(this.$rsvpNotAttendingOption && this.$rsvpNotAttendingOption.checked);
    }

    toggleAttendeeOnlySteps() {
        this.$registrationForm.classList.toggle(CLASSES.NOT_ATTENDING, this.isNotAttending());
    }

    hideMinMaxError() {
        this.$minMaxError?.classList.add(CLASSES.MIN_MAX_ERROR_HIDDEN);
        this.toggleEnableSubmit(true);
    }

    hideSameStartTimeError() {
        this.$sameStartTimeError?.classList.add(CLASSES.START_TIME_ERROR_HIDDEN);
        this.toggleEnableSubmit(true);
    }

    showMinMaxError() {
        this.$minMaxError?.classList.remove(CLASSES.MIN_MAX_ERROR_HIDDEN);
        this.toggleEnableSubmit(false);
    }

    showSameStartTimeError() {
        this.$sameStartTimeError?.classList.remove(CLASSES.START_TIME_ERROR_HIDDEN);
        this.toggleEnableSubmit(false);
    }

    toggleEnableSubmit(enabled) {
        if (this.$submit) {
            this.$submit.disabled = !enabled;
        }
    }
};

import { html } from 'lit';
import { closest, getOffsetTop, hrefMatchesUrl } from '../../utils.js';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';
import { SPY_CREATED } from '../scroll-container/scroll-container.lit.js';

@customElement('dj-scroll-spy-nav')
class ScrollSpyNav extends DjElement {

    @property({
        type: String,
        attribute: 'active-element',
    })
    activeElement;

    @property({
        type: String,
        attribute: 'active-class',
    })
    activeClass;

    @property({
        type: String,
        attribute: 'offset-element',
    })
    offsetElement;

    @property({
        type: String,
        attribute: 'section-element',
    })
    sectionElement;

    offset = 0;
    className;
    links;
    sections;

    constructor() {
        super();

        this.onScroll = this.onScroll.bind(this);
        this.setup = this.setup.bind(this);

        this.resizeHandler = this.resizeHandler.bind(this);
    }

    connectedCallback() {
        this.className = this.activeClass || 'is-active';
        this.sectionElement = this.sectionElement || '.dj-section';

        this.setup();

        this.dispatchEvent(
            new CustomEvent(SPY_CREATED, { bubbles: true, detail: this })
        );
    }

    resizeHandler(scrollContainer) {
        this.setup();
        this.onScroll(scrollContainer.getScroll());
    }

    onScroll(scroll) {
        this.sections.forEach((section) => {
            const sectionOffset = getOffsetTop(section);
            if (scroll.top >= sectionOffset - this.offset) {
                this.links.forEach((link) => {
                    const item = this.activeElement
                        ? closest(this.activeElement, link)
                        : link;

                    item.classList.remove(this.className);

                    const linkHref = link.getAttribute('href');
                    const isSamePage = hrefMatchesUrl(linkHref, window.location.href);

                    if (isSamePage) {
                        const target = linkHref.substring(linkHref.indexOf('#'));
                        if (section.id && target === `#${section.id}`) {
                            item.classList.add(this.className);
                        }
                    }
                });
            }
        });
    }

    setup() {
        if (self.offsetElement) {
            this.offset = document.querySelector(self.offsetElement).offsetHeight;
        }

        this.sections = [...document.querySelectorAll(this.sectionElement)];

        // Ignore sections without an id as only those with an ID can be linked to from
        // the nav bar.
        this.sections = this.sections.filter((section) => {
            return section.id;
        });

        // Skip specific class in case there are some nav items that
        // points to external pages

        this.links = Array.prototype.slice.call(
            this.querySelectorAll('a:not(.dj-nav-link--external)')
        );
    }
}

export { ScrollSpyNav };

import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

@customElement('dj-io-faq-nav')
class IOFAQNav extends DjElement {
    /** @type {{ section: Element, title: string }[]} */
    sections = [];

    /** @override */
    connectedCallback() {
        super.connectedCallback();
        this.classList.add('dj-io-faq-nav');
        document.querySelector('.dj-content')?.classList.add('dj-content--with-io-faq-nav');

        // Find FAQ section elements
        for (const section of document.querySelectorAll('.io24-faq-section')) {
            const title = section.querySelector('h1, h2, h3, h4, h5, h6')?.textContent;
            if (title) this.sections.push({ section, title });
        }
    }

    /**
     * Event handler that scrolls to the given section element
     * @param {Element} section
     * @returns {() => void}
     */
    onClick(section) {
        return () => section.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * @override
     * @return {import('lit').TemplateResult}
     */
    render() {
        return html`
            <h3>Frequently asked questions</h3>
            <ul>
                ${this.sections.map(({ section, title }) => html`
                    <li @click=${this.onClick(section)}>${title}</li>
                `)}
            </ul>
        `;
    }
}

export { IOFAQNav };

import { debounce } from './utils.js'

const callbacks = [];
let size = {
    height: window.innerHeight,
    width: window.innerWidth,
};

const resizeHandler = (cb) => {
    if (typeof cb === 'function') {
        callbacks.push(cb);
    }
}

const onResize = () => {
    const newSize = {
        height: window.innerHeight,
        width: window.innerWidth,
    };
    callbacks.forEach(function (cb) {
        cb(newSize, size);
    });
    size = {...newSize};
}

const doResize = debounce(onResize, 250, true);
window.addEventListener('resize', doResize);

const resize = {
    resizeHandler: resizeHandler,
    onResize: onResize,
};

export { resize };

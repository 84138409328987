export const keynames = {
    BACKSPACE: 'BACKSPACE',
    DOWN: 'DOWN',
    ENTER: 'ENTER',
    ESCAPE: 'ESCAPE',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    SPACE: 'SPACE',
    TAB: 'TAB',
    UP: 'UP',
};

/**
 * Simple function for listening for specific keyboard events
 *
 * @param  {Element|angular.element} element
 *         The element to listen for events on
 * @param  {Object.<keyname, Function>} callbacks
 *         A mapping of keynames (enum at the top of this file) to
 *         callback functions that should be called for events for that
 *         key.
 * @param  {string} eventName
 *         The name of the keyboard event to listen for (keyup etc).
 *         Defaults to keypress.
 * @return {Function}
 *         A function for unbinding the keyboard events
 */
export const bindKeyboardEvents = (element, callbacks, eventName = 'keypress') => {
    const codes = {
        ' ': keynames.SPACE,
        ArrowDown: keynames.DOWN,
        ArrowLeft: keynames.LEFT,
        ArrowRight: keynames.RIGHT,
        ArrowUp: keynames.UP,
        Enter: keynames.ENTER,
        Escape: keynames.ESCAPE,
        Tab: keynames.TAB,
    };

    const keyCodes = {
        9: keynames.TAB,
        13: keynames.ENTER,
        27: keynames.ESCAPE,
        32: keynames.SPACE,
        37: keynames.LEFT,
        38: keynames.UP,
        39: keynames.RIGHT,
        40: keynames.DOWN,
    };

    const keysOfInterest = Object.keys(callbacks);

    const _handleKeypress = (event) => {
        let friendlyName;

        if (event.key) {
            friendlyName = codes[event.key];
        } else {
            const keyCode = event.keyCode || event.which;
            friendlyName = keyCodes[keyCode];
        }

        if (keysOfInterest.includes(friendlyName)) {
            const callback = callbacks[friendlyName];
            callback(event);
        }
    };

    element.addEventListener(eventName, _handleKeypress);
    return () => element.removeEventListener(eventName, _handleKeypress);
};

let idCounter = 0;
/**
 * Generates a generally uniqueId. If prefix is given, the ID is
 * appended to it.
 *
 * This function is borrowed from Lodash.
 * https://lodash.com/docs/#uniqueId.
 *
 * @param {string} prefix The value to prefix the ID with.
 * @return {string} Returns a unique ID.
 */
export const uniqueId = (prefix = '') => {
    idCounter = idCounter + 1;
    const id = idCounter;
    return prefix + id;
};

let nowFn;

if ('performance' in window) {
    nowFn = function () {
        return window.performance.now();
    };
} else {
    var nowOffset = Date.now();

    nowFn = function () {
        return Date.now() - nowOffset;
    };
}

/**
 * Returns a function that will execute only after at least tresholds seconds have
 * elapsed since the last call
 * @param  {function} fn         The function to debounce
 * @param  {number}   threshhold Delay in milliseconds, defaults to 250ms
 * @param  {boolean}  skipApply  Skip the $digest cycle after function execution
 * @return {function}            The debounced function
 */
export const debounce = (fn, threshhold) => {
    threshhold = threshhold || (threshhold = 250);
    var last, deferTimer;

    return function () {
        var nowTime = nowFn(),
            args = arguments;
        if (nowTime - last < threshhold) {
            clearTimeout(deferTimer);
        }

        deferTimer = setTimeout(function () {
            fn.apply(undefined, args);
        }, threshhold);

        last = nowTime;
    };
};


/**
 * Polyfill for browsers that do not support Element.closest(),
 * but carry support for document.querySelectorAll().
 * Copied from MDN: https://goo.gl/MppzCr
 * @param {string} selector CSS selector.
 * @param {Object|Element} element HTML Element or jQuery-light collection.
 * @return {?Element} Closest element that matches the selector or null.
*/
export const closest = (selector, element) => {
    let ret;

    if (window.Element && Element.prototype.closest) {
        ret = element.closest(selector);
    } else {
        const matches = document.querySelectorAll(selector);
        let i;

        do {
            i = matches.length;
            // Diables dince taken from MDN
            // eslint-disable-next-line
            while (--i >= 0 && matches.item(i) !== element) {};
        } while ((i < 0) && (element = element.parentElement));

        ret = element;
    }

    return ret;
};

export const hrefMatchesUrl = (href, location = window.location.href) => {
    const hrefLink = document.createElement('a');
    const locationLink = document.createElement('a');
    hrefLink.href = href;
    locationLink.href = location;

    return `${hrefLink.host}${hrefLink.pathname}` === `${locationLink.host}${locationLink.pathname}`;
};

export const breakpoint = (element = window.document.body) => {
    return window.getComputedStyle(
        element, ':before'
    ).getPropertyValue('content').replace(/\"/g, '');
};


/**
 * Perform a bottom-up walk of the given dom node
 * folding the result in the resulting accumulator.
 *
 * See Array.prototype.reduce
 *
 * @param  {Node} node Subtree DOM tip (can be a document fragment)
 * @param  {function} fn(acc, node): newAcc  function that reduce the tree
 * @param  {*} acc  initial accumulator value
 * @return {*} final accumulator value
 */
export const reduceNodeUp = (node, fn, acc) => {
    acc = fn(acc, node);

    if (node.parentNode) {
        acc = reduceNodeUp(node.parentNode, fn, acc);
    }
    return acc;
};

export const whenTransitionEnds = ($element, property, callback) => {
    const transitions = getTransitions($element);
    const transition = transitions[property] || transitions['all'];

    // If the element doesn't have any transition rule applied,
    // then call the callback immediately…
    if (!transition) {
        callback.call(this);
        return;
    }

    // …otherwise bind to transitionend and call it once
    // that fires.
    $element.addEventListener('transitionend', transitionEndCallback);

    function transitionEndCallback(event) {
        if (event.propertyName === property) {
            $element.removeEventListener('transitionend', transitionEndCallback);
            callback.call(this);
        }
    }
}


export const getTransitions = ($element) => {
    const transitions = {};
    const transitionTimingFunctionRe = /ease|ease-in|ease-out|ease-in-out|cubic-bezier\(\d,\d,\d,\d\)/;
    const style = window.getComputedStyle($element);
    const delays = style.transitionDelay.split(',');
    const durations = style.transitionDuration.split(',');
    const properties = style.transitionProperty.split(',');
    const easings = style.transitionTimingFunction.split(transitionTimingFunctionRe);

    properties.forEach((property, index) => {
        property = property.trim();
        transitions[property] = {
            delay: (delays[index] || delays[0]).trim(),
            duration: (durations[index] || durations[0]).trim(),
            timingFunction: (easings[index] || easings[0]).trim(),
        };
    });

    return transitions;
}


/**
 * Disable scroll on the body element by making it position fixed
 * compensating current scroll with negative top position
 *
 * @return {function} Function to restore scroll
 */
export const disableBodyScroll = () => {
    const htmlNode = document.body.parentNode;
    const restoreHtmlStyle = htmlNode.style.cssText || '';
    const restoreBodyStyle = document.body.style.cssText || '';
    const scrollOffset = document.documentElement.scrollTop || document.body.scrollTop;
    const clientWidth = document.body.clientWidth;
    const $body = document.body;

    $body.style.position = 'fixed';
    $body.style.width = '100%';
    $body.style.top = -scrollOffset + 'px';
    htmlNode.style.overflowY = 'scroll';

    if ($body.clientWidth < clientWidth) {
        $body.style.overflow = 'hidden';
    }

    return function restoreScroll() {
        document.body.style.cssText = restoreBodyStyle;
        htmlNode.style.cssText = restoreHtmlStyle;
        document.body.scrollTop = scrollOffset;
        htmlNode.scrollTop = scrollOffset;
    };
}


/**
 * Compute the "offsetTop" taking into account both position fixed
 * element and relatively position ones
 * @param {Element} el        Element to compute offset for
 * @param {Element} parent    Element to consider as root
 * @param {boolean} recurring NOT TO USE, used internally by recursion
 * @return {number}
 */
export const getOffsetTop = (el, parent = document.body, recurring) => {
    let elOffset = el.offsetTop;
    if (el === parent) {
        return 0;
    }
    if (!el.offsetParent || el.offsetParent === document.body) {
        if (window.getComputedStyle(el).position === 'fixed' && !recurring) {
            elOffset += document.documentElement.scrollTop || window.scrollY;
        }
        return elOffset;
    }
    return getOffsetTop(el.offsetParent, parent, true) + el.offsetTop;
}


/**
 * Ensure a given node is (verically) visible and not hidden by
 * scroll. If not, perform the minimum required scroll to fix it
 * @param  {Element} element    Element to ensure is in view
 * @param  {?Element} container Scroll container (body if none)
 * @return {number}             Delta of performed scroll
 */
export const ensureInView = (element, container, margin = 0) => {
    const onBody = !container;
    const height = (element.cachedHeight === undefined) ? element.offsetHeight : element.cachedHeight;

    const scrollerHeight = onBody ? window.innerHeight : container.offsetHeight;
    const offset = getOffsetTop(element, container);
    const scroll = onBody ? (document.documentElement.scrollTop || window.scrollY) : container.scrollTop;
    const distanceFromTop = offset - scroll;
    const distanceFromBottom = offset + height - scroll - scrollerHeight;

    let toScroll = scroll;

    if (distanceFromTop < 0) {
        toScroll = offset + margin;
    } else if (distanceFromBottom > 0) {
        toScroll = offset + height - scrollerHeight + margin;
    }

    if (toScroll !== scroll) {
        if (onBody) {
            window.scrollTo(0, toScroll);
        } else {
            container.scrollTop = toScroll;
        }
    }

    return scroll - toScroll;
}


/**
 * Reliably measure an invisible element's height
 * @param  {Element} element    Element to measure
 * @return {number}             Element's height
 */
export const calcHeight = (element) => {
    const style = window.getComputedStyle(element),
        display = style.display,
        position = style.position,
        visibility = style.visibility,
        maxHeight = style.maxHeight.replace('px', '').replace('%', '');

    let height = 0;

    // if its not hidden we just return normal height
    if(display !== 'none' && maxHeight !== '0') {
        return element.offsetHeight;
    }

    // the element is hidden so:
    // making the el block so we can meassure its height but still be hidden
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.display = 'block';

    height = element.offsetHeight;

    // reverting to the original values
    element.style.display = display;
    element.style.position = position;
    element.style.visibility = visibility;

    return height;
}

import { resize } from '../../resize.js';
import { getOffsetTop } from '../../utils.js';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';
import { SPY_CREATED } from '../scroll-container/scroll-container.lit.js';

@customElement('dj-scroll-spy')
class ScrollSpy extends DjElement {

    @property({
        type: String,
        attribute: 'offset-height',
    })
    offsetHeight;

    @property({
        type: String,
        attribute: 'scroll-class-name',
    })
    scrollClassName;

    offset;
    className;

    constructor() {
        super();

        this.onScroll = this.onScroll.bind(this);
        this.setup = this.setup.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        const api =  {
            onScroll: this.onScroll,
        };
        this.className = this.scrollClassName || 'locked';

        this.dispatchEvent(
            new CustomEvent(SPY_CREATED, { bubbles: true, detail: this })
        );

        this.setup();
    }

    resizeHandler(scrollContainer) {
        this.setup();
        this.onScroll({
            top: scrollContainer.getScrollTop(),
        });
    }

    onScroll(scroll) {
        if (scroll.top > this.offset) {
            this.classList.add(this.className);
        } else {
            this.classList.remove(this.className);
        }
    }

    setup() {
        const hadClass = this.classList.contains(this.className),
            $spyOffsetHeight = document.querySelector(this.offsetHeight);

        // If sepcified, use a selector for an item whose height
        // will be used as offset
        if (this.offsetHeight) {
            // NOTE: not very generic: this is used to "unfix"
            // when scroll reaches the bottom of the masthead
            // Maybe there's a better/more general way of doing this?
            this.offset = $spyOffsetHeight.offsetHeight;
        } else {
            this.classList.remove(this.className);
            this.offset = getOffsetTop(this);
            if (hadClass) {
                this.classList.add(this.className);
            }

            if (this.djScrollSpyRelativeOffset) {
                this.offset -= $spyOffsetHeight.offsetHeight;
            }
        }
    }
}

export { ScrollSpy };


import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

import 'moment-timezone';
import moment from '../../utils/moment-django';


@customElement('dj-when-widget')
class DjWhenWidget extends DjElement {

    @property({ attribute: 'show-user-timezone'})
    showUserTimezone;

    @property({ attribute: 'start-date' })
    startDate;

    @property({ attribute: 'finish-date' })
    finishDate;

    @property({ attribute: 'date-format' })
    dateFormat;

    @property({ attribute: 'time-format' })
    timeFormat;

    @property({ attribute: 'date-time' })
    dateTime;
    
    connectedCallback() {
        super.connectedCallback();

        if (this.showUserTimezone == "false") {
            this.dateTimeDisplay = this.dateTime;
            return false;
        }

        const locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);

        let startDateTimeWithTz = '',
            finishDateTimeWithTz = '';

        if (typeof this.dateFormat !== undefined) {
            startDateTimeWithTz = moment(this.startDate).django(this.dateFormat);
            if (startDateTimeWithTz !== moment(this.finishDate).django(this.dateFormat)) {
                finishDateTimeWithTz = moment(this.finishDate).django(this.dateFormat);
            }
        }
        if (typeof this.timeFormat !== undefined) {
            if (startDateTimeWithTz !== '') {
                startDateTimeWithTz += ' ';
            }
            startDateTimeWithTz += moment(this.startDate).django(this.timeFormat);
            // Don't repeat the end time if it's the same as the start on the same date.
            if (moment(this.startDate).format('YYYY-MM-DD') !== moment(this.finishDate).format('YYYY-MM-DD') ||
                moment(this.startDate).format('H:m') != moment(this.finishDate).format('H:m')) {
                finishDateTimeWithTz += ' ' + moment(this.finishDate).django(this.timeFormat);
            }
        }
        // Strip leading/trailing whitespace
        this.dateTimeDisplay = startDateTimeWithTz.replace(/^\s+|\s+$/g, '');
        if (finishDateTimeWithTz !== '') {
            this.dateTimeDisplay += ' - ' + finishDateTimeWithTz.replace(/^\s+|\s+$/g, '');
        }
        let tz = moment.tz.guess(true);
        this.dateTimeDisplay += ' ' + moment.tz(tz).zoneAbbr() + ' - ' + tz;
    }

    render() {
        return html`${this.dateTimeDisplay}`

    }
}

export { DjWhenWidget }


import { customElement, property } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

import { focusTrap } from '../../utils/focus-trap';


const EVENTS = {
    ITEM_ADDED: 'dj-filterable-item:added',
    ITEM_REMOVED: 'dj-filterable-item:removed',
    ITEM_HIDDEN: 'dj-filterable-item:hidden',
    ITEM_SHOWN: 'dj-filterable-item:shown',
}

const CLASSES = {
    FILTER_TAG_ACTIVE: 'dj-filter-tag--active',
}

const SELECTORS = {
    FILTER_BTN:'.dj-server-filters-btn',
    FILTER_MENU_OPEN:'.dj-filter-menu-open',
    FILTER_MENU_CLOSE:'.dj-filter-menu-close',
    FILTER_MENU_CLOSE_MOBILE:'.dj-agenda-filters__mobile-close',
    FILTER_MENU:'.dj-filter-categories',
    FILTER_TAG_BUTTONS: '.dj-filter-tag__toggle',
    FILTER_CLEAR_ALL_BUTTONS: '.dj-agenda-filters__clear-all',
}


const ATTRS = {
    FILTER_GROUP: 'dj-server-filter-group',
    DATA_FILTER_ID: 'data-filter-id',
}


@customElement('dj-server-filters')
class FilterableList extends DjElement {
    items = [];
    appliedFilters = [];
    @property({
        type: Boolean,
        attribute: 'show-filter',
    })
    showFilter=false;

    constructor() {
        super();

        this.menuFilterTrap = undefined;

        this.registerItem = this.registerItem.bind(this);
        this.unregisterItem = this.unregisterItem.bind(this);
        this.filter = this.filter.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this._applyFilters = this._applyFilters.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.hideGroup = this.hideGroup.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        this.$filterBtn = this.querySelector(`${SELECTORS.FILTER_BTN}`);
        this.$filterMenuOpen = this.querySelector(`${SELECTORS.FILTER_MENU_OPEN}`);
        this.$filterMenuClose = this.querySelector(`${SELECTORS.FILTER_MENU_CLOSE}`);
        this.$filterMenuCloseMobile = this.querySelector(`${SELECTORS.FILTER_MENU_CLOSE_MOBILE}`);
        this.$filterMenu = this.querySelector(`${SELECTORS.FILTER_MENU}`);
        this.$filterTagButtons = this.querySelectorAll(`${SELECTORS.FILTER_TAG_BUTTONS}`);
        this.$filterClearAllButtons = this.querySelectorAll(`${SELECTORS.FILTER_CLEAR_ALL_BUTTONS}`);

        this.menuVisible = false;
        this.$filterMenuOpen.hidden = false;
        this.$filterMenuClose.hidden = true;
        this.$filterMenu.hidden = true;

        this.addEventListener(EVENTS.ITEM_ADDED, this.registerItem);
        this.addEventListener(EVENTS.ITEM_REMOVED, this.unregisterItem);

        this.$filterBtn.addEventListener('click', this.toggleVisibility);
        this.$filterTagButtons.forEach((item) => item.addEventListener('click', this.filter));
        this.$filterClearAllButtons.forEach((item) => item.addEventListener('click', this.clearFilter));

        if(this.$filterMenuCloseMobile){
            this.$filterMenuCloseMobile.addEventListener('click', this.toggleVisibility);
        }

    }

    disconnectedCallback(){
        super.disconnectedCallback();
        this.removeEventListener(EVENTS.ITEM_ADDED, this.registerItem);
        this.removeEventListener(EVENTS.ITEM_REMOVED, this.unregisterItem);

        this.$filterBtn.removeEventListener('click', this.toggleVisibility);
        this.$filterTagButtons.forEach(
            (item) => item.removeEventListener('click', this.filter));
        this.$filterClearAllButtons.forEach(
            (item) => item.removeEventListener('click', this.clearFilter));

    }

    registerItem(event) {
        this.items.push(event.detail);
        event.stopPropagation();
    }

    unregisterItem(event) {
        this.items.pop(event.detail);
        event.stopPropagation();
    }

    toggleVisibility() {

        this.menuVisible = !this.menuVisible;
        this.$filterMenuOpen.hidden = this.menuVisible;
        this.$filterMenuClose.hidden = !this.menuVisible;
        this.$filterMenu.hidden = !this.menuVisible;
        if (this.menuVisible) {
            this.menuFilterTrap = focusTrap.create(this.$filterMenu);
        }
        else {
            this.menuFilterTrap = undefined;
        }
    }

    filter(event) {
        const filterTag = event.target.getAttribute(`${ATTRS.DATA_FILTER_ID}`) || event.target.closest(`[${ATTRS.DATA_FILTER_ID}]`).getAttribute(`${ATTRS.DATA_FILTER_ID}`);
        const filterChips = this.querySelectorAll(`[data-filter-id=${filterTag}]`);
        const filterIsApplied = this.appliedFilters.includes(filterTag);

        if(filterIsApplied) {
            this.appliedFilters = this.appliedFilters.filter(tag => tag !== filterTag);
            filterChips.forEach(el => el.parentElement.classList.remove(CLASSES.FILTER_TAG_ACTIVE));
        }
        else {
            this.appliedFilters.push(filterTag);
            filterChips.forEach(el => el.parentElement.classList.add(CLASSES.FILTER_TAG_ACTIVE));
        }
        this._applyFilters();
    }

    _applyFilters() {
        this.items.forEach((item) => {
            const itemTag = item.filterTags;
            const showItem = itemTag.some((element) => this.appliedFilters.includes(element.id));
            (showItem || this.appliedFilters.length === 0) ? item.show() : item.hide();
            const closestGroup = item.closest(`[${ATTRS.FILTER_GROUP}]`);
            if (closestGroup) {
                this.hideGroup(closestGroup);
            }
        });
    }

    hideGroup(group) {
        const $filterableItems = [...group.querySelectorAll('dj-server-filter-item')];
        group.hidden = $filterableItems.every((element) => element.hidden);
    }

    clearFilter() {
        this.appliedFilters = [];
        this._applyFilters();
        this.$filterTagButtons.forEach((button) => {
            button.parentElement.classList.remove(CLASSES.FILTER_TAG_ACTIVE);
        });
    }
}

@customElement('dj-server-filter-item')
class FilterableItem extends DjElement {
    @property({
        type: Object,
        attribute: 'filter-tags',
    })
    filterTags;

    constructor() {
        super();
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
    }

    connectedCallback() {
        this.dispatchEvent(
            new CustomEvent(EVENTS.ITEM_ADDED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    disconnectedCallback() {
        this.dispatchEvent(
            new CustomEvent(EVENTS.ITEM_REMOVED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    hide() {
        this.hidden = true;
        this.dispatchEvent(
            new CustomEvent(EVENTS.ITEM_HIDDEN, {
                bubbles: true,
                detail: this,
            })
        );
    }

    show() {
        this.hidden = false;
        this.dispatchEvent(
            new CustomEvent(EVENTS.ITEM_SHOWN, {
                bubbles: true,
                detail: this,
            })
        );
    }

}

export { FilterableList, FilterableItem, EVENTS};
import { STATUS_KEYS } from './session.constant';

export const demoContentStrings = {
    session_label_attended: '(attended)',
    session_label_unavailable: '(unavailable)',
    session_label_waitlist: '(waitlist)',
    session_message_unavailable: 'Sorry, you are unable to attend this session. Please contact the event admin for more information.',
    session_status_label_added: 'Added',
    session_status_label_attended: 'You\'ve already attended',
    session_status_label_add: 'Add',
    session_status_label_confirmed: 'Confirmed',
    session_status_label_join_waitlist: 'Add to waitlist',
    session_status_label_pending: 'Pending',
    session_status_label_remove: 'Remove',
    session_status_label_removed: 'Removed',
    session_status_label_unavailable: 'Unavailable',
    session_status_label_undo: 'Undo',
    session_status_label_waitlist: 'Waitlisted',
    session_ungrouped_sessions_title: 'Other Sessions',
};

export const demoGroups = [
    {
        group_order: 2,
        id: 1,
        max_sessions_allowed: 2,
        min_sessions_required: 0,
        sessions: [1, 2],
        title: "group 1",
    },
    {
        group_order: 1,
        id: 2,
        max_sessions_allowed: 2,
        min_sessions_required: 1,
        sessions: [3, 4],
        title: "group 2",
    }
]


export const demoEditGroups = [
    {
        group_order: 2,
        id: 1,
        max_sessions_allowed: 2,
        min_sessions_required: 0,
        sessions: [1, 2, 9],
        title: "group 1",
    },
    {
        group_order: 1,
        id: 2,
        max_sessions_allowed: 2,
        min_sessions_required: 1,
        sessions: [3, 4],
        title: "group 2",
    }
]

export const demoSessions = [{
    description: 'Some other more detailed blurb here about the session.',
    id: 1,
    delegate_status: STATUS_KEYS.ADDED,
    status: STATUS_KEYS.ADDED,
    title: 'Session 1',
    venue: 'A nice restaurant, Bristol, UK',
    session_order: 2,
}, {
    date: 'Feb. 5, 2018 14:20 - 19:20',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 2,
    delegate_status: STATUS_KEYS.WAITLIST,
    status: STATUS_KEYS.WAITLIST,
    title: 'Session 2',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    session_order: 1,
}, {
    description: 'Some other more detailed blurb here about the session.',
    id: 3,
    delegate_status: STATUS_KEYS.ADDED,
    status: STATUS_KEYS.ADDED,
    title: 'Title:Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit_sed_do_eiusmod_tempor_incididunt_ut_labore_et_dolore_magna_aliqua',
    session_order: 4,
}, {
    date: 'Feb. 5, 2018 14:20 - 19:20',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 4,
    delegate_status: STATUS_KEYS.REJECTED,
    status: STATUS_KEYS.REJECTED,
    title: 'Session 4',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    session_order: 5,
}, {
    date: 'Feb. 1, 2018 15:00 - 16:00',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 5,
    delegate_status: STATUS_KEYS.ATTENDED,
    status: STATUS_KEYS.ATTENDED,
    title: 'Session 5',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    session_order: 3,
},
{
    date: 'Feb. 1, 2018 15:00 - 16:00',
    description: 'This session is pending',
    id: 6,
    delegate_status: STATUS_KEYS.PENDING,
    status: STATUS_KEYS.PENDING,
    title: 'Session 6',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    session_order: 6,
}];

export const demoSessionforTimeConversion = {
description: 'Some other more detailed blurb here about the session.',
id: 9,
delegate_status: STATUS_KEYS.ADDED,
status: STATUS_KEYS.ADDED,
title: 'Session 1',
venue: 'A nice restaurant, Bristol, UK',
convert_datetime: true,
start_utc: "2022-06-15T07:30:00+00:00",
end_utc: "2022-06-27T12:00:00+00:00",
date_format: "N j, Y",
time_format: "G:i",
}

export const demoEditableSessions = [{
    date: 'Feb. 5, 2018 14:20 - Feb. 7, 2018 19:20',
    description: 'Some other more detailed blurb here about the session.',
    id: 1,
    status: STATUS_KEYS.AVAILABLE,
    title: 'Session 1',
    venue: 'A nice restaurant, Bristol, UK',
}, {
    date: 'Feb. 5, 2018 14:20 - 19:20',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 2,
    status: STATUS_KEYS.ADDED,
    title: 'Session 2',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
}, {
    description: 'Some other more detailed blurb here about the session.',
    id: 3,
    status: STATUS_KEYS.PENDING,
    title: 'Session 3',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
}, {
    date: 'Feb. 5, 2018 14:20 - 19:20',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 4,
    status: STATUS_KEYS.ATTENDED,
    title: 'Session 4',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
}, {
    id: 5,
    status: STATUS_KEYS.WAITLIST,
    title: 'Session 5',
}, {
    date: 'Feb. 5, 2018 14:20 - 19:20',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 6,
    status: STATUS_KEYS.JOIN_WAITLIST,
    title: 'Session 6',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
}, {
    date: 'Feb. 5, 2018 14:20 - 19:20',
    description: 'Some blurb here about the session. Give a preview of what they can expect here.',
    id: 7,
    status: STATUS_KEYS.REJECTED,
    title: 'Session 7',
    venue: 'Meeting Room ABC, Your Office, London W1 4TP',
}];

export const testEditableSessions = [
    {
        delegate_status: STATUS_KEYS.CANCELLED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 1,
        readable_datetime_range: "June 15, 2022 0:00 - June 29, 2022 0:00",
        session_order: 1,
        start: "2022-06-15T00:00:00BST",
        title: "Session 1",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.CONFIRMED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 2,
        readable_datetime_range: "September 15, 2022 0:00 - September 29, 2022 0:00",
        session_order: 2,
        start: "2022-09-15T00:00:00BST",
        title: "Session 2",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.CONFIRMED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 3,
        readable_datetime_range: "October 15, 2022 0:00 - October 29, 2022 0:00",
        session_order: 3,
        start: "2022-10-15T00:00:00BST",
        title: "Session 3",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.CONFIRMED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 4,
        readable_datetime_range: "November 15, 2022 0:00 - November 29, 2022 0:00",
        session_order: 4,
        start: "2022-11-15T00:00:00BST",
        title: "Session 4",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.ATTENDED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 5,
        readable_datetime_range: "December 15, 2022 0:00 - December 29, 2022 0:00",
        session_order: 5,
        start: "2022-12-15T00:00:00BST",
        title: "Session 5",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.REJECTED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 6,
        readable_datetime_range: "June 15, 2022 0:00 - June 29, 2022 0:00",
        session_order: 6,
        start: "2022-06-15T00:00:00BST",
        title: "Session 6",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.CANCELLED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 7,
        readable_datetime_range: "July 15, 2022 0:00 - July 29, 2022 0:00",
        session_order: 7,
        start: "2022-07-15T00:00:00BST",
        title: "Session 7",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.CANCELLED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 8,
        readable_datetime_range: "August 15, 2022 0:00 - August 29, 2022 0:00",
        session_order: 8,
        start: "2022-08-15T00:00:00BST",
        title: "Session 8",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
    {
        delegate_status: STATUS_KEYS.CANCELLED,
        description: "Some blurb here about the session. Give a preview of what they can expect here.",
        id: 9,
        readable_datetime_range: "September 15, 2022 0:00 - September 29, 2022 0:00",
        session_order: 9,
        start: "2022-09-15T00:00:00BST",
        title: "Session 9",
        venue: 'Meeting Room ABC, Your Office, London W1 4TP',
    },
]

export const demoEventConfig = {
    min_sessions: 3,
    max_sessions: 5,
    is_series: false,
    groups_min_max: true,
    validate_attended_session_count: false,
    allow_clashes: false,
}



export const demoStatuses = demoSessions.reduce((acc, cur)=> {
    if(cur.id === 6){
        acc[cur.id] = 'confirmed';
    } else if (cur.id === 1) {
        acc[cur.id] = 'waitlist';
    } else {
        acc[cur.id] = cur.delegate_status;
    }
    return acc;
},{})


export const demoEditContent = {
    session_group_max_error_text: "You've selected too many sessions for this group.",
    registration_next_button_text: "Next",
    session_group_min_error_text: "You haven't selected enough sessions for this group.",
    session_min_max_error_text: "Please choose the correct number of sessions.",
    session_group_max_allowed_text: "Max",
    registration_edit_schedule_footer_title: "Total sessions added:",
    session_same_start_time_error_text: "A selection has the same start date and time. Please update your selections before continuing.",
    session_max_allowed_text: "Max",
    session_group_min_required_text: "Min",
    registration_edit_schedule_footer_cancel_link_text: "Cancel",
    session_min_required_text: "Min",
    session_group_min_max_error_text: "Please choose the correct number of sessions for the following session groups: "
}

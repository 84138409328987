import { customElement } from 'lit/decorators.js';

import { DjCheckboxRadioBase } from './checkbox-and-radio.lit';

@customElement('dj-checkbox')
class DjCheckbox extends DjCheckboxRadioBase {

    constructor() {
        super();

        this.CLASSES = {
            BASE: 'dj-checkbox',
            BOX_CHECKED: 'dj-checkbox__box--checked',
            DISABLED: 'dj-checkbox--disabled',
            FOCUSED: 'dj-checkbox--focused',
        };
    }
}

export { DjCheckbox };

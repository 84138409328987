import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';
import { getEventBus } from '../../shared-with-old-bundles/eventBus.js';

const NATIVE_INPUT_TYPES = [
    'input',
    'select',
    'textarea',
];

const INPUT_READY = 'dj-input:ready';

/**
 * Conditions monitor the form changes and hide/show individual field as defined in the
 * conditions dictionary.
 *
 */
@customElement('dj-conditions')
class Conditions extends DjElement {
    @property({
        type: Object,
    })
    conditions = {};

    $form;
    $inputElements = {};

    eventBus = getEventBus();

    constructor() {
        super();
        this.denormalize = this.denormalize.bind(this);
        this.toggleInput = this.toggleInput.bind(this);
        this.findFieldWrappers = this.findFieldWrappers.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.initialEvaluateConditions = this.initialEvaluateConditions.bind(this);
        this.evaluateConditions = this.evaluateConditions.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.denormalize(this.conditions);
        this.$form = this.getElementsByTagName('form')[0];
        this.$form.addEventListener('change', this.evaluateConditions);
        // FIXME: The fields don't have a dj-conditional-field passed yet (from <bootstrap>), we need to somehow wait
        // for angular to be done, this will go away once we convert dj-input to a web component, and we can then
        // dispatch a form change event (or something else) to trigger condition evaluation
        // FIXME2: it looks like all the inputs are constructed and emitting first, so we need a direct call
        // here for the initial eval. Is it a guarantee or an accident?
        this.eventBus.on(INPUT_READY, this.initialEvaluateConditions);
        this.initialEvaluateConditions();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        // this is taken off once all fields are ready inside the handler itself, so probably a noop here
        this.eventBus.off(INPUT_READY, this.initialEvaluateConditions);
        this.$form.removeEventListener('change', this.evaluateConditions);
    }
    getParentElement = (condition) =>{
        return condition.type == 'static' ? condition.key  : condition.type + '__' + condition.key;
    };
    denormalize = (conditions) => {
        if (!conditions) {
            return;
        }
        // conditions are of the form:
        //
        //  rsvp__1: [{type: rsvp, key: 2, vlue: [1, 2, 3]}]
        //
        // field rsvp__1 will be hidden unless, field rsvp__2 has a value of 1 or 2 or 3
        this.$inputElements = {};
        // denormalize modifies the conditions dict in place - so we may run into trouble with the
        // `conditions` property it starts with
        const _getDependentConditions = (condition) => {
            const extra = [];
            const dependsOn = this.getParentElement(condition);
            this.$inputElements[dependsOn] = false; // map all inputs used by conditions
            if (conditions[dependsOn]) {
                extra.push(... conditions[dependsOn]);
                // forEach() will only iterate over items in the array at the time of the call.
                extra.forEach(function (additionalCondition) {
                    extra.push(... _getDependentConditions(additionalCondition));
                });
            }
            return extra;
        }

        for (let [fieldName, conditionList] of Object.entries(conditions)) {
            const extra = [];
            conditionList.forEach(function (condition) {
                extra.push(... _getDependentConditions(condition));
            });
            conditionList.push(... extra);
            // Only fields that have conditions should end up in the $inputElement
            if (conditionList.length) {
                this.$inputElements[fieldName] = false; // map all inputs used by conditions
            }
        };

        return conditions;
    };

    findFieldWrappers = (name) => {
        return this.$form.querySelectorAll(`[dj-conditional-field="${name}"]`);
    };

    collectInputsFromNodeList = (nl, name, suffix='') => {
        // We need to qualify the following element find operation
        // by tag as some of our custom wrapper directives use the
        // name attribute now (they used to us dj-name but this was
        //  changed to just "name" in a previous commit that was
        //  merged and caused this conditional checking to break.
        //  See #5116.
        let selector = NATIVE_INPUT_TYPES.map(tagName =>
            `${tagName}[name="${name}${suffix}"]`
        ).join(',');

        let elements = [];
        nl.forEach((node) => {
            elements.push(...node.querySelectorAll(selector));
        });
        return elements;
    };

    getFieldValue = (name) => {
        const values = [];
        const fields = this.findFieldWrappers(name);

        let elements = this.collectInputsFromNodeList(fields, name);

        if (elements.length < 1) {
            // Handle fields with "other" option, since they have
            // _0 appended by django to the field name

            elements = this.collectInputsFromNodeList(fields, name, '_0');
        }

        // After above checks we have our field (either with _0 suffix or without)
        if (elements.length > 1) {
            // Checkboxes
            elements.forEach((element) => {
                if (element.checked) {
                    values.push(element.value);
                }
            });
        } else if (elements.length === 1) {
            if (elements[0].type && elements[0].type === 'select-multiple') {
                // Multi-select don't have a x-browser way of
                // getting all values correctly, need to iterate
                // over options
                const children = Array.from(elements[0].children);
                children.forEach((option) => {
                    if (option.selected) {
                        values.push(option.value);
                    }
                });
            } else {
                // All other fields should be in this case
                values.push(elements[0].value);
            }
        }
        return values;
    };

    initialEvaluateConditions = () => {
       this.evaluateConditions();
    };

    evaluateConditions = (event) => {
        /*
         * Checks a single condition, if the specified field has a value within the expected values
         */
        const _checkCondition = (condition) => {
            // Not sure where those conditions are set?
            if (condition.type === 'session' || condition.type === 'ticket') {
                return true;
            }
            const values = this.getFieldValue(this.getParentElement(condition));

            return values.reduce(function (validOption, value) {
                var parsedValue = parseInt(value, 10);

                // FIXME: do we need utils.isNan?
                if (isNaN(parsedValue)) {
                    parsedValue = value;
                }
                return validOption || condition.value.indexOf(parsedValue) > -1;
            }, false);
        };

        let fields, conditionsMet;

        for (let [fieldName, conditionList] of Object.entries(this.conditions)) {

            fields = this.findFieldWrappers(fieldName);
            if (fields) {
                conditionsMet = conditionList.reduce(function(allMet, condition) {
                    return allMet && _checkCondition(condition);
                }, true);

                this.toggleInput(fields, conditionsMet);
            }
        };
    };

    toggleInput = (fields, show) => {
        fields.forEach((elem) => {
            elem.classList.toggle('dj-h-hidden', !show);
            elem.querySelectorAll('input,select').forEach((input) => { input.disabled = !show; });
        });
    };
}

import { customElement, property } from 'lit/decorators.js';

import { defer } from '../../utils/defer';
import { DjElement } from '../base-component/base-component.lit';
import { EVENT_EXPIRED } from '../countdown/countdown.lit';
import { BRAINTREE_FIELD_EVENTS } from './braintree-field.lit';
import { getEventBus } from '../../shared-with-old-bundles/eventBus';

export const BRAINTREE_INPUT_STYLES = {
    'input': {
        'font-size': '14px',
        'line-height': '24px',
    },
    'input.invalid': {
        'color': '#ea4335',
    },
    'input.valid': {
        'color': '#414141',
    },
  };

const checkBtSDKAvailability = function () {
    if (!window['braintree'] || !window['braintree']['hostedFields']) {
        throw new Error('Braintree client not loaded');
    }
};

@customElement('dj-bt-form')
export class DjBtForm extends DjElement {

    @property({
        attribute: 'payments-token',
        type: String,
    })
    PAYMENTS_TOKEN;

    @property({
        attribute: 'hosted-field-error-messages',
        type: Object,
    })
    hostedFieldErrorMessages = {
        default: 'Form failed to submit.'
    };

    fieldConfig = {};

    braintreeFieldConfig = {};

    formEl = null;

    nonceFieldEl = null;

    submitButtonEl = null;

    formErrorsEl = null;

    hostedFieldErrorsEl = null;

    // This will be set to true if the timer expires while the
    // user is filling out the form.
    paymentDisabled = false;

    // Flag to control disabling of submit button once it's been
    // pressed to prevent double form submission.
    submitting = false;

    // State used along with ng-enabled to enable the submit button
    // once the Braintree API setup work has completed.
    initialized = false;

    client = defer();

    hostedFields = defer();

    eventBus = getEventBus();

    constructor() {
        super();

        checkBtSDKAvailability();

        this.bindFieldEvents = this.bindFieldEvents.bind(this);
        this.disablePayments = this.disablePayments.bind(this);
        this.onFieldEvent = this.onFieldEvent.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitError = this.onSubmitError.bind(this);
        this.registerField = this.registerField.bind(this);
        this.updateSubmitButton = this.updateSubmitButton.bind(this);
        this.updateValidity = this.updateValidity.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        this.formEl = this.querySelector('form');
        this.nonceFieldEl = this.querySelector('[dj-bt-nonce-field]');
        this.submitButtonEl = this.querySelector('input[type=submit]');
        this.formErrorsEl = this.querySelector('[dj-bt-form-errors]');
        this.hostedFieldErrorsEl = this.querySelector('[dj-bt-form-hosted-field-errors]');
        this.btFieldEls = [...this.querySelectorAll('dj-bt-field')];
        this.btFieldEls.forEach(this.registerField);

        // Setup a listener for the dj-countdown:timer-expired event
        // This is emitted from newly migrated components and uses an
        // event bus
        this.eventBus.on(EVENT_EXPIRED, this.disablePayments);

        this.formEl.addEventListener('submit', this.onSubmit);

        this.hostedFields.promise.then(() => {
            this.initialized = true;
            this.updateSubmitButton();
        });

        // TODO: Dynamically load braintree API via a service instead of
        // assuming it's already available in the page.
        braintree.client.create({
            authorization: this.PAYMENTS_TOKEN,
        }, (clientErr, clientInstance) => {
            if (clientErr) {
                this.client.reject(clientErr);
            } else {
                this.client.resolve(clientInstance);
            }
        });

        this.client.promise.then((clientInstance) => {
            if (!Object.keys(this.braintreeFieldConfig).length) {
                return;
            }

            // TODO: Dynamically load braintree API via a service instead of
            // assuming it's already available in the page.
            braintree.hostedFields.create({
                client: clientInstance,
                styles: BRAINTREE_INPUT_STYLES,
                fields: this.braintreeFieldConfig,
            }, (hostedFieldsErr, hostedFieldsInstance) => {
                if (hostedFieldsErr) {
                    // Handle error in Hosted Fields creation
                    console.error('HOSTED FIELDS ERR: ' + hostedFieldsErr);
                    this.hostedFields.reject(hostedFieldsErr);
                    return;
                }

                this.bindFieldEvents(hostedFieldsInstance);
                this.hostedFields.resolve(hostedFieldsInstance);
            });
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.eventBus.off(EVENT_EXPIRED, this.disablePayments);

        this.formEl.removeEventListener('submit', this.onSubmit);
    }

    bindFieldEvents(hostedFieldsInstance) {
        console.debug('[dj-bt-forms:bindFieldEvents]');
        Object.values(BRAINTREE_FIELD_EVENTS).forEach((eventName) => {
            hostedFieldsInstance.on(eventName, (eventData) => {
                this.onFieldEvent(eventName, eventData);
            });
        });
    }

    disablePayments() {
        this.paymentDisabled = true;
        this.updateSubmitButton();
    }

    async onFieldEvent(eventName, eventData) {
        const fieldName = eventData.emittedBy;
        console.debug(`[dj-bt-forms] ${fieldName}:${eventName}`);

        const hostedFieldsInstance = await this.hostedFields.promise;
        const state = hostedFieldsInstance.getState();
        const fieldState = state.fields[fieldName];

        // Forward the event to the controller that corresponds to
        // the field that triggered the event.
        const config = this.fieldConfig[fieldName];
        config.controller.handleEvent(eventName, eventData, fieldState);
    }

    async onSubmit(event) {
        event.preventDefault();
        console.log('[dj-bt-forms:onSubmit]', event);

        if (this.submitting) {
            return;
        }

        this.submitting = true;
        this.updateSubmitButton()

        if (!this.nonceFieldEl) {
            console.error('[dj-bt-form:onSubmit] Cannot submit form. Nonce field not provided in template.');
            return;
        }

        this.formErrorsEl.hidden = true;

        // Mark the fields as submitted
        Object.values(this.fieldConfig).forEach((field) => {
            field.controller.formSubmitted = true;
        });

        this.updateValidity();

        const hostedFieldsInstance = await this.hostedFields.promise;
        hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
            if (tokenizeErr) {
                console.error('[dj-bt-form:onSubmit] Tokenization Error: ', tokenizeErr);
                this.submitting = false;
                this.updateSubmitButton();
                this.onSubmitError(tokenizeErr);
                return;
            }

            this.nonceFieldEl.value = payload.nonce;
            console.info('Submitting form...');
            this.formEl.submit();
        });
    }

    onSubmitError(error) {
        this.hostedFieldErrorsEl.parentNode.hidden = false;
        this.hostedFieldErrorsEl.textContent = this.hostedFieldErrorMessages[error.code] || this.hostedFieldErrorMessages.default;

        switch (error.code) {
            case 'HOSTED_FIELDS_FIELDS_EMPTY':
                console.error('All fields are empty! Please fill out the form.');
                break;
            case 'HOSTED_FIELDS_FIELDS_INVALID':
                console.error('Some fields are invalid:', error.details.invalidFieldKeys);
                break;
            case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                console.error('Tokenization failed server side. Is the card valid?');
                break;
            case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                console.error('Network error occurred when tokenizing.');
                break;
            default:
                console.error('Something bad happened!', error);
        }
    }

    async registerField(el) {
        await el.connectedCallback;
        const name = el.fieldName;
        console.debug('[dj-bt-forms:registerField]', name);
        this.braintreeFieldConfig[name] = {
            selector: '#bt-field-' + name,
        };
        this.fieldConfig[name] = { controller: el };
    }

    updateSubmitButton() {
        this.submitButtonEl.disabled = !this.initialized || this.paymentDisabled || this.submitting;
    }

    updateValidity() {
        console.debug('[dj-bt-forms:updateValidity]');
        Object.values(this.fieldConfig).forEach((field) => {
            field.controller.updateValidity();
        });
    }
}

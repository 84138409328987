import { html } from 'lit';

import moment from 'moment';

import { customElement, property, state } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';


const COLORS = {
    DAYS: {
        CIRCLE: '#D2E3FC',
        DOT: '#4285F4',
    },
    HOURS: {
        CIRCLE: '#FAD2CF',
        DOT: '#EA4335',
    },
    MINUTES: {
        CIRCLE: '#FEEFC3',
        DOT: '#FBBC04',
    },
    SECONDS: {
        CIRCLE: '#CEEAD6',
        DOT: '#1E8E3E',
    },
}

const MS_PER_SECOND = 1000
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;
const DAYS_PER_YEAR = 365;

@customElement('dj-event-countdown')
class EventCountdown extends DjElement {
    @property({
        type: String,
        converter: (value) => {
            return moment.utc(value);
        },
    })
    expiry;

    @state()
    days;

    @state()
    hours;

    @state()
    minutes;

    @state()
    seconds;

    @state()
    status;

    connectedCallback() {
        super.connectedCallback();

        this.eventDate = new Date(this.expiry).getTime();
        // SVG Radius
        this.rSmall = 28;
        this.rLarge = 56;
        // SVG Circumference
        this.cSmall = this.rSmall * 2 * Math.PI;
        this.cLarge = this.rLarge * 2 * Math.PI;
        // Making stroke dashes as big as a whole circle
        this.strokeDasharraySmall = `${this.cSmall} ${this.cSmall}`;
        this.strokeDasharrayLarge = `${this.cLarge} ${this.cLarge}`;

        this.refresh = this.refresh.bind(this);
        this.renderCircle = this.renderCircle.bind(this);
        this.renderCircleXL = this.renderCircleXL.bind(this);

        this.refresh();
        this.interval = window.setInterval(this.refresh, 1000);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.clearInterval(this.interval);
    }

    refresh() {
        const now = new Date().getTime();
        const distance = this.eventDate - now;

        if (distance < 0) {
            window.clearInterval(this.interval);
            this.days = 0
            this.hours = 0
            this.minutes = 0
            this.seconds = 0
            this.status = 'expired';
            return;
        }

        const secondsToEvent = distance / MS_PER_SECOND;

        this.days = Math.floor((secondsToEvent / (SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY)));
        this.hours = Math.floor(((secondsToEvent / (SECONDS_PER_MINUTE * MINUTES_PER_HOUR)) % HOURS_PER_DAY));
        this.minutes = Math.floor(((secondsToEvent / SECONDS_PER_MINUTE) % MINUTES_PER_HOUR));
        this.seconds = Math.floor(secondsToEvent % SECONDS_PER_MINUTE);
    }

    renderCircle({ value, label, circleColor, dotColor, strokeDashoffset, dotTransform }) {
        return html`
            <div class="time-wrap">
                <div class='circle-wrap'>
                    <div><h3>${value}</h3><h3>${label}</h3></div>
                    <svg class="progress-ring" height="60" width="60">
                        <circle class="gray-ring" stroke-width="6" stroke-linecap="round" stroke="#F8F9FA" fill="transparent" r="${this.rSmall}" cx="30" cy="30" />
                        <circle class="progress-ring__circle" stroke-width="6" stroke-linecap="round" stroke="${circleColor}" fill="transparent" r="${this.rSmall}" cx="30" cy="30" style="stroke-dashoffset: ${strokeDashoffset}; stroke-dasharray: ${this.strokeDasharraySmall}" />
                        <circle class="progress-dot" fill="${dotColor}" r="3" cx="2" cy="30" style="transform: ${dotTransform}" />
                    </svg>
                </div>
            </div>
        `;
    }

    renderCircleXL({ value, label, circleColor, dotColor, strokeDashoffset, dotTransform }) {
        return html`
            <div class="time-wrap">
                <div class='circle-wrap'>
                    <div><h3>${value}</h3><h3>${label}</h3></div>
                    <svg class="progress-ring" height="120" width="120">
                        <circle class="gray-ring" stroke-width="6" stroke-linecap="round" stroke="#F8F9FA" fill="transparent" r="${this.rLarge}" cx="60" cy="60" />
                        <circle class="progress-ring__circle" stroke-width="6" stroke-linecap="round" stroke="${circleColor}" fill="transparent" r="${this.rLarge}" cx="60" cy="60" style="stroke-dashoffset: ${strokeDashoffset}; stroke-dasharray: ${this.strokeDasharrayLarge}" />
                        <circle class="progress2-dot" fill="${dotColor}" r="4" cx="4" cy="60" style="transform: ${dotTransform}" />
                    </svg>
                </div>
            </div>
        `;
    }

    render() {
        const daysFraction = this.days / DAYS_PER_YEAR;
        const hoursFraction = this.hours / HOURS_PER_DAY;
        const minutesFraction = this.minutes / MINUTES_PER_HOUR;
        const secondsFraction = this.seconds  / SECONDS_PER_MINUTE;

        const dotTransformDays = `rotate(${daysFraction * 360 + 90}deg)`;
        const dotTransformHours = `rotate(${hoursFraction * 360 + 90}deg)`;
        const dotTransformMinutes = `rotate(${minutesFraction * 360 + 90}deg)`;
        const dotTransformSeconds = `rotate(${secondsFraction * 360 + 90}deg)`;

        const daysSmall = {
            circleColor: COLORS.DAYS.CIRCLE,
            dotColor: COLORS.DAYS.DOT,
            dotTransform: dotTransformDays,
            label: 'D',
            strokeDashoffset: this.cSmall - daysFraction * this.cSmall,
            value: this.days,
        };
        const hoursSmall = {
            // Shared
            circleColor: COLORS.HOURS.CIRCLE,
            dotColor: COLORS.HOURS.DOT,
            dotTransform: dotTransformHours,
            value: this.hours,
            // Unique
            label: 'H',
            strokeDashoffset: this.cSmall - hoursFraction * this.cSmall,
        };
        const minutesSmall = {
            // Shared
            circleColor: COLORS.MINUTES.CIRCLE,
            dotColor: COLORS.MINUTES.DOT,
            dotTransform: dotTransformMinutes,
            value: this.minutes,
            // Unique
            label: 'M',
            strokeDashoffset: this.cSmall - minutesFraction * this.cSmall,
        };
        const secondsSmall = {
            // Shared
            circleColor: COLORS.SECONDS.CIRCLE,
            dotColor: COLORS.SECONDS.DOT,
            dotTransform: dotTransformSeconds,
            value: this.seconds,
            // Unique
            label: 'S',
            strokeDashoffset: this.cSmall - (secondsFraction || 1) * this.cSmall, // show full circle for 0 seconds
        };

        const daysLarge = {
            ...daysSmall,
            label: 'Days',
            strokeDashoffset: this.cLarge - daysFraction * this.cLarge,
        };
        const hoursLarge = {
            ...hoursSmall,
            label: 'Hours',
            strokeDashoffset: this.cLarge - hoursFraction * this.cLarge,
        };
        const minutesLarge = {
            ...minutesSmall,
            label: 'Minutes',
            strokeDashoffset: this.cLarge - minutesFraction * this.cLarge,
        };
        const secondsLarge = {
            ...secondsSmall,
            label: 'Seconds',
            strokeDashoffset: this.cLarge - (secondsFraction || 1) * this.cLarge, // show full circle for 0 seconds
        };

        return html`
            <section id="cc-sm" class="dj-section--bottom-padding-small dj-clock-small">
                <div class="flex-center">
                    ${this.renderCircle(daysSmall)}
                    ${this.renderCircle(hoursSmall)}
                    ${this.renderCircle(minutesSmall)}
                    ${this.renderCircle(secondsSmall)}
                </div>
            </section>
            <section  class="dj-section--bottom-padding-small dj-clock-large ">
                <div class="flex-center">
                <div class="col-sm-full">
                    ${this.renderCircleXL(daysLarge)}
                    ${this.renderCircleXL(hoursLarge)}
                </div>
                <div class="col-sm-full">
                    ${this.renderCircleXL(minutesLarge)}
                    ${this.renderCircleXL(secondsLarge)}
                </div>
                </div>
            </section>
        `;
    }
}

export { EventCountdown };

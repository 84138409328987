/**
 * Maps the local constants (keys) to the values we expect from the Backend
 * @enum {string}
 */
export const STATUS_KEYS = {
    ADDED: 'added',
    ATTENDED: 'attended',
    AVAILABLE: 'not-registered',
    CANCELLED: 'cancelled',
    CONFIRMED: 'confirmed',
    JOIN_WAITLIST: 'join-waitlist',
    PENDING: 'pending',
    REJECTED: 'rejected',
    REMOVED: 'removed',
    WAITLIST: 'waitlist',
};

/**
 * Maps the local constants to sensible status constants.
 *  This mapping can be thought of as Status -> Status/Action.
 *  The values are used directly in css classNames and SVG names.
 *  They can also be used to access the STATUS_LABELS below.
 * @enum {string}
 */
export const STATUSES = {
    ADDED: 'ADDED',
    ADD: 'ADD',
    ATTENDED: 'ATTENDED',
    AVAILABLE: 'ADD',
    CANCELLED: 'ADD',
    CONFIRMED: 'CONFIRMED',
    JOIN_WAITLIST: 'JOIN_WAITLIST',
    PENDING: 'PENDING',
    REJECTED: 'UNAVAILABLE',
    REMOVE: 'REMOVE',
    REMOVED: 'REMOVED',
    UNDO: 'UNDO',
    UNAVAILABLE:'UNAVAILABLE',
    WAITLIST: 'WAITLIST',
};

import { customElement, property, state } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';
import { resize } from '../../utils/resize';

@customElement('dj-map')
class Map extends DjElement {

    @property({
        type: String,
        attribute: 'dj-map-latitude',
    })
    djMapLatitude;

    @property({
        type: String,
        attribute: 'dj-map-longitude',
    })
    djMapLongitude;

    @property({
        type: String,
        attribute: 'dj-map-marker-latitude'
    })
    djMapMarkerLatitude;

    @property({
        type: String,
        attribute: 'dj-map-marker-longitude'
    })
    djMapMarkerLongitude;

    @property({
        type: String,
        attribute: 'dj-map-zoom'
    })
    djMapZoom;

    @property({
        type: String,
        attribute: 'dj-map-type'
    })
    djMapType;

    constructor() {
        super();

        this.map = null;
        this.mapOptions = {};

        this.setUp = this.setUp.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        this.mapOptions = {
            type: this.djMapType,
            draggable: false,
            scrollwheel: false,
            scaleControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            panControl: true,
            zoom: parseInt(this.djMapZoom, 10),
        }

        // Reinitalize the map on window resize.
        resize(this.setUp);
    }

    firstUpdated(){
        // google maps can directly modify the current element,
        // it's safer to wrap it in a div
        this.mapWrapper = document.createElement('div');
        this.mapWrapper.style.height = '100%';
        this.appendChild(this.mapWrapper);

        this.setUp();
    }

    setUp(newSize, oldSize) {
        if (newSize && oldSize && newSize.width === oldSize.width) {
            return false;
        }

        this.mapOptions.center = {
            lat: parseFloat(this.djMapLatitude),
            lng: parseFloat(this.djMapLongitude),
        };

        this.map = new google.maps.Map(this.mapWrapper, this.mapOptions);

        if (this.djMapMarkerLatitude && this.djMapMarkerLongitude) {
            const markerPosition = {
                lat: parseFloat(this.djMapMarkerLatitude),
                lng: parseFloat(this.djMapMarkerLongitude),
            };
            new google.maps.Marker({
                draggable: false,
                position: markerPosition,
                map: this.map,
            });
        }
    }
}

export { Map };

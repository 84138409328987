import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';
import { ATTRS } from '../input/input.lit';

// @TODO: not currently in use on the microsite, but it would require some
//        extra thought as to how this would handle custom error checks
const ERROR_CHECKS =  {
	email: (field) => field.type === 'email' && field.validity.typeMismatch,
	'group-required-all': (_, fields) => !fields.every(field => field.checked && field.validity.valid),
	'group-required-some': (_, fields) => !fields.some(field => field.checked && field.validity.valid),
	max: (field) => field.validity.rangeOverflow,
	maxlength: (field) => field.validity.tooLong,
	min: (field) => field.validity.rangeUnderflow,
	minlength: (field) => field.validity.tooShort,
	pattern: (field) => field.validity.patternMismatch,
	required: (field) => field.validity.valueMissing,
	server: () => true, // Always shows as the backup if there has been a server generated message
	url: (field) => field.type === 'url' && field.validity.typeMismatch,
};

@customElement('dj-messages')
class DjMessages extends DjElement {
	@property({
		type: String,
		attribute: 'dj-messages-field-name',
	})
	fieldName;

	@state()
	messages;

	constructor() {
		super();

		this.fields;
		this.parentForm;

		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.onInput = this.onInput.bind(this);
		this.validate = this.validate.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();

		this.messages = [...this.querySelectorAll('[dj-message]')].map((messageNode) => {
			messageNode.removeAttribute('style');
			const clone = messageNode.cloneNode(true);
			const key = messageNode.getAttribute('dj-message');
			this.removeChild(messageNode);
			return {
				key,
				node: clone,
				show: false,
			};
		}, {});

		// Multiple fields handles radio/checkbox with the same name. Validation state will be shared.
		this.fields = [...document.querySelectorAll(`[name^="${this.fieldName}"]`)];
		this.fields.forEach((field) => {
			field.addEventListener('input', this.onInput, {passive: true});
			field.addEventListener('change', this.onInput, {passive: true});
		});
		this.validate(this.fields[0]);

		this.parentForm = this.closest(`form[${ATTRS.VALIDATE_ON_SUBMIT}]`);
		if (this.parentForm) {
			this.parentForm.addEventListener('submit', this.onFormSubmit, {passive: true});
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();

		this.fields.forEach((field) => {
			field.removeEventListener('input', this.onInput, {passive: true});
			field.removeEventListener('change', this.onInput, {passive: true});
		});
		if (this.parentForm) {
			this.parentForm.removeEventListener('submit', this.onFormSubmit, {passive: true});
		}
	}

	onFormSubmit() {
		this.fields.forEach(this.validate);
	}

	onInput(event) {
		this.validate(event.currentTarget);
	}

	validate(field) {
		this.messages = this.messages.map((message) => {
			const errorCheck = ERROR_CHECKS[message.key];
			if (!errorCheck) {
				console.warn(`No validation method found for '${message.key}'`);
				return message;
			};
			return {
				...message,
				show: errorCheck(field, this.fields),
			};
		});
	}

	render() {
		const message = this.messages.find((m) => m.show);
		if (!message) {
			return html``;
		}
		return html`${message.node}`;
	}
}

export { DjMessages };

import { html } from 'lit';
import { customElement, property,} from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { DjElement } from '../base-component/base-component.lit';
import { STATUS_KEYS, } from './session.constant';
import { ON_SESSION_CLICK } from './session.lit';

const SESSION_UPDATE_INTERVAL = 10000;


/**
 *
 * Sessions
 *
 */
@customElement('dj-sessions')
class Sessions extends DjElement {

    static properties = {
        footerClasses: {},
    };

    @property({ attribute: 'content-strings', type: Object, })
    contentStrings;

    @property({ attribute: 'sessions', type: Object, })
    sessions;

    @property({ attribute: 'session-groups', type: Object})
    sessionGroups;

    @property({ attribute: 'dj-session-api-endpoint', })
    djSessionApiEndpoint;

    @property({ attribute: 'edit-content', type: Object, })
    editContent;

    @property({ attribute: 'event-config', type: Object, })
    eventConfig;

    @property({ attribute: 'form-errors', type: Object, })
    formErrors;

    @property({ attribute: 'is-editable', type: Boolean, })
    isEditable = false;

    @property({ attribute: 'my-schedule-slug', type: String, })
    myScheduleSlug;

    groupedSessions = [];
    ungroupedSessions = [];

    ungroupedSessionsId = 'ungrouped';
    isFormPristine;
    min;
    max;
    messages;

    sessionsAdded = [];
    serverErrors = this.formErrors;
    hasServerErrors;
    isValid;
    footerTextClassName;
    defaultStatus = STATUS_KEYS.AVAILABLE;
    sessionsInterval;

    constructor() {
        super();
        this.__onSessionClick = this.__onSessionClick.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.updateSessions = this.updateSessions.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.sortGroups();

        if (this.isEditable) {
            this.min = this.eventConfig.min_sessions;
            this.max = this.eventConfig.max_sessions;
            this.isFormPristine = true;
            this.isValid = !this.min && !this.max;
            this.footerClasses = {
                [`${this.footerTextClassName}`]: true,
                ['dj-grid__col']: true,
                ['dj-grid__col-s--6']: true
            }

            this.messages = {
                $info: {
                    'min-sessions': this.min && !this.max,
                    'max-sessions': !this.min && this.max,
                    'min-max-sessions': this.min && this.max
                },
                $error: {
                    'min-sessions': false,
                    'max-sessions': false,
                    'min-max-sessions': false,
                    'sessions-clash': false
                },
            };

            this.hasServerErrors = this.serverErrors? !!this.serverErrors.length: undefined;

            this.addEventListener(ON_SESSION_CLICK, this.__onSessionClick);

            //  Update <input> elements, this is necessary because Django doesn't bother...
            this.processSessions(this.updateInput);

            // Number of sessions added
            this.updateSessionsAdded();

            // Validation
            if (this.hasServerErrors) {
                this.validateSessions();
            } else {
                // Initial validation
                this.isValid = this.sessionsAddedValidator({ displayErrors: false }) && this.sessionGroupsMinMaxValidator();
            }
        }

        if (this.djSessionApiEndpoint) {
            // Written as an anonymous function as jest requires timer based functions to be written this way, so we can successfully mock it.
            this.sessionsInterval = window.setInterval(() => this.updateSessions(), SESSION_UPDATE_INTERVAL);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this.isEditable) {
            this.removeEventListener(ON_SESSION_CLICK, this.__onSessionClick);
        }
        window.clearInterval(this.sessionsInterval);
    }

    /**
     * Calls the api every 10 secs and updates sessions with previous status set to pending
     */
    async updateSessions() {
        try {
            const response = await fetch(this.djSessionApiEndpoint);
            let statuses = await response.json();
            statuses = JSON.parse(statuses);

            this.sessionGroups = this.sessionGroups.map(group => {
                const newSessions = group.sessions.map(session => {
                    const currentStatus = session.delegate_status;
                    const newStatus = statuses[session.id];

                    return currentStatus == STATUS_KEYS.PENDING && currentStatus != newStatus
                    ? {
                        ...session,
                        delegate_status: newStatus,
                        status: newStatus
                    }
                    : session;
                })
                return { ...group, sessions: newSessions };
            });
        } catch(err) {
            console.error(err);
        }
    };

    /**
     * Converts sessionGroups into a nested structure of groups with child sessions.
     */
    sortGroups() {
        this.sessionGroups = this.sessionGroups.map(group => {
            this.groupedSessions = [...this.groupedSessions, ...group.sessions];

            return Object.assign({}, group, {
                hasClashes: false,
                sessions: group.sessions
                    .map(sessionId => this.sessions.find(({ id }) => id === sessionId))
                    .reduce((prev, curr) => [...prev,
                        this.decorateSession(curr, group.id)], [])
                    .sort(this.sortBy('session_order')),
            });
        });

        // Handle ungrouped sessions
        this.ungroupedSessions = this.sessions
            .reduce((prev, curr) => {
                const isGroupedSession = !!this.groupedSessions.find(sessionId => sessionId === curr.id);
                return isGroupedSession ? prev : [...prev,
                    this.decorateSession(curr, this.ungroupedSessionsId)];
            }, [])
            .sort(this.sortBy('session_order'));

        if (this.ungroupedSessions.length) {
            this.sessionGroups.push({
                group_order: Infinity,
                id: this.ungroupedSessionsId,
                sessions: this.ungroupedSessions,
                title: this.contentStrings.session_ungrouped_sessions_title,
            });

            if (this.sessionGroups.length === 1) {
                this.onlyUngrouped = true;
            }
        }

        // Sort groups
        this.sessionGroups = this.sessionGroups.sort(this.sortBy('group_order'));
    }

    // Helpers

    /**
     * Returns the initial session status
     * @param {Object} session
     * @returns {string}
    */
    initialSessionStatus(session) {
        if (this.isChecked(session) && !this.hasServerErrors) {
            return STATUS_KEYS.ADDED;
        }
        return session.delegate_status || STATUS_KEYS.AVAILABLE;
     };

    /**
     * Decorate a session to include necessary values for rendering the session
     * @param {Object} session
     * @param {number|string} groupId
     * @returns {Object}
    */
    decorateSession(session, groupId) {
        return Object.assign({}, session, {
            date: session.readable_datetime_range || null,
            description: session.description || null,
            groupId,
            status: this.initialSessionStatus(session),
            venue: session.venue || null,
        });
    }

    /**
     * Given a Session, return a boolean that indicates if the input is checked or not.
     * Due to the way that the PENDING status works, this needs to be handled slightly differently:
     *
     * A session with the status of PENDING will have a checked corresponding checkbox, but it has been checked
     * programmatically rather than by the user so we actually want isChecked to return false in that instance.
     * Otherwise we would see a PENDING session's status change to ADDED after a form post that failed
     * server side validation.
     *
     * @param {Object} session
     * @returns {boolean}
    */
    isChecked ({ id, delegate_status }){
        return delegate_status !== STATUS_KEYS.PENDING && !!this.findInput(id, { isChecked: true });
    }

    /**
     * Find the <input> element based on the session id
     * @param {number} id
     *   Session Id
     * @param {Object} options
     *   Config options
     * @returns {HTMLElement}
    */
     findInput(id, options = {}) {
         const defaultConfig = {
             isChecked: null,
             notDisabled: true,
            };

        const inputConfig = Object.assign({}, defaultConfig, options);
        let selector = `#id_sessions input[type="checkbox"][value="${id}"]`;

        if (inputConfig.isChecked) {
            selector += '[checked]';
        }

        if (inputConfig.notDisabled) {
            selector += ':not([disabled])';
        }
        return document.querySelector(selector);
    };

    /**
     * Update an <input> element to reflect the session status
     * @param {Object} session
    */
    updateInput({ id, status }) {
        const $input = this.findInput(id);

        if ($input) {
            switch (status) {
                case STATUS_KEYS.ATTENDED:
                    $input.setAttribute('checked', true);
                    $input.setAttribute('disabled', 'disabled');
                    break;
                case STATUS_KEYS.CONFIRMED:
                case STATUS_KEYS.PENDING:
                case STATUS_KEYS.WAITLIST:
                    $input.setAttribute('checked', true);
                    break;
                case STATUS_KEYS.REJECTED:
                    $input.setAttribute('disabled', 'disabled');
                    break;
            }
        }
    };

    /**
     * Updates a session's status based on its current status.
     * @param {Object} group
     * @param {number|string} sessionId
     * @param {string} prevStatus
     * @returns {Object}
    */
    updateSessionStatus = (group, sessionId, prevStatus) => group.sessions.map((session) => {
        if (session.id === sessionId) {
            let nextStatus = prevStatus;

            switch (prevStatus) {
                case STATUS_KEYS.AVAILABLE:
                case STATUS_KEYS.CANCELLED:
                    nextStatus = STATUS_KEYS.ADDED;
                    break;
                case STATUS_KEYS.CONFIRMED:
                case STATUS_KEYS.WAITLIST:
                    nextStatus = STATUS_KEYS.REMOVED;
                    break;
                case STATUS_KEYS.ADDED:
                case STATUS_KEYS.REMOVED:
                    // If the session was previously added or removed then it means we are in a rollback state
                    nextStatus = session.delegate_status || this.defaultStatus;
                    break;
            }

            return Object.assign({}, session, { status: nextStatus });
        }

        return session;
    });

    /**
     * Update a count of the number of sessions that will be sent to the server on submit
    */
    updateSessionsAdded = () => {
        const validStatuses = [
            STATUS_KEYS.ADDED,
            STATUS_KEYS.CONFIRMED,
            STATUS_KEYS.PENDING,
            STATUS_KEYS.WAITLIST,
        ];
        const isAdded = (session) => {
            const { is_past_session: isPastSession } = session;
            const { validate_attended_session_count: validateAttended } = this.eventConfig;
            return (validateAttended && isPastSession) || validStatuses.includes(session.status);
        };
        // Process sessions so that only added sessions are returned,
        // the undefined items in the array are then filtered out
        this.sessionsAdded = this.processSessions(session => isAdded(session) && session).filter(session => session);
    };

    /**
     * Validator for number of sessions added, handles messages and returns a boolean to indicate if the choice of
     * sessions is valid
     * @returns {boolean}
    */
    sessionsAddedValidator(options) {
        const { min_sessions: min, max_sessions: max } = this.eventConfig;
        // No min-max session restrictions
        if (!min && !max) {
            return true;
        }

        const { displayErrors } = options || { displayErrors: true };

        // Min number of sessions
        if (min && !max) {
            const isValid = this.sessionsAdded.length >= min;
            this.messages.$error['min-sessions'] = displayErrors && !isValid;
            return isValid;
        }

        // Max number of sessions
        if (!min && max) {
            const isValid = this.sessionsAdded.length <= max;
            this.messages.$error['max-sessions'] = displayErrors && !isValid;
            return isValid;
        }

        // Min & Max sessions
        if (min && max) {
            const isValid = this.sessionsAdded.length >= min && this.sessionsAdded.length <= max;
            this.messages.$error['min-max-sessions'] = displayErrors && !isValid;
            return isValid;
        }
    };

    /**
     * Validator for session datetime clashes, handles message and returns a boolean to indicate if the choice of
     * sessions is valid
     * @returns {boolean}
    */
    sessionClashesValidator() {
        // Session clashes are always valid
        if (this.eventConfig.allow_clashes) {
            return true;
        }

        const clashes = this.sessionsAdded.reduce((prev, curr, i, array) => {
            const isClash = array.some(item => (item.id !== curr.id) && (item.start === curr.start));
            return isClash ? [...prev,
                curr] : prev;
        }, []);

        const isValid = !clashes.length;

        let groupsWithClashes = [];

        // Add validation messages to each session that has a clash
        this.sessionGroups = this.sessionGroups.map(group => Object.assign({}, group, {
            sessions: group.sessions.map(session => {
                const isClash = clashes.some(clash => clash.id === session.id);
                const hasOtherClashesInGroup = !isClash && clashes.some(clash => clash.groupId !== session.groupId);

                session.validationMessage = isClash ? this.contentStrings.session_clash : null;

                // Handle groups with clashes
                if (isClash) {
                    groupsWithClashes.push(group.id);
                } else if (groupsWithClashes.length) {
                    groupsWithClashes = groupsWithClashes.filter(id => id !== group.id || hasOtherClashesInGroup);
                }

                return session;
            }),
            // Toggle hasClashes on each group
            hasClashes: groupsWithClashes.find(id => group.id === id),
        }));

        this.messages.$error['session-clashes'] = !isValid;
        return isValid;
    };

    /**
     * Validator for session group min/max settings, handles message and returns a boolean to indicate if the choice of
     * sessions is valid
     * @returns {boolean}
    */
    sessionGroupsMinMaxValidator() {
        let isValid = true;
        const invalidGroups = [];
        if (!this.eventConfig.groups_min_max) {
            return true;
        }

        // Min/max validation for session groups
        this.sessionGroups.forEach(group => {
            const { min_sessions_required : min, max_sessions_allowed : max } = group;
            if (min || max) {

                const num_sessions = this.sessionsAdded.filter(s => s.groupId === group.id).length;

                group.minError = false;
                group.maxError = false;
                if (num_sessions < (min || -Infinity)) {
                    isValid = false;
                    group.minError = true;
                    invalidGroups.push(group.title);
                } else if (num_sessions > (max || Infinity)) {
                    isValid = false;
                    group.maxError = true;
                    invalidGroups.push(group.title);
                }
            }
        })
        this.messages.$error['group-min-max-sessions'] = !isValid;
        this.groupsWithMinMaxError = invalidGroups.join(', ');
        return isValid;
    };


    /**
     * Validate the sessions and make adjustments to the DOM if necessary.
     * This should always be run after updateSessionsAdded()
    */
    validateSessions() {
        const isSessionsAddedValid = this.sessionsAddedValidator();
        const isSessionsClashValid = this.sessionClashesValidator();
        const isSessionGroupsMinMaxValid = this.sessionGroupsMinMaxValidator();
        this.isValid = isSessionsAddedValid && isSessionsClashValid && isSessionGroupsMinMaxValid;
        this.footerClasses[`${this.footerTextClassName}--error`] = !this.isValid;
    };

    /**
     * Click handler
     * @param {number|string} groupId
     * @param {number|string} sessionId
     * @param {string} prevStatus
    */
    __onSessionClick({ detail }) {
        const { groupId, sessionId, prevStatus } = detail;

        const $input = this.findInput(sessionId, { notDisabled: true });

        if (!$input) {
            return;
        }

        // Programmatically click the hidden <input type="checkbox">
        $input.click();

        this.isFormPristine = false;
        // Remove server-side error messages
        this.hasServerErrors = false;


        this.sessionGroups = this.sessionGroups.map((group) => {
            if (group.id !== groupId) {
                return group;
            }
            return Object.assign({}, group, { sessions: this.updateSessionStatus(group, sessionId, prevStatus) });
        });


        // Update the number of sessions added
        this.updateSessionsAdded();

        // Validation
        this.validateSessions();
    };

    /**
     * Returns info message based on true value in this.messages
     * @returns {string}
    */
    getInfoMessage() {
        const infoMessage = this.messages.$info;

        if (infoMessage['min-sessions']) {
            return `${ this.editContent.session_min_required_text }: ${ this.eventConfig.min_sessions }`
        }

        if (infoMessage['max-sessions']) {
            return `${ this.editContent.session_max_allowed_text }: ${ this.eventConfig.max_sessions }`
        }

        if (infoMessage['min-max-sessions']) {
            return `${ this.editContent.session_min_required_text }: ${ this.eventConfig.min_sessions }, ${ this.editContent.session_max_allowed_text }: ${ this.eventConfig.max_sessions }`
        }
    }

    /**
     * Returns error message based on true value in this.messages
     * @returns {string}
    */
    getErrorMessage() {
        const errorMessage = this.messages.$error;

        if (errorMessage['min-sessions']) {
            return `${ this.editContent.session_min_max_error_text } ${ this.editContent.session_min_required_text }: ${ this.eventConfig.min_sessions }`
        }

        if (errorMessage['max-sessions']) {
            return `${ this.editContent.session_min_max_error_text } ${ this.editContent.session_max_allowed_text }: ${ this.eventConfig.max_sessions }`
        }

        if (errorMessage['min-max-sessions']) {
            return html`
                ${ this.editContent.session_min_max_error_text } ${ this.editContent.session_min_required_text }: ${ this.eventConfig.min_sessions }
                <br/> ${ this.editContent.session_min_max_error_text } ${ this.editContent.session_max_allowed_text }: ${ this.eventConfig.max_sessions }
            `
        }

        if (errorMessage['session-clashes']) {
            return `${ this.editContent.session_same_start_time_error_text }`
        }

        if (errorMessage['group-min-max-sessions']) {
            return `${ this.editContent.session_group_min_max_error_text } ${ this.groupsWithMinMaxError }`
        }
    }

    /**
     * SortBy object key
     * @param sortKey
     * @returns {function(*, *): number}
    */
    sortBy(sortKey){
        return (a, b) => a[sortKey] - b[sortKey];
    }


    /**
     * Due to the way that sessions are nested inside vm.groups (for templating reasons)
     * It is necessary to loop through all groups, then all sessions in order to process any data from the sessions.
     * This is particularly necessary for calculating where sessions have similar properties, e.g.
     *  - sessions added by the user
     *  - session datetime clashes
     *
     *  This helper accepts a function which will process each session and return an array of processed sessions for
     *  comparison by the calling code.
     *
     *  Example usages given the groups look like this:
     *
     *  groups = [
     *      {
     *          id: 1,
     *          title: 'Group A',
     *          sessions: [
     *              { id: 1, title: 'foo', status: 'available' },
     *              { id: 2, title: 'bar', status: 'added' },
     *          ],
     *      },
     *      {
     *          id: 2,
     *          title: 'Group B',
     *          sessions: [
     *              { id: 3, title: 'baz', status: 'confirmed' },
     *              { id: 4, title: 'qux', status: 'waitlist' },
     *          ],
     *      },
     *  ]
     *
     *  // Usage: Process sessions to get an array of ids
     *  const sessionIds = processSessions(({ id }) => ({ id })) // => [1, 2, 3, 4]
     *
     *  // Usage: Process all sessions to find ones clicked on by the user
     *
     *  let sessionsAdded = processSessions(session => session.status !== 'available')
     *  // => [undefined, { ..., status: 'added' }, { ..., status: 'confirmed' }, { ..., status: 'waitlist' }]
     *
     *  // This can then be filtered to remove the undefined items
     *  sessionsAdded = sessionsAdded.filter(session => session && session)
     *
     * @param {Function} func
    */
    processSessions(func) {
        return this.sessionGroups.reduce((prev, curr) => [...prev,
            ...curr.sessions.map(func)], []);
    }

    render() {
        return html`
        ${!this.onlyUngrouped ?
            html
                `<dj-accordion class="dj-accordion">
                    ${this.sessionGroups.map(group => {
                        return html`
                        <dj-accordion-item
                        class="dj-accordion__item complex-title"
                        initially-open="true">
                        <div class="dj-accordion__title">
                            ${this.isEditable ? html `
                            <div
                                class="dj-accordion__title-content">
                                <span>${ group.title }</span>
                                <div>
                                ${ group.min_sessions_required ? html`
                                <span class="sessions-form__group-min-max">${this.editContent.session_group_min_required_text}: ${ group.min_sessions_required }</span>
                                `: ''}
                                ${ group.max_sessions_allowed ? html`
                                <span class="sessions-form__group-min-max"> ${this.editContent.session_group_max_allowed_text}: ${group.max_sessions_allowed }</span>
                                `: ''}
                            </div>
                            ${group.hasClashes? html `
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    class="dj-icon dj-accordion__title--warning"
                                    aria_hidden=true
                                >
                                    <use xlink:href="#mi-warning"></use>
                                </svg>
                            </span>`: ''}

                            </div>`
                            : html`<span>${ group.title }</span>` }
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                class="dj-icon dj-accordion__chevron"
                                aria_hidden=true
                            >
                                <use xlink:href="#mi-chevron-right"></use>
                            </svg>
                        </div>
                        <div class="dj-accordion__content-wrap">
                            <div class="dj-accordion__content">
                            ${group.sessions.map(session => {
                                return html`
                                        <dj-session .session="${session}" .strings="${this.contentStrings}" .isEditable="${this.isEditable}" .groupId="${group.id}"></dj-session>`
                                })
                            }
                            ${this.isEditable ? html`
                                ${ group.minError ? html`<div class="sessions-form__group-min-max-error">${ this.editContent.session_group_min_error_text }</div>`: '' }
                                ${ group.maxError ? html `<div class="sessions-form__group-min-max-error">${ this.editContent.session_group_max_error_text }</div>`: '' }
                            `: ''}

                            </div>
                        </div>
                    </dj-accordion-item
                    `
                })}
                </dj-accordion>` :
                this.sessionGroups.map(group => {
                    return html`
                        <div>
                             ${group.sessions.map(session => {
                                return html`
                                    <dj-session .session="${session}" .strings="${this.contentStrings}" .isEditable="${this.isEditable}" .groupId="${group.id}"></dj-session>`
                                })
                            }
                        </div>`
                })
        }
        ${this.isEditable ? html `
        <div class="sessions-form__footer">
            <div class="dj-grid dj-grid--align-center">
                <div class="${classMap(this.footerClasses)}">
                    <h3 class="sessions-form__footer-text-title">
                        ${this.editContent.registration_edit_schedule_footer_title } ${this.sessionsAdded.length}
                    </h3>
                        ${this.isValid || (this.isFormPristine && !this.hasServerErrors) ? html`
                        <div>
                            <p>${this.getInfoMessage()}</p>
                        </div>`: ''}
                        ${!this.isFormPristine & !this.isValid ? html`
                        <div role="alert">
                            <div class="sessions-form__footer-msg-error">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        class="dj-icon"
                                        aria_hidden=true
                                    >
                                        <use xlink:href="#mi-warning"></use>
                                    </svg>
                                </span>
                                <p class="sessions-form__footer-msg-error-text">
                                    ${this.getErrorMessage()}
                                </p>
                            </div>
                        </div>`:''}
                        ${this.hasServerErrors ?
                            this.serverErrors.map(serverError => {
                                return html`
                                <div>
                                <p class="sessions-form__footer-msg-error">
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            class="dj-icon"
                                            aria_hidden=true
                                        >
                                            <use xlink:href="#mi-warning"></use>
                                        </svg>
                                    </span>
                                    <span class="sessions-form__footer-msg-error-text">
                                        ${serverError}
                                    </span>
                                </p>
                            </div>`
                        }) : ''}
                </div>
                <div class="dj-grid__col dj-grid__col-s--6 dj-grid__col--justify-flex-end sessions-form__footer-actions">
                    <a href="${this.myScheduleSlug}" class="dj-link">
                        ${ this.editContent.registration_edit_schedule_footer_cancel_link_text }
                    </a>
                    <input
                        class="dj-button dj-button--primary"
                        ?disabled="${!this.isValid}"
                        type="submit"
                        value="${ this.editContent.registration_next_button_text }"/>
                </div>
            </div>
        </div>`:''}
     `
    }
}


export { Sessions, SESSION_UPDATE_INTERVAL, };

import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';
import Cropper from 'cropperjs';

@customElement('dj-cropper')
class DjCropper extends DjElement {
    @state()
    _x;

    @state()
    _y;

    @state()
    _width;

    @state()
    _height;

    @state()
    _rotate;

    @property({
        type: String,
        attribute: 'field-name'
    })
    fieldName;

    @property({
        type: String,
        attribute: 'img-src'
    })
    imgSrc;

    _cropper = undefined;

    constructor() {
        super();
        this.initCropper = this.initCropper.bind(this);
    }

    initCropper() {
        const $image = this.querySelector('img');
        if ($image) {
            //we want to destroy cropper if an image was selected before
            if (this._cropper) {
                this._cropper.destroy();
            }
            this._cropper = new Cropper($image, {
                aspectRatio: 1 / 1,  //TODO this might come from field settings
                zoomable: false,
                scalable: false,
                rotatable: true,
                checkOrientation: true,
                crop: (event) => {
                    this._x = event.detail.x;
                    this._y = event.detail.y;
                    this._width = event.detail.width;
                    this._height = event.detail.height;
                },
            });
        }
    }

    updated(changedProperties) {
        //it was observed that the image on cropper only changes on first selection
        // subsequent selections does not change the image
        //we want to change the cropper data whenever user chooses an image
        if (changedProperties.has('imgSrc')) {
            this.initCropper();
        }
    }

    render() {
        return html`
        <div>
            <img src=${this.imgSrc}/>
            <input
                type="hidden"
                name=${'file-crop-rotation-' + this.fieldName}
                value=${this._rotate}
            />
            <input
                type="hidden"
                name=${'file-crop-x-' + this.fieldName}
                value=${this._x}
            />
            <input
                type="hidden"
                name=${'file-crop-y-' + this.fieldName}
                value=${this._y}
            />
            <input
                type="hidden"
                name=${'file-crop-width-' + this.fieldName}
                value=${this._width}
            />
            <input
                type="hidden"
                name=${'file-crop-height-' + this.fieldName}
                value=${this._height}
            />
        </div>`

    }
}

export { DjCropper }

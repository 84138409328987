import { customElement, property } from 'lit/decorators.js';

import { DjCheckboxRadioBase } from './checkbox-and-radio.lit';

@customElement('dj-radio')
class DjRadio extends DjCheckboxRadioBase {

    @property({
        type: String,
        attribute: 'dj-radio-name',
    })
    name;

    constructor() {
        super();

        this.CLASSES = {
            BASE: 'dj-radio',
            BOX_CHECKED: 'dj-radio__box--checked',
            DISABLED: 'dj-radio--disabled',
            FOCUSED: 'dj-radio--focused',
        };
    }

    connectedCallback() {
        super.connectedCallback();

        if (typeof this.name !== 'string') {
            console.warn('DjRadio missing attribute `dj-radio-name`. Radio functionality will not be as expected.');
            this.allRadios = [this];
        } else {
            this.allRadios = [...document.querySelectorAll(`dj-radio[dj-radio-name=${this.name}]`)];
        }

    }

    onChange() {
        this.allRadios.forEach((radio) => radio.updateState());
    }
}

export { DjRadio };

import { customElement, property } from 'lit/decorators.js';
import { bindKeyboardEvents, keynames } from '../../utils';
import { resize } from '../../utils/resize';

import { DjElement } from '../base-component/base-component.lit';

const ITEM_ADDED = 'dj-tab-nav-item:added';
const ITEM_REMOVED = 'dj-tab-nav-item:removed';
const ITEM_ACTIVE = 'dj-tab-nav-item:active';

const CLASSES = {
    NAV_ITEM_ACTIVE: 'dj-tabs__nav-item--active',
}

@customElement('dj-tab-nav')
class TabNav extends DjElement {
    items = [];
    currentlyActive = null;

    @property({
        type: Boolean,
        attribute: 'dj-tabs-nav-scrollable'
    })
    scrollable = false;

    constructor() {
        super();

        this.registerItem = this.registerItem.bind(this);
        this.unregisterItem = this.unregisterItem.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.setCurrentlyActive = this.setCurrentlyActive.bind(this);
        this._prev = this._prev.bind(this);
        this._next = this._next.bind(this);
        this._adjustScroll = this._adjustScroll.bind(this);
        this._setTabButtonDisplay = this._setTabButtonDisplay.bind(this);
        this.updateButtons = this.updateButtons.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.$itemsNavContainer = this.querySelector('.dj-tabs__nav');

        this.addEventListener(ITEM_ADDED, this.registerItem);
        this.addEventListener(ITEM_REMOVED, this.unregisterItem);
        this.addEventListener(ITEM_ACTIVE, this.setCurrentlyActive);

        bindKeyboardEvents(this, {
            [keynames.DOWN]: this._next,
            [keynames.RIGHT]: this._next,
            [keynames.UP]: this._prev,
            [keynames.LEFT]: this._prev,
        }, 'keydown');

        if (this.scrollable) {
            this.$prevButton = this.querySelector('button[prev]');
            this.$nextButton = this.querySelector('button[next]');
            this.$prevButton.addEventListener('click', this._prev);
            this.$nextButton.addEventListener('click', this._next);
            this._setTabButtonDisplay();
            this._adjustScroll();

            resize(this._setTabButtonDisplay);
        }

    }

    disconnectedCallback() {
        this.removeEventListener(ITEM_ADDED, this.registerItem);
        this.removeEventListener(ITEM_REMOVED, this.unregisterItem);
        this.removeEventListener(ITEM_ACTIVE, this.setCurrentlyActive);

        if (this.scrollable) {
            this.$prevButton.removeEventListener('click', this._prev);
            this.$nextButton.removeEventListener('click', this._next);
        }
    }

    registerItem(event) {
        let tab = event.detail;
        this.items.push(tab);

        if (!this.currentlyActive) {
            this.currentlyActive = tab;
            tab.markActive();
        }
        else {
            const activeTab = tab.classList.contains(CLASSES.NAV_ITEM_ACTIVE)
            if (activeTab) {
                this.setActiveTab(tab);
            }
        }

        if (this.scrollable) {
            this.updateButtons();
        }

        event.stopPropagation();
    }

    unregisterItem(event) {
        this.items.pop(event.detail);
        event.stopPropagation();
    }

    setActiveTab(tab){
        tab.markActive();
        this.currentlyActive.markInactive();
        this.currentlyActive = tab;
        if(this.scrollable){
            this.updateButtons();
            this._adjustScroll();
        }
    }

    setCurrentlyActive(event, focus = false){
        let tab = event.detail;
        this.setActiveTab(tab);

        if (focus) {
            tab.focus();
        }

    }

    _prev() {
        this._relativeTabChange(-1, true);
    }

    _next() {
        this._relativeTabChange(1, true);
    }

    _relativeTabChange(adjustment, moveFocus = false) {
        const index = this.items.indexOf(this.currentlyActive);
        const newIndex = index + adjustment;
        const newTab = this.items[newIndex];

        if (!newTab) {
            return;
        }

        this.setActiveTab(newTab, moveFocus);
    }

    _setTabButtonDisplay() {
        const width = this.offsetWidth;
        const scrollWidth = this.$itemsNavContainer.scrollWidth;
        if (scrollWidth > width) {
            this.$prevButton.style.display = '';
            this.$nextButton.style.display = '';
        } else {
            this.$prevButton.style.display = 'none';
            this.$nextButton.style.display = 'none';
        }
    }

    _adjustScroll() {
        const activeTab = this.currentlyActive;

        if (!activeTab) {
            return;
        }

        const container = activeTab.parentElement;
        const containerRect = container.getBoundingClientRect();
        const containerOffset = containerRect.left;
        const containerWidth = containerRect.width;
        const containerScroll = container.scrollLeft;

        const activeTabRect = activeTab.getBoundingClientRect();
        const activeTabOffset = activeTabRect.left - containerOffset;
        const activeTabWidth = activeTabRect.width;

        const leftInView = activeTabOffset >= 0;
        const rightInView = activeTabOffset + activeTabWidth <= containerWidth;

        if (!leftInView || !rightInView) {
            container.scrollLeft = containerScroll + activeTabOffset;
        }
    }

    updateButtons() {
        if (!this.$prevButton || !this.$nextButton) {
            return;
        }

        const index = this.items.indexOf(this.currentlyActive);

        this.$prevButton.disabled = index <= 0;
        this.$nextButton.disabled = index >= this.items.length - 1;
    }
}

@customElement('dj-tab-nav-item')
class TabNavItem extends DjElement {

    constructor() {
        super();
        this.active = this.active.bind(this);
    }

    connectedCallback() {
        this.addEventListener('click', this.active);

        // a11y
        this.setAttribute('role', this.getAttribute('role') || 'tab');
        this.setAttribute('tabindex', this.getAttribute('tabindex') || '0');
        bindKeyboardEvents(this, {
            [keynames.ENTER]: () => this.active,
        });


        this.dispatchEvent(
            new CustomEvent(ITEM_ADDED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    disconnectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_REMOVED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    active() {
        this.dispatchEvent(
            new CustomEvent(ITEM_ACTIVE, {
                bubbles: true,
                detail: this,
            })
        );
    }

    markActive() {
        this.classList.add(CLASSES.NAV_ITEM_ACTIVE);
        this.setAttribute('aria-selected', 'true');
        this.setAttribute('tabindex', 0);
        this.focus();
    }

    markInactive() {
        this.classList.remove(CLASSES.NAV_ITEM_ACTIVE);
        this.setAttribute('aria-selected', 'false');
        this.setAttribute('tabindex', -1);
    }

}

export { TabNav, TabNavItem, ITEM_ADDED, ITEM_REMOVED };
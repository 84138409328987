import Cookies from 'js-cookie';
import { customElement } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

const ACCEPT_COOKIE_NAME = 'ACCEPT_COOKIES';

@customElement('dj-cookie')
class CookieConsent extends DjElement {
    constructor() {
        super();
        this.$dismissBtn = this.querySelector('#dj-cookie__dismiss');

        // Bind event listeners callbacks.
        this.acceptCookie = this.acceptCookie.bind(this);
    }

    get visible() {
        return Cookies.get(ACCEPT_COOKIE_NAME) !== 'true';
    }

    _toogleBannerVisibility = (visibility) => {
        this.classList.toggle('dj-cookie--visible', visibility);
        this.setAttribute('aria-hidden', !visibility);
    };

    acceptCookie = () => {
        const date = new Date();
        date.setYear(date.getFullYear() + 1);

        Cookies.set(ACCEPT_COOKIE_NAME, 'true', {
            expires: date,
            secure: window.location.protocol === 'https:',
        });

        this._toogleBannerVisibility(this.visible);
    };

    connectedCallback() {
        this._toogleBannerVisibility(this.visible);
        this.$dismissBtn.addEventListener('click', this.acceptCookie);
    }

    disconnectedCallback() {
        this.$dismissBtn.removeEventListener('click', this.acceptCookie);
    }
}

export { CookieConsent };

import { breakpoint, closest, hrefMatchesUrl } from '../../utils.js';

class SmoothScrollHelper {
    static TARGET_ATTR = 'dj-smooth-scroll';
    static DURATION = 1000;

    static connectedCallback() {
        document.body.addEventListener('click', SmoothScrollHelper.onClick);
    }

    static disconnectedCallback() {
        document.body.removeEventListener('click', SmoothScrollHelper.onClick);
    }

    static onClick(event) {
        const link = event.target && closest(`[${SmoothScrollHelper.TARGET_ATTR}]`, event.target);
        if (!link) {
            return;
        }

        const href = link.getAttribute('href');
        const isSamePage = !!href && hrefMatchesUrl(href, window.location.href);
        // If this is a link to a different page, just navigate there.
        if (!isSamePage) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const scrollTargetSelector = link.getAttribute(SmoothScrollHelper.TARGET_ATTR);
        const scrollTarget = document.querySelector(scrollTargetSelector);

        if (!scrollTarget) {
            return;
        }

        SmoothScrollHelper.smoothScrollTo(scrollTarget, link, scrollTargetSelector)
    }

    static smoothScrollTo(scrollTarget, link, href) {
        const scrollDuration = SmoothScrollHelper.DURATION;
        const $navBar = closest('.dj-nav-bar', link);
        const $menuTrigger = $navBar ? $navBar.querySelector('.dj-header__menu-trigger') : null;

        let scrollStart;
        let scrollStop;
        let scrollDistance;
        let timeStart;

        function easing(time, start, distance, duration) {
            if ((time /= duration / 2) < 1) {
                return distance / 2 * time * time + start;
            }
            time -= 1;
            return -distance / 2 * ((time) * (time - 2) - 1) + start;
        }

        function loop(timeCurrent) {
            if (!timeStart) {
                timeStart = timeCurrent;
            }
            const timeElapsed = timeCurrent - timeStart;

            window.scrollTo(0, easing(
                timeElapsed,
                scrollStart,
                scrollDistance,
                scrollDuration
            ));

            timeElapsed < scrollDuration ?
                window.requestAnimationFrame(loop) :
                done();
        }

        function done() {
            if (!scrollTarget.hasAttribute('tabindex')) {
                scrollTarget.setAttribute('tabindex', '-1');
            }
            scrollTarget.focus();

            window.location.hash = href;
            window.scrollTo(0, scrollStart + scrollDistance);
            timeStart = false;
        }

        // Force the navigation menu to close on mobile.
        if ($menuTrigger && breakpoint() === 'xsmall') {
            $menuTrigger.click();
        }

        scrollStart = window.scrollY || window.pageYOffset;
        scrollStop = scrollTarget.getBoundingClientRect().top + scrollStart;
        scrollDistance = Math.round(scrollStop - scrollStart);

        window.requestAnimationFrame(loop);
    }
}

export { SmoothScrollHelper };

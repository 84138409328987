import { customElement } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

/**
 * Wrapper around a native textarea
 * The only functionality is automatically setting the row number of the textarea whenever
 * it changes size, the input changes, or if we show/hide the field.
 */
@customElement('dj-textarea')
class DjTextarea extends DjElement {

    $textarea;
    constructor() {
        super();
        // or querySelector(textarea)?
        this.$textarea = this.children[0];
        this.growTextarea = this.growTextarea.bind(this);
        this.getDimensions = this.getDimensions.bind(this);
        this.resizeObserver = new ResizeObserver(() => this.growTextarea());
    }

    connectedCallback() {
        super.connectedCallback();
        this.$textarea.addEventListener('input', this.growTextarea);
        window.addEventListener('resize', this.growTextarea);

        this.resizeObserver.observe(this.$textarea);

        this.growTextarea();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('input', this.growTextarea);
        window.removeEventListener('resize', this.growTextarea);
        this.resizeObserver.disconnect();
    }

    growTextarea() {
        const node = this.$textarea;
        node.style.overflow = 'hidden';
        node.setAttribute('rows', 1);

        const {
            borderHeight,
            lineHeight,
            paddingHeight,
            scrollHeight,
        } = this.getDimensions();

        const rows = Math.ceil(scrollHeight / lineHeight);
        node.setAttribute('rows', rows);

        node.style.height = scrollHeight + paddingHeight + borderHeight + 'px';
        node.style.overflow = 'auto';
    };

    getDimensions() {
        const node = this.$textarea;

        let borderHeight,
            paddingHeight,
            lineHeight,
            scrollHeight;

        const paddingStyle = node.style.padding || '',
            borderStyle = node.style.border || '',
            heightStyle = node.style.height || '',
            cs = window.getComputedStyle(node);

        borderHeight = parseInt(cs.borderBottomWidth, 10) + parseInt(cs.borderTopWidth, 10);
        paddingHeight = parseInt(cs.paddingBottom, 10) + parseInt(cs.paddingTop, 10);

        node.style.border = 0;
        node.style.height = 'auto';
        node.style.padding = 0;

        scrollHeight = node.scrollHeight;
        lineHeight = node.offsetHeight / parseInt(node.getAttribute('rows'), 10);

        if (paddingStyle === '') {
            node.style.removeProperty('padding');
        } else {
            node.style.padding = paddingStyle;
        }

        if (borderStyle === '') {
            node.style.removeProperty('border');
        } else {
            node.style.border = borderStyle;
        }

        if (heightStyle === '') {
            node.style.removeProperty('height');
        } else {
            node.style.height = heightStyle;
        }

        return {
            borderHeight: borderHeight,
            paddingHeight: paddingHeight,
            scrollHeight: scrollHeight,
            lineHeight: lineHeight,
        };
    };

}

export { DjTextarea };

import { customElement } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';


@customElement('dj-video-thumbnail')
class VideoThumbnail extends DjElement {

    constructor() {
        super();
        this.$thumbnail = this.querySelector('img');
        // Bind event listeners callbacks.
        this.playVideo = this.playVideo.bind(this);
    }

    playVideo = () => {
        //get properties
        var self = this;
        const videoId = this.getAttribute('data-video-id');
        const cbId = this.getAttribute('data-cb-id');
        const playlistId = this.getAttribute('data-video-playlist');
        let playerVars = {autoplay:1, mute:0};
        if(playlistId){
            playerVars['list'] = playlistId;
            playerVars['listType'] = 'playlist';
        }
        //play video
        new YT.Player(cbId, {
            playerVars,
            videoId: videoId,
            events: {
              'onReady': () => {
                    self.querySelector('iframe').focus();
              },
            }
        });
        if(this.$thumbnail){
            this.$thumbnail.hidden=true;
            this.$thumbnail.removeEventListener('click', this.playVideo);
        }
    };

    connectedCallback() {
        super.connectedCallback();
        if(this.$thumbnail){
            this.$thumbnail.addEventListener('click', this.playVideo);  
        }else{
            window.addEventListener("load", this.playVideo);
        }
    }

    disconnectedCallback() {
        if(this.$thumbnail){
            this.$thumbnail.removeEventListener('click', this.playVideo);
        }
        else{
            window.removeEventListener("load", this.playVideo);
        }
    }
}

export { VideoThumbnail };

import { bindKeyboardEvents, keynames } from '../../utils';
import { FOCUSABLE_ELEMENTS_SELECTOR } from '../toggle-click/toggle-click';

export const ATTRS = {
    ARIA_CONTROLS: 'aria-controls',
    ARIA_EXPANDED: 'aria-expanded',
    CLASS_KEY: 'dj-menu-class-key',
    CLASS_VALUE: 'dj-menu-class-value',
    CLOSE_ON_ESC: 'dj-menu-escape',
    CLOSE_ON_TAB: 'dj-menu-tab',
    FOCUS_ACTIVE: 'dj-menu-item-active',
    HIDE: 'dj-hide',
    KEYBOARD: 'dj-menu-keyboard',
    MENU_DROPDOWN: 'dj-menu-dropdown',
    MENU_ITEM: 'dj-menu-item',
    OPEN_ON_ENTER: 'dj-menu-enter',
    SHOW: 'dj-show',
};

export class MenuDropdown {
    constructor() {
        this.state = {};
        this.menuListNodes = [];
        this.menuListItems = [];

        this.onBackgroundMousedown = this.onBackgroundMousedown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onDownKey = this.onDownKey.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.onEscape = this.onEscape.bind(this);
        this.onSpace = this.onSpace.bind(this);
        this.onTab = this.onTab.bind(this);
        this.onUpKey = this.onUpKey.bind(this);
        this.setAccessibility = this.setAccessibility.bind(this);
        this.setClasses = this.setClasses.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.setVisibility = this.setVisibility.bind(this);

        // Add event listeners
        this.initializeListeners();

        this.init();
    }

    initializeListeners() {
        document.addEventListener('click', this.onClick);

        // Close menu dropdown on background mouse down.
        window.addEventListener('mousedown', this.onBackgroundMousedown, true);

        bindKeyboardEvents(document, {
            [keynames.SPACE]: this.onSpace,
            [keynames.ENTER]: this.onEnter,
        });

        // Close menu dropdown on Esc key using keydown because modal uses keyup & keypress.
        bindKeyboardEvents(
            document,
            {
                [keynames.DOWN]: this.onDownKey,
                [keynames.ESCAPE]: this.onEscape,
                [keynames.TAB]: this.onTab,
                [keynames.UP]: this.onUpKey,
            },
            'keydown'
        );
    }

    get firstMenuitem() {
        return this.menuListItems[0];
    }

    get lastMenuitem() {
        return this.menuListItems[this.menuListItems.length - 1];
    }

    get currentMenuitem() {
        return (
            this.menuListItems.find((item) =>
                item.hasAttribute(`${ATTRS.FOCUS_ACTIVE}`)
            ) ?? this.firstMenuitem
        );
    }

    get previousMenuitem() {
        if (this.currentMenuitem === this.firstMenuitem) {
            return this.lastMenuitem;
        }

        let index = this.menuListItems.indexOf(this.currentMenuitem);
        return this.menuListItems[index - 1];
    }

    get nextMenuitem() {
        if (this.currentMenuitem === this.lastMenuitem) {
            return this.firstMenuitem;
        }

        let index = this.menuListItems.indexOf(this.currentMenuitem);
        return this.menuListItems[index + 1];
    }

    get openMenus() {
        const asArray = Object.entries(this.state);
        const filtered = asArray.filter(([_, value]) => value);
        return Object.fromEntries(filtered);
    }

    init() {
        // Set starting state for the UI.
        const triggerEls = [
            ...document.querySelectorAll(`[${ATTRS.MENU_DROPDOWN}]`),
        ];
        const keys = triggerEls.map((el) =>
            el.getAttribute(ATTRS.MENU_DROPDOWN)
        );
        keys.forEach((key) => {
            this.state[key] = false;
            this.setVisibility(key);
            this.setAccessibility(key);
            this.setClasses(key);
            this.menuListNodes.push(
                document.querySelector(`[${ATTRS.SHOW}='${key}']`)
            );
            this.menuListItems.push(
                ...document.querySelectorAll(`[${ATTRS.MENU_ITEM}='${key}']`)
            );
        });
        // Add the button elements and list items to menuListNodes so they're not considered as background elements.
        this.menuListNodes.push(...triggerEls);
        this.menuListNodes.push(...this.menuListItems);
    }

    setMenuState(key, state = null) {
        if (key === null || !(key in this.state) || this.state[key] == state) {
            return;
        }

        // If no state passed, toggle the state.
        if (state === null) {
            this.state[key] = !this.state[key];
        } else {
            this.state[key] = state;
        }

        this.setVisibility(key);
        this.setAccessibility(key);
        this.setClasses(key);
        this.setFocus(key);
    }

    setFocusToMenuitem(newMenuitem) {
        this.menuListItems.forEach((item) => {
            if (item === newMenuitem) {
                item.tabIndex = 0;
                item.setAttribute(`${ATTRS.FOCUS_ACTIVE}`, true);
                newMenuitem.focus();
            } else {
                item.removeAttribute(`${ATTRS.FOCUS_ACTIVE}`);
                item.tabIndex = -1;
            }
        });
    }

    // Helper function to fetch the appropriate key for state based on the event attribute.
    getEventKey(event, attr, preventDefault = true) {
        const el = event.target?.closest(`[${attr}]`);
        if (!el) {
            return null;
        }

        const key = el.getAttribute(attr);
        if (preventDefault) {
            event.preventDefault();
        }
        return key;
    }

    onClick(event) {
        const key = this.getEventKey(event, ATTRS.MENU_DROPDOWN);
        this.setMenuState(key);
    }

    onSpace(event) {
        const key = this.getEventKey(event, ATTRS.KEYBOARD);
        if (!key) {
            return; // Key might be null.
        }

        // Space key opens the menu, otherwise it activates the menu item.
        if (!this.state[key]) {
            this.setMenuState(key);
            this.setFocusToMenuitem(this.firstMenuitem);
        } else if (event.target.href) {
            window.location.href = event.target.href;
        }
    }

    onEnter(event) {
        const key = this.getEventKey(event, ATTRS.OPEN_ON_ENTER);
        this.setMenuState(key);
        this.setFocusToMenuitem(this.firstMenuitem);
    }

    onUpKey(event) {
        const key = this.getEventKey(event, ATTRS.KEYBOARD);
        if (!key) {
            return; // Key might be null.
        }

        if (!this.state[key]) {
            this.setMenuState(key, true);
            this.setFocusToMenuitem(this.lastMenuitem);
        } else {
            this.setFocusToMenuitem(this.previousMenuitem);
        }
    }

    onDownKey(event) {
        const key = this.getEventKey(event, ATTRS.KEYBOARD);
        if (!key) {
            return; // Key might be null.
        }

        if (!this.state[key]) {
            this.setMenuState(key, true);
            this.setFocusToMenuitem(this.firstMenuitem);
        } else {
            this.setFocusToMenuitem(this.nextMenuitem);
        }
    }

    onEscape(event) {
        const key = this.getEventKey(event, ATTRS.CLOSE_ON_ESC);
        this.setMenuState(key, false);
    }

    onTab(event) {
        // Do not prevent default so tabbing works as expected.
        const key = this.getEventKey(event, ATTRS.CLOSE_ON_TAB, false);
        this.setMenuState(key, false);
    }

    setAccessibility(key) {
        const state = this.state[key];
        document
            .querySelectorAll(
                `[${ATTRS.MENU_DROPDOWN}='${key}'][${ATTRS.ARIA_CONTROLS}]`
            )
            .forEach((el) => {
                el.setAttribute(ATTRS.ARIA_EXPANDED, state.toString());
            });
    }

    setVisibility(key) {
        const state = this.state[key];
        document.querySelectorAll(`[${ATTRS.SHOW}='${key}']`).forEach((el) => {
            el.hidden = !state;
        });
        document.querySelectorAll(`[${ATTRS.HIDE}='${key}']`).forEach((el) => {
            el.hidden = state;
        });
    }

    setClasses(key) {
        const state = this.state[key];
        document
            .querySelectorAll(`[${ATTRS.CLASS_KEY}='${key}']`)
            .forEach((el) => {
                el.classList.toggle(el.getAttribute(ATTRS.CLASS_VALUE), state);
            });
    }

    setFocus(key) {
        let potentialTargets = document.querySelectorAll(
            `[${this.state[key] ? ATTRS.SHOW : ATTRS.HIDE}=${key}]`
        );

        if (!potentialTargets.length) {
            // If there are no valid show/hide blocks available, shift focus back to the trigger.
            potentialTargets = document.querySelectorAll(
                `[${ATTRS.MENU_DROPDOWN}="${key}"]`
            );
        }

        let firstFocusable;
        [...potentialTargets].find((target) => {
            firstFocusable = target.querySelector(FOCUSABLE_ELEMENTS_SELECTOR);
            return firstFocusable;
        });
        if (firstFocusable) {
            firstFocusable.focus();
        } else if (potentialTargets.length) {
            potentialTargets[0].focus();
        }
    }

    onBackgroundMousedown(event) {
        // We need to short-circuit as early as possible.
        // Check if state has any keys.
        if (Object.keys(this.state).length > 0) {
            // Check if menulist nodes contains target elements i.e event occured on background.
            if (!this.menuListNodes.find((el) => el === event.target)) {
                // Check if there are any open menus. Close all menus that are opened.
                const openMenus = this.openMenus;
                if (Object.keys(openMenus).length) {
                    for (const property in openMenus) {
                        this.setMenuState(property, false);
                    }
                }
            }
        }
    }
}

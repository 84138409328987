import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';


@customElement('dj-io-countdown')
class IOCountdown extends DjElement {
    @property({
        converter: (value) => new Date(value),
    })
    date = new Date();

    @state() // Re-render if this updates
    remaining = [0, 0, 0, 0];

    interval;

    connectedCallback() {
        super.connectedCallback();
        this.classList.add('dj-io-countdown');

        // Remove the countdown if it has already elapsed
        if (this.date && this.date < new Date()) {
            return this.remove();
        }

        // This is frequent, but Lit will only re-render if there is a change
        this.interval = setInterval(this.tick, 100);
        this.tick();
    }

    disconnectedCallback() {
        if (this.interval) clearInterval(this.interval);
    }

    render() {
        const [days, hours, minutes, seconds] = this.remaining;
        return html`
            <div class="dj-io-countdown__digits" aria-label=${days + ' days'}>
                ${this.renderDigits(days)}
                <span aria-hidden="true">days</span>
            </div>
            <div class="dj-io-countdown__digits" aria-label=${hours + ' hours'}>
                ${this.renderDigits(hours)}
                <span aria-hidden="true">hours</span>
            </div>
            <div class="dj-io-countdown__digits" aria-label=${minutes + ' minutes'}>
                ${this.renderDigits(minutes)}
                <span aria-hidden="true">min</span>
            </div>
            <div class="dj-io-countdown__digits" aria-label=${seconds + ' seconds'}>
                ${this.renderDigits(seconds)}
                <span aria-hidden="true">sec</span>
            </div>
        `;
    }

    *renderDigits(number) {
        const digits = String(number).padStart(2, '0')

        for (const [index, digit] of Object.entries(digits)) {
            yield html`
                <span
                    class="dj-io-countdown__digit dj-io-countdown__digit--${index}"
                    data-digit=${digit}
                ></span>
            `;
        }
    }

    tick = () => {
        const now = new Date();

        // Freeze the countdown when it elapses
        if (this.date < now) {
            // setInterval can be throttled by the browser if the page is not
            // in focus. This could to a negative value so manually set the
            // final value to zero.
            this.remaining = [0, 0, 0, 0];
            return clearInterval(this.interval);
        }

        // Calculate remaining days, hours, minutes and seconds
        let delta = Math.floor((this.date - new Date()) / 1000);
        const seconds = Math.floor(delta % 60);
        delta = delta / 60;
        const minutes = Math.floor(delta % 60);
        delta = delta / 60;
        const hours = Math.floor(delta % 24);
        delta = delta / 24;
        const days = Math.floor(delta);
        this.remaining = [days, hours, minutes, seconds];
    };
}

export { IOCountdown };

import { bindKeyboardEvents, keynames, uniqueId } from '../../utils';
import { customElement, property } from 'lit/decorators.js';
import { DjElement } from '../base-component/base-component.lit';

const ITEM_CREATED = 'dj-accordion:item-created';
const ITEM_OPENED = 'dj-accordion:item-opened';

const DEFAULT_OPENED_CLASS = 'dj-accordion__item--opened';

/**
 * Accordion is a wrapper for AccordionItems, keeps track of them via the ITEM_CREATED and when exclusive closes
 * other items.
 *
 */
@customElement('dj-accordion')
class Accordion extends DjElement {
    @property({
        type: Boolean,
    })
    exclusive = false;

    items = [];

    constructor() {
        super();
        this.registerItem = this.registerItem.bind(this);
        this.closeOtherItems = this.closeOtherItems.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener(ITEM_CREATED, this.registerItem);
        this.addEventListener(ITEM_OPENED, this.closeOtherItems);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener(ITEM_CREATED, this.registerItem);
        this.removeEventListener(ITEM_OPENED, this.closeOtherItems);
    }

    registerItem = (event) => {
        event.stopPropagation();
        this.items.push(event.detail);
    };

    closeOtherItems = (event) => {
        event.stopPropagation();
        if (this.exclusive) {
            this.items.forEach((item) => {
                if (item !== event.detail) {
                    item.closeContent();
                }
            });
        }
    };
}

/**
 * AccordionItem is an individual content section of the accordion, capable of opening and closing
 * itself, and notifying the parent Accordion when opened.
 *
 */
@customElement('dj-accordion-item')
class AccordionItem extends DjElement {
    @property({
        type: Boolean,
        attribute: 'initially-open',
    })
    initiallyOpen = false;

    @property({
        type: String,
        attribute: 'open-class',
    })
    openClass;

    open = false;

    /**
     * $trigger references the title of the AccordionItem used to toggle the contents, it will be the first child
     * of the item node in the dom.
     */
    $trigger;
    /**
     * $content will reference the contents to open and close when $trigger is clicked, it will be the second child
     * of the item node in the dom.
     */
    $content;
    unbindKeys;

    constructor() {
        super();
        this.$trigger = this.children[0];
        this.$content = this.children[1];

        this.toggleHeight = this.toggleHeight.bind(this);
        this.openContent = this.openContent.bind(this);
        this.closeContent = this.closeContent.bind(this);

        this.calcFullHeight = this.calcFullHeight.bind(this);
        this.clearHeight = this.clearHeight.bind(this);
        this.preventFocus = this.preventFocus.bind(this);
        this.updateAriaStates = this.updateAriaStates.bind(this);

        this.openClass = this.openClass || DEFAULT_OPENED_CLASS;
    }

    connectedCallback() {
        super.connectedCallback();

        this.dispatchEvent(
            new CustomEvent(ITEM_CREATED, { bubbles: true, detail: this })
        );

        this.$trigger.addEventListener('click', this.toggleHeight);
        this.$trigger.setAttribute('tabIndex', 0);

        this.unbindKeys = bindKeyboardEvents(this.$trigger, {
            [keynames.ENTER]: this.toggleHeight,
        });

        let contentId = this.$content.getAttribute('id');
        if (!contentId) {
            contentId = uniqueId('accordion-item-');
            this.$content.setAttribute('id', contentId);
        }
        this.$trigger.setAttribute('aria-controls', contentId);

        if (this.initiallyOpen) {
            this.openContent({ skipTransition: true });
        } else {
            this.updateAriaStates();
            this.preventFocus();
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.$trigger.removeEventListener('click', this.toggleHeight);
        this.unbindKeys();
    }

    toggleHeight = () => {
        if (this.open) {
            this.closeContent();
        } else {
            this.openContent();
        }
    };

    openContent = ({ skipTransition = false } = {}) => {
        this.dispatchEvent(
            new CustomEvent(ITEM_OPENED, { bubbles: true, detail: this })
        );
        this.classList.add(this.openClass);

        // Make children focusable again
        this.$content.style.display = '';

        const targetHeight = this.calcFullHeight();

        this.$content.style.height = '0';

        if (!skipTransition) {
            this.$content.style.transition = '';
            this.$content.addEventListener('transitionend', this.clearHeight, {
                once: true,
            });
        }
        // Force reflow. keep this line
        this.$content.offsetHeight;

        this.$content.style.height = targetHeight + 'px';

        if (skipTransition) {
            this.$content.style.transition = '';
            this.clearHeight();
        }
        //scope.$broadcast('dj:accordion:open');
        this.open = true;
        this.updateAriaStates();
    };

    closeContent = () => {
        if (!this.open) return;

        const from = this.calcFullHeight();

        this.classList.remove(this.openClass);

        this.$content.style.height = from + 'px';

        this.$content.style.transition = '';
        this.$content.addEventListener('transitionend', this.preventFocus, {
            once: true,
        });

        // Force reflow. keep this line
        this.$content.offsetHeight;
        this.$content.style.height = '0';

        //scope.$broadcast('dj:accordion:close', elem);
        this.open = false;
        this.updateAriaStates();
    };

    calcFullHeight = () => {
        const previousHeight = this.$content.height;
        this.$content.style.transition = 'none';
        this.$content.style.height = 'auto';
        const finalHeight = this.$content.offsetHeight;
        this.$content.style.height = previousHeight + 'px';
        return finalHeight;
    };

    clearHeight = () => {
        this.$content.style.transition = 'none';
        this.$content.style.height = 'auto';
    };

    preventFocus() {
        // Force "display:none" so that items are not focused
        this.$content.display = 'none';
    }

    updateAriaStates = () => {
        this.$trigger.setAttribute('aria-expanded', this.open);
        this.$content.setAttribute('aria-hidden', !this.open);
    };
}

export { Accordion, AccordionItem, ITEM_OPENED, ITEM_CREATED };

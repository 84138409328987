window['eventBusMap'] = {};

const DEFAULT_BUS_NAME = 'global';

class EventBus {
    constructor(name) {
        this.eventTarget = document.appendChild(
            document.createComment(`Events Bus ${name}`)
        );
    }

    on(type, listener) {
        this.eventTarget.addEventListener(type, listener);
    }

    once(type, listener) {
        this.eventTarget.addEventListener(type, listener, { once: true });
    }

    off(type, listener) {
        this.eventTarget.removeEventListener(type, listener);
    }

    emit(type, detail) {
        return this.eventTarget.dispatchEvent(
            new CustomEvent(type, { detail })
        );
    }
}

const getEventBus = (name = DEFAULT_BUS_NAME) => {
    let map = window['eventBusMap'];
    if (!map[name]) {
        map[name] = new EventBus(name);
    }
    return map[name];
};

export { DEFAULT_BUS_NAME, getEventBus, EventBus };

import { bindKeyboardEvents, keynames } from "../../utils";
import uncloak from "../../utils/uncloak";
/*
    Focusable element selectors
*/
const FOCUSABLE_ELEMENT_SELECTORS = [
    'a[href]',
    'area[href]',
    'audio controls',
    'button',
    '[contentEditable="true"]',
    'datalist',
    'details',
    'embed',
    'iframe',
    'input',
    'object',
    'progress',
    'select',
    'summary',
    '[tabindex]',
    'textarea',
    'video controls',
];
export const FOCUSABLE_ELEMENTS_SELECTOR = FOCUSABLE_ELEMENT_SELECTORS.join(':not([tabindex="-1"]),');


export const ATTRS = {
    ARIA_CONTROLS: 'aria-controls',
    ARIA_EXPANDED: 'aria-expanded',
    CLASS_KEY: 'dj-toggle-class-key',
    CLASS_VALUE: 'dj-toggle-class-value',
    CLOSE_ON_ESC: 'dj-toggle-escape',
    FOCUS: 'dj-toggle-focus',
    HIDE: 'dj-hide',
    IF: 'dj-if',
    INIT_KEY: 'dj-toggle-init-key',
    INIT_VALUE: 'dj-toggle-init-value',
    KEYBOARD: 'dj-toggle-keyboard',
    SHOW: 'dj-show',
    TOGGLE_CLICK: 'dj-toggle-click',
}

export class ToggleClick {
    constructor() {
        this.state = {};
        this.djIfContents = {}

        this.onClick = this.onClick.bind(this);
        this.onSpace = this.onSpace.bind(this);
        this.onEscape = this.onEscape.bind(this);
        this.setAccessibility = this.setAccessibility.bind(this);
        this.setClasses = this.setClasses.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.setVisibility = this.setVisibility.bind(this);

        document.addEventListener('click', this.onClick);
        bindKeyboardEvents(document, {
            [keynames.SPACE]: this.onSpace,
        });

        // Close toggle dropdown on Esc key using keydown because modal uses keyup&keypress
        bindKeyboardEvents(document, {
            [keynames.ESCAPE]: this.onEscape
        }, 'keydown');

        this.init();
    }

    init() {
        // Set IDs
        [...document.querySelectorAll(`[${ATTRS.IF}]`)].forEach((el) => {
            el.id = el.id || `dj-toggle-${Math.random().toString().split('.')[1]}`;
        });

        // Set state
        const initialState = {};
        for (const el of document.querySelectorAll(`[${ATTRS.INIT_KEY}]`)) {
            const key = el.getAttribute(ATTRS.INIT_KEY);
            const value = el.getAttribute(ATTRS.INIT_VALUE);
            if (key && value) {
                initialState[key] = value === 'true';
            }
        }

        // Set starting state for the UI
        const triggerEls = [...document.querySelectorAll(`[${ATTRS.TOGGLE_CLICK}]`)];
        const keys = triggerEls.map(el => el.getAttribute(ATTRS.TOGGLE_CLICK));
        keys.forEach(key => {
            this.state[key] = initialState[key] || false;
            this.setVisibility(key);
            this.setAccessibility(key);
            this.setClasses(key);
            this.setContents(key);
        });
    }

    onClick(event) {
        const triggerEl = event.target?.closest(`[${ATTRS.TOGGLE_CLICK}]`);
        if (!triggerEl) {
            return;
        }

        const key = triggerEl.getAttribute(ATTRS.TOGGLE_CLICK);
        this.state[key] = !this.state[key];

        this.setVisibility(key);
        this.setAccessibility(key);
        this.setClasses(key);
        this.setContents(key);
        if (triggerEl.hasAttribute(ATTRS.FOCUS)) {
            this.setFocus(key);
        }
    }

    onSpace(event) {
        if (event.target?.closest(`[${ATTRS.KEYBOARD}]`)) {
            event.preventDefault();
            this.onClick(event);
        }
    }

    onEscape(event) {
        const el = event.target?.closest(`[${ATTRS.CLOSE_ON_ESC}]`);
        if (!el) {
            return;
        }

        const key = el.getAttribute(ATTRS.CLOSE_ON_ESC);
        // Do nothing if this is already closed
        if (!this.state[key]) {
            return;
        }

        const triggerEl = document.querySelector(`[${ATTRS.TOGGLE_CLICK}="${key}"]`);
        if (!triggerEl) {
            return;
        }

        event.preventDefault();
        this.onClick({
            ...event,
            target: triggerEl,
        });
    }

    setAccessibility(key) {
        const state = this.state[key];
        document.querySelectorAll(`[${ATTRS.TOGGLE_CLICK}='${key}'][${ATTRS.ARIA_CONTROLS}]`).forEach((el) => {
            el.setAttribute(ATTRS.ARIA_EXPANDED, state.toString());
        });
    }

    setVisibility(key) {
        const state = this.state[key];
        document.querySelectorAll(`[${ATTRS.SHOW}='${key}']`).forEach((el) => {
            el.hidden = !state;
        });
        document.querySelectorAll(`[${ATTRS.HIDE}='${key}']`).forEach((el) => {
            el.hidden = state;
        });
    }

    setClasses(key) {
        const state = this.state[key];
        document.querySelectorAll(`[${ATTRS.CLASS_KEY}='${key}']`).forEach((el) => {
            el.classList.toggle(el.getAttribute(ATTRS.CLASS_VALUE), state);
        });
    }

    setFocus(key) {
        let potentialTargets = document.querySelectorAll(`[${this.state[key] ? ATTRS.SHOW : ATTRS.HIDE}=${key}]`);

        if (!potentialTargets.length) {
            // If there are no valid show/hide blocks available, shift focus back to the trigger
            potentialTargets = document.querySelectorAll(`[${ATTRS.TOGGLE_CLICK}="${key}"]`)
        }

        let firstFocusable;
        [...potentialTargets].find((target) => {
            firstFocusable = target.querySelector(FOCUSABLE_ELEMENTS_SELECTOR);
            return firstFocusable;
        });
        if (firstFocusable) {
            firstFocusable.focus();
        } else if (potentialTargets.length) {
            potentialTargets[0].focus();
        }
    }

    setContents(key) {
        const state = this.state[key];
        const conditionals = document.querySelectorAll(`[${ATTRS.IF}="${key}"]`);
        conditionals.forEach((conditional) => {
            if (state) {
                const documentFragment = document.createDocumentFragment();
                const children = this.djIfContents[conditional.id] || [];
                children.forEach(child => documentFragment.appendChild(child));
                conditional.appendChild(documentFragment);
                uncloak(conditional);
            } else {
                const children = this.djIfContents[conditional.id] = [...conditional.children];
                children.forEach(child => child.parentElement.removeChild(child));
            }
        });
    }
}

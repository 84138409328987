import 'moment-timezone';
import moment from '../../utils/moment-django';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { DjElement } from '../base-component/base-component.lit';
import { STATUSES, STATUS_KEYS } from './session.constant';

const ON_SESSION_CLICK = 'onSessionClick';


/**
 *
 * Component to render a session
 *
 */
 @customElement('dj-session')
 class Session extends DjElement {
    static properties = {
        classes: {},
      };

    @property({ attribute: 'strings', type: Object, })
    strings;

    @property({ attribute: 'session', type: Object,})
    session;

    @property({ attribute: 'groupId', type: Number, })
    groupId;

    @property({ attribute: 'isEditable', type: Boolean, })
    isEditable = false;

    defaultStatus = STATUSES.AVAILABLE;
    STATUS_LABELS = null;

    connectedCallback() {
        super.connectedCallback();
        this.baseClassName = 'dj-session';

        this.STATUS_LABELS = {
            [STATUSES.ADDED]: this.strings.session_status_label_added,
            [STATUSES.ATTENDED]: this.strings.session_status_label_attended,
            [STATUSES.ADD]: this.strings.session_status_label_add,
            [STATUSES.CONFIRMED]: this.strings.session_status_label_confirmed,
            [STATUSES.JOIN_WAITLIST]: this.strings.session_status_label_join_waitlist,
            [STATUSES.PENDING]: this.strings.session_status_label_pending,
            [STATUSES.REMOVE]: this.strings.session_status_label_remove,
            [STATUSES.REMOVED]: this.strings.session_status_label_removed,
            [STATUSES.UNAVAILABLE]: this.strings.session_status_label_unavailable,
            [STATUSES.UNDO]: this.strings.session_status_label_undo,
            [STATUSES.WAITLIST]: this.strings.session_status_label_waitlist,
        };

        // Status
        this.updatableStatusKeys = [
            STATUSES.ADDED,
            STATUSES.AVAILABLE,
            STATUSES.CANCELLED,
            STATUSES.CONFIRMED,
            STATUSES.JOIN_WAITLIST,
            STATUSES.REMOVED,
            STATUSES.WAITLIST,
        ];
        this.status = this.getStatus(this.session);

        this.isUpdatable = this.isEditable && this.updatableStatusKeys.includes(this.status);

        this.isFull = !this.session.registration_open && this.session.show_on_reg_form_if_closed;
        this.updateStatusLabelAndIcon(this.session, this.status);

        if (this.session.convert_datetime) {
            this.session.date = this.convertSessionDatetime(this.session);
        }

        // Attrs
        this.ariaRole = this.isUpdatable ? 'button' : null;
        this.tabIndex = this.isUpdatable ? 0 : null;
    }

    /**
     * Returns a status constant (AVAILABLE, PENDING) given a status from the backend ('not-registered', 'pending')
     * @param {string} status
     * @returns {string}
    */
    getStatus({ status }) {
      if (!status) {
          return defaultStatus;
      }
      const statusKeys = Object.keys(STATUS_KEYS);
      const statusIndex = Object.values(STATUS_KEYS).findIndex(value => value === status);
      const statusKey = statusKeys[statusIndex];
      return STATUSES[statusKey] || defaultStatus;
    };

    /**
     * Decorates the session with properties relating to status
     * @param {Object} nextSession
     * @param {string} nextStatus
    */
    updateStatusLabelAndIcon(nextSession, nextStatus) {
        nextSession.statusLabel = this.STATUS_LABELS[nextStatus];
        nextSession.statusLabelActive = this.STATUS_LABELS[nextStatus];
        nextSession.statusSVG = this.setSVGUrl(this.getStatusName(nextStatus));

        this.setClasses(nextStatus);

        switch (nextStatus) {
            case STATUSES.ADDED:
                nextSession.statusLabel = this.STATUS_LABELS.ADDED;
                nextSession.statusSVG = this.setSVGUrl('added');
                nextSession.statusLabelActive = this.STATUS_LABELS.REMOVE;
                nextSession.statusSVGActive = this.setSVGUrl('remove');
                break;
            case STATUSES.AVAILABLE:
                nextSession.statusSVGActive = this.setSVGUrl('add-active');
                break;
            case STATUSES.CONFIRMED:
            case STATUSES.WAITLIST:
                nextSession.statusLabelActive = this.STATUS_LABELS.REMOVE;
                nextSession.statusSVGActive = this.setSVGUrl('remove');
                break;
            case STATUSES.REMOVED:
                nextSession.statusLabel = this.STATUS_LABELS.REMOVED;
                nextSession.statusSVG = this.setSVGUrl('remove');
                nextSession.statusLabelActive = this.STATUS_LABELS.UNDO;
                nextSession.statusSVGActive = this.setSVGUrl('add-active');
                break;
            case STATUSES.JOIN_WAITLIST:
                nextSession.statusSVG = this.setSVGUrl('add');
                nextSession.statusSVGActive = this.setSVGUrl('add-active');
                break;
        }

        // Remove active label & SVG (hover states) when the session is not editable
        if (!this.isEditable) {
            nextSession.statusLabelActive = null;
            nextSession.statusSVGActive = null;
        }
    };

    /**
     * Converts all the session datetimes in the browser's timezone
    */
    convertSessionDatetime(session) {
        const start = session.start_utc;
        const end = session.end_utc;
        const dateFormat = session.date_format;
        const timeFormat = session.time_format;

        const locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);

        let userStartDate = '';
        let userEndDate = '';

        if (typeof dateFormat !== undefined) {
            userStartDate = moment(start).django(dateFormat);

            if (userStartDate !== moment(end).django(dateFormat)) {
                userEndDate = moment(end).django(dateFormat);
            }
        }
        if (typeof timeFormat !== undefined) {
            if (userStartDate !== '') {
                userStartDate += ' ';
            }
            userStartDate += moment(start).django(timeFormat);
            // Don't repeat the end time if it's the same as the start on the same date.
            if (moment(start).format('YYYY-MM-DD') !== moment(end).format('YYYY-MM-DD') ||
                moment(start).format('H:m') != moment(end).format('H:m')) {
                userEndDate += ' ' + moment(end).django(timeFormat);
            }
        }

        // Strip leading/trailing whitespace
        let displayDate = userStartDate.replace(/^\s+|\s+$/g, '');
        if (userEndDate !== '') {
            displayDate += ' - ' + userEndDate.replace(/^\s+|\s+$/g, '');
        }
        const tz = moment.tz.guess(true);
        return displayDate + ' ' + moment.tz(tz).zoneAbbr() + ' - ' + tz;
    }


    /**
     * Dynamic classNames for the component's root element
     * @param {string} status
     */
    setClasses(status) {
         this.classes = {
            [`${this.baseClassName}`]: true,
            [`${this.baseClassName}--status-${this.getStatusName(status)}`]: true,
            [`${this.baseClassName}--is-editable`]: !!this.isEditable,
            [`${this.baseClassName}--is-updatable`]: !!this.isUpdatable,
            [`${this.baseClassName}--is-full`]: !!this.isFull,
        };
     };

    // Helpers

    /**
     * Set the URL for an svg <use> object
     * @param {string} name
     * @returns {Object}
     */
    setSVGUrl(name) {
        return `#session-${name}`;
    }

    /**
     * Provides the lowercase version of the statusKey, i.e. 'available' rather than 'not-registered'
     * @param {string} status
     * @returns {string|void}
     */
    getStatusName(status) {
        return status.replace('_', '-').toLowerCase();
    }

    // Handlers
    onBlur() {
        if (!this.isUpdatable || !this.session.statusActiveOnBlur) {
            return;
        }

        const { statusActiveOnBlur } = this.session;

        if (statusActiveOnBlur) {
            const statusLabelActive = statusActiveOnBlur.label;
            const statusSVGActive = statusActiveOnBlur.svg;

            delete this.session.statusActiveOnBlur;

            this.session = {...this.session, statusLabelActive, statusSVGActive};

            this.classes[`${this.baseClassName}--session-updated`] = false;
        }
    };

    onClickHandler() {
        if (!this.isUpdatable) {
            return;
        }

        this.dispatchEvent(new CustomEvent(ON_SESSION_CLICK, {
            bubbles: true,
            detail: { groupId: this.groupId, sessionId: this.session.id, prevStatus: this.session.status },
        }));

        const $elem = document.querySelector(`[data-session-id="${this.session.id}"]`)
        $elem && $elem.focus();
    };

    onMouseLeave() {
        if (!this.isUpdatable) {
            return;
        }

        const targetElem = this.querySelector(`.${this.baseClassName}`);

        if (targetElem === document.activeElement) {
            targetElem.blur();
        }
    }

    onKeyup(e) {
        if (!this.isUpdatable) {
            return;
        }

        // Key: Enter
        if (e.keyCode === 13) {
            this.onClickHandler();
        }
    };

    willUpdate(changedProperties) {
        let previousValue = changedProperties.get('session');
        let currentValue = this.session;

        if (!previousValue?.status || previousValue?.status === currentValue.status) {
            return;
        }

        this.updateStatusLabelAndIcon(currentValue, this.getStatus(currentValue));
        currentValue.statusActiveOnBlur = {
            svg: currentValue.statusSVGActive,
            label: currentValue.statusLabelActive,
        };
        this.classes[`${this.baseClassName}--session-updated`] = true;
        currentValue.statusLabelActive = currentValue.statusLabel;
        currentValue.statusSVGActive = currentValue.statusSVG;
    }

    render() {
        return html`
            <div
                class="${classMap(this.classes)}"
                data-session-id="${this.session.id}"
                data-session-status="${this.session.statusSVG}"
                role="${ this.ariaRole }"
                tabindex="${ this.tabIndex }"
                @mouseleave="${this.onMouseLeave}"
                @click="${this.onClickHandler}"
                @blur="${this.onBlur}"
                @keyup="${this.onKeyup}">
                <div class="dj-session__status">
                    <svg class="dj-icon dj-session__status-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="39" aria-hidden="true">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" href="${this.session.statusSVG}"></use>
                    </svg>
                    ${this.session.statusSVGActive ?
                        html
                            `<svg
                                class="dj-icon dj-session__status-svg--active"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="39"
                                aria-hidden="true">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" href="${ this.session.statusSVGActive }"></use>
                            </svg>`
                        : ''
                    }
                    <p class="dj-session__status-label">${ this.session.statusLabel }</p>
                    ${this.session.statusLabelActive ?
                            html`<p class="dj-session__status-label-active">
                                ${ this.session.statusLabelActive }
                                </p>`
                            : ''
                    }
            </div>
            <div class="dj-session__details">
                <h4 class="dj-session__title">
                    <span class="dj-session__title-text">${ this.session.title }</span>
                    ${this.session.date ?
                        html`
                        <date class="dj-session__date">${this.session.date}</date>
                        `
                        : ''
                    }
                    ${this.isFull ?
                        html`
                        - ${this.strings.session_full_additional_text}
                        `
                        : ''
                    }
                    ${this.session.validationMessage?
                        html`<span class="dj-session__validation dj-session__validation--error">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                class="dj-icon"
                                >
                                    <use xlink:href="#mi-warning"></use>
                                </svg>
                                ${this.session.validationMessage}
                            </span>`
                        : ''
                    }
                </h4>
            ${this.session.status === STATUS_KEYS.REJECTED ?
                html`
                <p class="dj-session__unavailable">
                <svg class="dj-icon dj-session__unavailable-svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" href="#mi-warning"></use>
                </svg>
                ${this.strings.session_message_unavailable}
                </p>`: ''
            }
            ${this.session.description?
                html`<p class="dj-session__description">
                        ${this.session.description}
                    </p>`: ''

            }
            ${this.session.venue ?
                html`
                    <address class="dj-session__venue">
                        ${this.session.venue }
                    </address>` : ''
            }
        </div>
    </div>`
    }
}


 export { Session, ON_SESSION_CLICK, };

import { customElement, property } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

export const BRAINTREE_FIELD_EVENTS = {
  BLUR: 'blur',
  CARD_TYPE_CHANGE: 'cardTypeChange',
  EMPTY: 'empty',
  FOCUS: 'focus',
  NOT_EMPTY: 'notEmpty',
  VALIDITY_CHANGE: 'validityChange',
};

export const CLASSES = {
    HAS_VALUE: 'dj-bt-field--has-value',
    HAS_PRELOADED_VALUE: 'dj-bt-field--has-preloaded-value',
    ICON_ACTIVE: 'dj-bt-field__card-icon--active',
    ICON_INACTIVE: 'dj-bt-field__card-icon--inactive',
    INVALID: 'dj-bt-field--invalid',
    FOCUSED: 'dj-bt-field--focused',
};

@customElement('dj-bt-field')
export class DjBtField extends DjElement {

    @property({
        attribute: 'field-name',
        type: String,
    })
    fieldName;

    @property({
        attribute: 'required-message',
        type: String,
    })
    requiredErrorMessage

    @property({
        attribute: 'invalid-message',
        type: String,
    })
    invalidErrorMessage;

    fieldState = {
        dirty: false,
        invalid: true,
        pristine: true,
        required: true,
        touched: false,
        untouched: true,
    };

    formSubmitted = false;

    isEmpty;

    errorMessageEl;

    constructor() {
        super();

        this.handleEvent = this.handleEvent.bind(this);
        this.onCardTypeChange = this.onCardTypeChange.bind(this);
        this.setHasValue = this.setHasValue.bind(this);
        this.setInvalid = this.setInvalid.bind(this);
        this.updateValidity = this.updateValidity.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.fieldName) {
            console.error('[dj-bt-field] field-name attribute is required');
            return;
        }

        const cardIconEls = this.querySelectorAll('[dj-bt-field-card-icon]');
        this.cardTypes = [...cardIconEls].reduce((cardTypes, el) => {
            const cardName = el.getAttribute('dj-bt-field-card-icon');
            return {
                ...cardTypes,
                [cardName]: el,
            };
        }, {});
        this.errorMessageEl = this.querySelector('[dj-bt-field-error-message]');
    }

    setHasValue(hasValue) {
        this.isEmpty = !hasValue;
        this.classList.toggle(CLASSES.HAS_VALUE, !!hasValue);
        if (!hasValue) {
            this.classList.remove(CLASSES.HAS_PRELOADED_VALUE);
        }
    }

    setInvalid(isInvalid) {
        this.classList.toggle(CLASSES.INVALID, !!isInvalid);
    }

    onCardTypeChange(cardTypes) {
        cardTypes = cardTypes.map(card => card.type);
        Object.keys(this.cardTypes).forEach((type) => {
            const el = this.cardTypes[type];
            const matchingType = cardTypes.includes(type);
            el.classList.toggle(CLASSES.ICON_ACTIVE, !this.isEmpty && matchingType);
            el.classList.toggle(CLASSES.ICON_INACTIVE, this.isEmpty || !matchingType);
        });
    }

    handleEvent(eventName, eventData, fieldState) {
        console.debug('[DjBtField:handleEvent] fieldstate for ' + eventData.emittedBy, fieldState);

        this.fieldState.invalid = !fieldState.isValid || !fieldState.isPotentiallyValid;

        switch (eventName) {
            case BRAINTREE_FIELD_EVENTS.FOCUS:
                this.classList.add(CLASSES.FOCUSED);
                break;

            case BRAINTREE_FIELD_EVENTS.BLUR:
                this.fieldState.touched = true;
                this.fieldState.untouched = false;
                this.classList.remove(CLASSES.FOCUSED);
                break;

            case BRAINTREE_FIELD_EVENTS.EMPTY:
                this.fieldState.required = true;
                this.setHasValue(false);
                break;

            case BRAINTREE_FIELD_EVENTS.NOT_EMPTY:
                this.fieldState.dirty = true;
                this.fieldState.pristine = false;
                this.fieldState.required = false;
                this.setHasValue(true);
                break;

            case BRAINTREE_FIELD_EVENTS.CARD_TYPE_CHANGE:
                this.onCardTypeChange(eventData.cards);
                break;
        }

        this.updateValidity();
    }

    updateValidity() {
        const { invalid, required, touched } = this.fieldState;
        this.setInvalid(invalid && (touched || this.formSubmitted));
        if (required) {
            this.errorMessageEl.textContent = this.requiredErrorMessage;
        } else if (invalid) {
            this.errorMessageEl.textContent = this.invalidErrorMessage;
        } else {
            this.errorMessageEl.textContent = '';
        }
    }
}

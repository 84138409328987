import { customElement, property } from 'lit/decorators.js';

import { DjElement } from '../base-component/base-component.lit';

const ITEM_ADDED = 'dj-searchable-item:added';
const ITEM_REMOVED = 'dj-searchable-item:removed';

@customElement('dj-searchable-list')
class SearchableList extends DjElement {
    items = [];

    constructor() {
        super();

        this.registerItem = this.registerItem.bind(this);
        this.unregisterItem = this.unregisterItem.bind(this);
        this.filter = this.filter.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.$searchInput = this.querySelector('input');
        this.$emptyResults = this.querySelector('.empty');

        this.$emptyResults.hidden = true;
        this.addEventListener(ITEM_ADDED, this.registerItem);
        this.addEventListener(ITEM_REMOVED, this.unregisterItem);
        this.$searchInput.addEventListener('input', this.filter);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener(ITEM_ADDED, this.registerItem);
        this.removeEventListener(ITEM_REMOVED, this.unregisterItem);
        this.$searchInput.removeEventListener('input', this.filter);
    }

    registerItem(event) {
        this.$emptyResults.hidden = true;
        this.items.push(event.detail);
        event.stopPropagation();
    }

    unregisterItem(event) {
        this.items.pop(event.detail);
        if (this.items.length > 0) {
            this.$emptyResults.hidden = false;
        }
        event.stopPropagation();
    }

    combinations(array) {
        return new Array(1 << array.length).fill().map(
          (e1, i) => array.filter((e2, j) => i & 1 << j));
    }

    filter(event) {
        const query = event.target.value.toLowerCase();

        this.items.forEach((item) => {
            const searchableFields = this.combinations(item.searchFieldsValues);

            const containsString = Object.values(searchableFields).some((val) =>
                    val ?
                    val
                    .join(' ')
                    .toLowerCase()
                    .includes(query) : false
            );

            containsString ? item.show() : item.hide();
        });

        this.$emptyResults.hidden = this.items.some(item => !item.isHidden());
    }
}

@customElement('dj-searchable-item')
class SearcheableItem extends DjElement {
    @property({
        type: Object,
        attribute: 'search-fields-values',
    })
    searchFieldsValues;

    constructor() {
        super();
        this.hidden = false;
    }

    connectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_ADDED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    disconnectedCallback() {
        this.dispatchEvent(
            new CustomEvent(ITEM_REMOVED, {
                bubbles: true,
                detail: this,
            })
        );
    }

    hide() {
        this.hidden = true;
    }

    show() {
        this.hidden = false;
    }

    isHidden() {
        return this.hidden === true;
    }
}

export { SearchableList, ITEM_ADDED, ITEM_REMOVED };


export const ATTRS = {
    SCROLL_TO_INPUT: 'dj-scroll-to-input-with-error',
    HREF: 'href',
}

export class ScrollToInputWithError {
    constructor() {
        this.onClick = this.onClick.bind(this);
        document.addEventListener('click', this.onClick);
    }
    onClick(event) {
        const triggerEl = event.target?.closest(`[${ATTRS.SCROLL_TO_INPUT}]`);
        if (!triggerEl) {
            return;
        }

        const linkHref = triggerEl.getAttribute(ATTRS.HREF);
        if (linkHref.startsWith('#')) {
            const inputId = linkHref.split('#')[1];
            if (inputId) {
                const inputElement = document.getElementById(inputId);
                if (inputElement) {
                    inputElement.scrollIntoView({ "block": "center" });
                    inputElement.focus();
                    history.pushState(null, null, '#' + inputId);
                    event.preventDefault();
                }
            }
        }
    }
}